import React from "react";
import { useParams } from "react-router-dom";

const MovieDetailPage = ({
  movies,
  seenMovies,
  userScores,
  watchlistMovies,
  updateUserScore,
  userId,
}) => {
  const { movieId } = useParams(); // This will get the movie ID from the URL
  const movie = movies.find((m) => m.Const === movieId);

  if (!movie) {
    return <div>Movie not found</div>;
  }

  return (
    <div>
      {/* Render your movie details here */}
      <h1>{movie.Title}</h1>
      {/* You can add more movie details here */}
    </div>
  );
};

export default MovieDetailPage;
