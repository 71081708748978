import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import MovieShelf from "../components/MovieShelf";
import MetacriticLogo from "../assets/Metacritic_logo.png";
import { ReactComponent as DropDown } from "../assets/chevron.down.svg";
import ImdbLogo from "../assets/imdbFavicon.png";
import MetaLogo from "../assets/metaFavicon.png";
import LetterLogo from "../assets/letterFavicon.svg";
import CuratorLogo from "../assets/Logo150.png";

import MovieSchema from "./MovieSchema";

import StreamingIcon from "./StreamingIcon";
// import StreamingIcon from "./StreamingIcon";

import StreamingURL from "./StreamingURL.js";

import "./style.css";

const MovieDetailPopup = ({
  movie,
  closePopup,
  onSimilarMovieSelect,
  displayUserScore,
  userScores,
  movies,
  updateUserScore,
  userId,
  seenMoviesProp,
  watchlistProp,
  userScores2: initialUserScores,
}) => {
  let apiBaseUrl = "";

  // console.log(`ENV: ${process.env.REACT_APP_NODE_ENV}`);
  if (process.env.REACT_APP_NODE_ENV === "development") {
    // Use the development base URL
    apiBaseUrl = "http://localhost:3001";
  } else {
    // Use the production base URL (empty string for relative URL)
    apiBaseUrl = "";
  }

  const [myScorePercentile, setMyScorePercentile] = useState(null);
  const [myScoreRank, setMyScoreRank] = useState(null);

  const { user, getAccessTokenSilently, isAuthenticated } = useAuth0();
  // const [seenMovies, setSeenMovies] = useState(new Set());
  // const [userScores, setUserScores] = useState({});

  const topRef = useRef(null); // Add this at the start of your component
  const [watchlist, setWatchlist] = useState(new Set(watchlistProp));
  const [seenMovies, setSeenMovies] = useState(new Set(seenMoviesProp));

  // Helper function to dynamically generate score text
  const getScoreDetails = (movie) => {
    // console.log('seen movies:', seenMovies);
    // const textColorStyle = getTextColorStyleBasedOnBackground(movie.DominantColor || 'rgb(0, 0, 0)'); // Fallback to a dark background
    const textColorStyle = getTextColorStyleBasedOnBackground("rgb(0, 0, 0)"); // Fallback to a dark background

    // Determine If userscore for the movie exists, use that, otherwise use the movie.CuratorScore
    const score =
      displayUserScore && userScores && userScores[movie.Const]
        ? userScores[movie.Const]
        : movie.CuratorScore;

    // console.log(`userScores:`, userScores);
    // const score = userScores[movie.Const];

    const scoreLabel = userScores && userScores[movie.Const] ? "My" : "Cu";
    //Print combination of score and scoreLabel
    const displayScore = `${scoreLabel}${score}`;

    const details = [{ label: movie.Streaming, value: score }];

    // Conditionally add user score if it exists
    // if (displayUserScore && userScores && userScores[movie.Const]) {
    //   details.push({ label: 'My Score', value: userScores[movie.Const] });
    // }

    return details.map((detail) => (
      <div key={movie.Const}>
        <span>{detail.value}</span>
        <br></br>
        {/* <span style={{ color: textColorStyle.valueColor }}>{detail.label}</span> */}
      </div>
    ));
  };

  useEffect(() => {
    // Update local state when props change
    setSeenMovies(new Set(seenMoviesProp));
    setWatchlist(new Set(watchlistProp));
  }, [seenMoviesProp, watchlistProp]);

  // Function to get button and icon styles based on background color
  const getButtonStyles = (dominantColor) => {
    const isDarkBackground = isColorDark(dominantColor);
    // const isDarkBackground = true;
    return {
      backgroundColor: !isDarkBackground
        ? "rgba(255, 255, 255, 0.3)"
        : "rgba(0, 0, 0, 0.3)", // Slightly transparent for visual depth
      iconFillColor: !isDarkBackground ? "black" : "white", // This assumes icons need to contrast with button background
    };
  };

  const getTextColorStyleBasedOnBackground = (dominantColor) => {
    const isDarkBackground = isColorDark(dominantColor);
    // Define text color for light backgrounds if needed
    const colorForLightBackground = "hsla(0, 0%, 20%, 1)"; // Adjust as needed
    return {
      labelColor: isDarkBackground
        ? "hsla(0, 0%, 100%, .64)"
        : colorForLightBackground,
      valueColor: isDarkBackground ? "white" : "black", // Adjust as needed for light backgrounds
    };
  };

  const isColorDark = (rgb) => {
    const color = rgb.match(/\d+/g).map(Number);
    const luminance = 0.299 * color[0] + 0.587 * color[1] + 0.114 * color[2];
    return luminance < 86;
  };

  const toggleSeenStatus = async (movie, event) => {
    event.stopPropagation();
    const isSeen = seenMovies.has(movie.Const);
    const endpoint = isSeen ? "/api/user/removeMovie" : "/api/user/addMovie";
    const movieData = isSeen
      ? { userId, movieConst: movie.Const }
      : { userId, movie };

    try {
      await axios.post(`${apiBaseUrl}${endpoint}`, movieData);
      isSeen ? seenMovies.delete(movie.Const) : seenMovies.add(movie.Const);
      setSeenMovies(new Set([...seenMovies]));
    } catch (error) {
      console.error("Error toggling seen status:", error);
    }
  };

  const toggleWatchlistStatus = async (movie, event) => {
    event.stopPropagation(); // Prevent triggering movie card click
    const isInWatchlist = watchlist.has(movie.Const);
    const endpoint = isInWatchlist
      ? "/api/user/removeFromWatchlist"
      : "/api/user/addToWatchlist";
    const movieData = { userId, movieConst: movie.Const };

    try {
      await axios.post(`${apiBaseUrl}${endpoint}`, movieData);
      isInWatchlist
        ? watchlist.delete(movie.Const)
        : watchlist.add(movie.Const);
      setWatchlist(new Set([...watchlist]));
    } catch (error) {
      console.error("Error toggling watchlist status:", error);
    }
  };

  const isMovieSeen = seenMovies.has(movie.Const);
  const [isClipped, setIsClipped] = useState(false);
  const [isClipped2, setIsClipped2] = useState(false);
  const textRef = useRef(null);
  const textRef2 = useRef(null);
  // const userScore = userScores[movie.Const];
  // const text = movie.Plot;
  // const text2 = movie.Plot;

  const text = movie.overview;
  const text2 = movie.overview;
  const [selectedMovie, setSelectedMovie] = useState(null);

  const userScore = userScores[movie.Const]
    ? `${userScores[movie.Const]} / 100`
    : "N/A";

  const formatRatingWithCommas = (rating) => {
    return rating.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const formatRarity = (rarity) => {
    let formattedRarity;
    if (rarity < 1000) {
      formattedRarity = rarity.toString();
    } else if (rarity < 1000000) {
      formattedRarity = (rarity / 1000).toFixed(0) + "K";
    } else {
      formattedRarity = (rarity / 1000000).toFixed(0) + "M";
    }
    return `${formattedRarity}`;
  };

  const formatRarityRounded = (rarity) => {
    let formattedRarity;
    if (rarity < 100000) {
      // Round to the nearest ten thousand if less than 100k
      const rounded = Math.round(rarity / 10000) * 10000;
      formattedRarity =
        rounded < 1000 ? rarity.toString() : (rounded / 1000).toFixed(0) + "K+";
    } else if (rarity >= 100000 && rarity < 1000000) {
      // Different rounding logic for 100k to 1 million
      const rounded = Math.round(rarity / 100000) * 100000;
      formattedRarity = (rounded / 1000).toFixed(0) + "K+";
    } else if (rarity >= 1000000) {
      // Different rounding logic for more than 1 million
      const rounded = Math.round(rarity / 1000000) * 1000000;
      formattedRarity = (rounded / 1000000).toFixed(0) + "M+";
    } else {
      // Handle case for rarity < 1000 without special rounding
      formattedRarity = rarity.toString();
    }
    return `${formattedRarity}`;
  };

  const formatRarity4 = (rarity) => {
    // Use Intl.NumberFormat to format the number with commas
    const formattedRarity = new Intl.NumberFormat("en-US").format(rarity);
    return formattedRarity;
  };

  const formatRuntime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    if (hours === 0) return `${minutes} min`;
    return `${hours} hr ${minutes} min`;
  };

  // const toggleBodyScroll = (shouldDisable) => {
  //     const element = document.querySelector('.landing-page-with-nav-header');
  //     const elementTop = document.querySelector('.h-100');

  //     elementTop.scrollTop = 0;

  //     const scrollContainer = document.querySelector('.top3-container-display');

  //     // Temporarily disable scroll snapping
  //     scrollContainer.style.overflow = 'unset';

  //     // Perform the scroll
  //     // elementTop.scrollIntoView({ behavior: 'instant', block: 'start' });

  //     // Re-enable scroll snapping
  //     // setTimeout(() => {
  //     //     scrollContainer.style.overflow = 'auto';
  //     // }, 100);

  //     if (elementTop) {
  //         setTimeout(() => {
  //             // elementTop.scrollIntoView({ behavior: 'instant', block: 'start' });
  //         }, 10); // Adjust delay as needed
  //     }

  //     if (shouldDisable) {
  //       // Disables scrolling
  //       document.body.style.overflow = '';
  //       if (element) element.classList.add('hidden');

  //     } else {
  //       // Enables scrolling
  //       document.body.style.overflow = '';
  //       if (element) element.classList.remove('hidden');
  //     }
  // };

  // useEffect(() => {
  //     // Disable scrolling when the popup is open
  //     toggleBodyScroll(true);

  //     // Cleanup function to re-enable scrolling when the popup is closed
  //     return () => {
  //       toggleBodyScroll(false);
  //       const scrollContainer = document.querySelector('.top3-container-display');

  //       toggleBodyScroll(false);
  //         setTimeout(() => {
  //             scrollContainer.style.overflow = 'auto';
  //         }, 100);
  //     };
  // }, []); // Empty dependency array means this effect runs only on mount and unmount

  // Function to convert RGB to RGBA with opacity
  const convertRGBtoRGBA = (rgb, opacity) => {
    return rgb.replace("rgb", "rgba").replace(")", `, ${opacity})`);
  };

  const getColorForScore = (score) => {
    let red, green;
    if (score >= 50) {
      red = Math.round((255 * (100 - score)) / 50);
      green = 255;
    } else {
      red = 255;
      green = Math.round((255 * score) / 50);
    }
    return `rgb(${red}, ${green}, 0)`;
  };

  const getColorForScore3 = (score) => {
    const clampedScore = Math.max(50, Math.min(score, 100));
    const normalizedScore = (clampedScore - 50) / (100 - 50);
    const hue = normalizedScore * 120;
    return `hsl(${hue}, 100%, 50%)`;
  };

  useEffect(() => {
    const checkIfClipped = () => {
      if (textRef.current) {
        setIsClipped(
          textRef.current.scrollHeight > textRef.current.clientHeight
        );
      }
    };

    checkIfClipped();
    window.addEventListener("resize", checkIfClipped); // Re-check on window resize

    return () => window.removeEventListener("resize", checkIfClipped);
  }, [text]);

  useEffect(() => {
    const checkIfClipped2 = () => {
      if (textRef2.current) {
        setIsClipped2(
          textRef2.current.scrollHeight > textRef2.current.clientHeight
        );
      }
    };

    checkIfClipped2();
    window.addEventListener("resize", checkIfClipped2); // Re-check on window resize

    return () => window.removeEventListener("resize", checkIfClipped2);
  }, [text2]);

  const handleMovieSelect = (movie) => {
    setSelectedMovie(movie);
  };

  const getTopPerformances = () => {
    const entries = Object.entries(movie.StarRank || {});
    const topPerformances = entries.filter(([key, rank]) => rank === 1);
    return topPerformances.map(([key, rank]) => {
      return (
        <div key={key} style={tagStyle}>
          Top Performance by {key.replace("_", " ")}
        </div>
      );
    });
  };

  // Assuming you have a function like this to handle when a similar movie is selected
  // const handleSimilarMovieSelect = async (movieId) => {
  //     // Assuming you have a way to fetch or derive the movie details from the movieId
  //     console.log('Selected movie:', movieId);
  //     const movieDetails = await fetchMovieDetails(movieId);
  //     setSelectedMovie(movieDetails);
  // };

  const handleSelectSimilarMovie = (movie) => {
    onSimilarMovieSelect(movie);
  };

  const productionCompanies =
    movie.production?.edges?.map(
      (edge) => edge.node.company.companyText.text
    ) || [];

  // // Function to handle score change and update the database
  // const handleScoreChange = async (constId, newScore) => {
  //     try {
  //         const token = await getAccessTokenSilently();
  //         await axios.post(`${apiBaseUrl}/api/user/rateMovie`, {
  //             userId: user.sub,
  //             Const: constId,
  //             score: newScore
  //         }, {
  //             headers: {
  //                 Authorization: `Bearer ${token}`,
  //             },
  //         });

  //         // Update the userScores state to include the new score
  //         setUserScores(prevUserScores => ({ ...prevUserScores, [constId]: newScore }));

  //         // Check if the movie is already in seenMovies before adding it
  //         if (!seenMovies.has(constId)) {
  //             setSeenMovies(prevSeenMovies => new Set([...prevSeenMovies, constId]));
  //         }
  //     } catch (error) {
  //         console.error('Error rating movie:', error);
  //     }
  // };

  // const handleAddAndRateMovie = async (movieConst, movieId) => {
  //     const newScore = prompt('Enter your score (1-99):');
  //     if (newScore && newScore >= 1 && newScore <= 99) {
  //       if (!seenMovies.has(movieConst)) {
  //         await addMovieToSeenList(movieId);
  //       }
  //       await handleScoreChange(movieConst, newScore);
  //     }
  // };

  // const addMovieToSeenList = async (movieId) => {
  //     try {
  //       const token = await getAccessTokenSilently();
  //       const movie = movies.find(m => m._id === movieId);
  //       if (!movie) {
  //         console.error('Movie not found');
  //         return;
  //       }

  //       if (seenMovies.has(movie.Const)) {
  //         // Remove movie from seen list
  //         await removeMovieFromSeenList(movie.Const);
  //       } else {
  //       await axios.post(`${apiBaseUrl}/api/user/addMovie`, {
  //         userId: user.sub,
  //         name: user.name,
  //         movie: { Const: movie.Const, Title: movie.Title }
  //       }, {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       });
  //       // Update the seenMovies state to include the new movie
  //       setSeenMovies(new Set([...seenMovies, movie.Const]));
  //       }

  //     } catch (error) {
  //       console.error('Error adding movie to seen list:', error);
  //     }
  //   };

  // Inside MovieDetailPopup component

  const handleAddAndRateMovie = async (movieConst) => {
    // const newScore = prompt('Enter your score (0-100):'); // Get user input
    // if (newScore && !isNaN(newScore) && newScore >= 0 && newScore <= 100) {
    // // Call the function passed via props to update the score in the parent state and database
    // updateUserScore(movieConst, newScore);
    // }
    updateUserScore(movieConst);
  };

  // No need to modify how userScore is displayed if it's already correctly implemented:
  // {userScore}

  // Function to create a faded background style
  const createFadedBackgroundStyle = (rgbColor) => {
    // Check if rgbColor is valid
    if (rgbColor && rgbColor.startsWith("rgb")) {
      return {
        backgroundImage: `linear-gradient(
                    to top, 
                    ${convertRGBtoRGBA(rgbColor, 0.25)} 10%, 
                    ${convertRGBtoRGBA(rgbColor, 0.25)} 100%
                )`,
      };
    } else {
      // Default to a black background if no valid color is provided
      return {
        backgroundColor: "black",
      };
    }
  };

  const getStyle3 = (type, rank) => {
    // Ensure the function handles cases without a valid rank or type
    if (!type || rank == null) return {};

    // Adjusting styles based on the rank
    switch (rank) {
      case 1:
        return { color: "rgb(20,255,0)" }; // Green for rank #1
      case 2:
      case 3:
        return { color: "rgb(220, 255, 0)" }; // Yellow for ranks #2 and #3
      default:
        return {}; // Default style, no specific color
    }
  };
  
const formatName = (key) => {
  if (typeof key !== "string") return "Invalid Key";
  return key
    .replace(/_/g, " ")
    .replace(/\b\w/g, (char) => char.toUpperCase())
    .trim();
};

const defaultStyle = { color: "gray" };

const renderDirectorRanks = () => {
  if (
    !movie ||
    !movie.DirectorRanks ||
    typeof movie.DirectorRanks !== "object"
  ) {
    return <div>No director rank data available.</div>;
  }

  const entries = Object.entries(movie.DirectorRanks);
  return entries.length > 0 ? (
    entries.map(([directorKey, rank]) => (
      <div key={directorKey}>
        <strong style={getStyle3("career", rank) || defaultStyle}>
          #{rank}
        </strong>{" "}
        Movie by <strong>{formatName(directorKey)}</strong>
      </div>
    ))
  ) : (
    <div>No director rank data available.</div>
  );
};

const renderAllRankings = () => {
  if (!movie || !movie.StarRank || typeof movie.StarRank !== "object") {
    return <div>No star rank data available.</div>;
  }

  const entries = Object.entries(movie.StarRank);
  return entries.length > 0 ? (
    <>
      {entries.flatMap(([key, rank]) => {
        if (typeof rank === "object") {
          return Object.entries(rank).map(([name, rankValue]) => (
            <div key={`${key}-${name}`}>
              <strong style={getStyle3("career", rankValue) || defaultStyle}>
                #{rankValue}
              </strong>{" "}
              Movie Starring <strong>{formatName(name)}</strong>
            </div>
          ));
        } else {
          return (
            <div key={key}>
              <strong style={getStyle3("career", rank) || defaultStyle}>
                #{rank}
              </strong>{" "}
              Movie Starring <strong>{formatName(key)}</strong>
            </div>
          );
        }
      })}
    </>
  ) : (
    <div>No star rank data available.</div>
  );
};

  // // Render rank information for directors
  // const renderDirectorRanks = () => {
  //   if (!movie || !movie.DirectorRanks)
  //     return <div>No director rank data available.</div>;

  //   const entries = Object.entries(movie.DirectorRanks);
  //   return entries.map(([directorKey, rank]) => (
  //     <div key={directorKey}>
  //       <strong style={getStyle3("career", rank)}>#{rank}</strong> Movie by{" "}
  //       <strong>{formatDirectorName(directorKey)}</strong>
  //     </div>
  //   ));
  // };

  // // Render all rankings for each star in the movie
  // const renderAllRankings = () => {
  //   if (!movie || !movie.StarRank)
  //     return <div>No star rank data available.</div>;

  //   const entries = Object.entries(movie.StarRank);
  //   return entries.map(([key, rank]) => (
  //     <div key={key}>
  //       <strong style={getStyle3("career", rank)}>#{rank}</strong> Movie
  //       Starring <strong>{formatDirectorName(key)}</strong>
  //     </div>
  //   ));
  // };

  // // Render all rankings for each star in the movie
  // const renderAllRankings = () => {
  //   const entries = Object.entries(movie.StarRank || {});
  //   return entries.map(([key, rank]) => {
  //     return (
  //       <div key={key}>
  //         <strong style={getStyle("career", rank)}>#{rank}</strong> Movie
  //         Starring{" "}
  //         <strong>
  //           {key
  //             .split("_")
  //             .map(
  //               (word) =>
  //                 word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  //             )
  //             .join(" ")}
  //         </strong>
  //       </div>
  //     );
  //   });
  // };

  // Styles
  const tagStyle = {};

  const detailStyle = {};

  const isInWatchlist = watchlist.has(movie.Const);
  const isSeen = seenMovies.has(movie.Const);
  // const isRated = userScores.has(movie.Const);

  const renderWatchlistIcon = (movie) => {
    const isInWatchlist = watchlist.has(movie.Const);
    // const { iconFillColor } = getButtonStyles(movie.DominantColor || 'rgb(0, 0, 0)'); // Assuming a dark default
    const { iconFillColor } = getButtonStyles("rgb(0, 0, 0)"); // Assuming a dark default

    return (
      <span>
        {isInWatchlist ? (
          <svg
            className="buttonIconColorFill"
            style={{
              width: "18px",
              strokeWidth: "2px",
              marginBottom: "0px",
            }}
            focusable="false"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <path d="M17 3H7c-1.1 0-1.99.9-1.99 2L5 21l7-3 7 3V5c0-1.1-.9-2-2-2z"></path>
          </svg>
        ) : (
          <svg
            className="buttonIconColorNoFill"
            style={{
              width: "18px",
              fill: "none",
              strokeWidth: "2px",
              marginBottom: "0px",
            }}
            focusable="false"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <path d="M17 3H7c-1.1 0-1.99.9-1.99 2L5 21l7-3 7 3V5c0-1.1-.9-2-2-2zm0 21.35z" />
          </svg>
        )}
      </span>
    );
  };

  // (<svg style={{ width: "50%", fill: "none", stroke: iconFillColor, strokeWidth: "2px", marginBottom: "7px" }} focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M17 3H7c-1.1 0-1.99.9-1.99 2L5 21l7-3 7 3V5c0-1.1-.9-2-2-2zm0 21.35z"/></svg>)}

  const renderSeenIcon = (movie) => {
    const isSeen = seenMovies.has(movie.Const);
    // const { iconFillColor } = getButtonStyles(movie.DominantColor || 'rgb(0, 0, 0)'); // Assuming a dark default
    const { iconFillColor } = getButtonStyles("rgb(0, 0, 0)"); // Assuming a dark default

    return (
      <span>
        {isSeen ? (
          <svg
            className="buttonIconColorFill"
            style={{
              width: "18px",
              strokeWidth: "2px",
              marginBottom: "0px",
            }}
            focusable="false"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <path d="M17 3H7c-1.1 0-1.99.9-1.99 2L5 21l7-3 7 3V5c0-1.1-.9-2-2-2z"></path>
          </svg>
        ) : (
          <svg
            className="buttonIconColorNoFill"
            style={{
              width: "18px",
              fill: "none",
              strokeWidth: "2px",
              marginBottom: "0px",
            }}
            focusable="false"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <path d="M17 3H7c-1.1 0-1.99.9-1.99 2L5 21l7-3 7 3V5c0-1.1-.9-2-2-2zm0 21.35z" />
          </svg>
        )}
      </span>
    );
  };

  const getSimilarMovieDetailsById = (id) => {
    const foundMovie = movies.find(
      (movie) => movie.TMDBID === id || movie.Const === id || movie._id === id
    );
    if (foundMovie) {
      return foundMovie;
    } else {
      return null;
    }
  };

  const getUniqueMovies = (movies) => {
    const uniqueMoviesMap = new Map();

    movies.forEach((movie) => {
      // Use TMDBID or Const as a unique key for the smovie
      const uniqueKey = movie.TMDBID || movie.Const || movie._id;
      if (!uniqueMoviesMap.has(uniqueKey)) {
        uniqueMoviesMap.set(uniqueKey, movie);
      }
    });

    // Convert the map back to an array of movie objects
    return Array.from(uniqueMoviesMap.values());
  };

  // Safely map over arrays, providing an empty array as a default if the data does not exist
  const sortedSimilarMovies1 = (movie.SimilarMovies || []).map(
    (similarMovieId) => getSimilarMovieDetailsById(similarMovieId)
  );

  const sortedSimilarMovies2 = (movie.SimilarMoviesTMDBID || []).map(
    (similarMovieId) => getSimilarMovieDetailsById(similarMovieId)
  );

  const sortedSimilarMovies3 = (movie.recommended_movie_ids || []).map(
    (similarMovieId) => getSimilarMovieDetailsById(similarMovieId)
  );

  // Combine all movies into one array before filtering for uniqueness
  const combinedSimilarMovies = [
    // ...sortedSimilarMovies1,
    // ...sortedSimilarMovies2,
    ...sortedSimilarMovies3,
  ].filter((movie) => movie !== null); // Remove any null values that represent not found movies

  // Filter the combined array for unique movies
  const uniqueSortedSimilarMovies = getUniqueMovies(combinedSimilarMovies);

  // Now `uniqueSortedSimilarMovies` contains no duplicate movies and accounts for potentially missing data.

  const formattedReleaseDate = new Date(movie.release_date).toLocaleDateString(
    "en-US",
    {
      year: "numeric",
      month: "long",
      day: "numeric",
    }
  );

  let oscarStatus;
  if (movie["Won Best Picture"]) {
    oscarStatus = "OSCAR® WINNER";
  } else if (movie["Nominated for Best Picture"]) {
    oscarStatus = "OSCAR® NOMMINATED";
  }

  const [isMuted, setIsMuted] = useState(true);

  const youtubeEmbedUrl = `https://www.youtube.com/embed/${
    movie.youtubeTrailerId
  }?autoplay=1&mute=${
    isMuted ? "1" : "0"
  }&controls=1&showinfo=0&modestbranding=1&loop=1`;
  const [videoLoaded, setVideoLoaded] = useState(true);
  const [isPlaying, setIsPlaying] = useState(true);

  const scoreDisplayStyle = (constId, score, additionalStyles) => {
    const isRated = true;
    const scoreColor = isRated
      ? getColorForScore(score || score)
      : "transparent"; // Transparent for unrated
    const borderColor = isRated
      ? getColorForScore(score)
      : getColorForScore(score);
    const borderRadius = isRated ? "50px" : "50px"; // 6px for rated, 50px for unrated

    const baseStyle = {
      backgroundColor: scoreColor,
      border: `4px solid ${borderColor}`,
      borderRadius: borderRadius,
      height: "30px",
      width: "30px",
    };

    return { ...baseStyle, ...additionalStyles };
  };

  useEffect(() => {
    const calculateDetails = () => {
      if (!userScores || !movie || userScores[movie.Const] === undefined) {
        setMyScorePercentile(null);
        setMyScoreRank(null);
        return;
      }

      const allScores = Object.values(userScores)
        .map(Number)
        .filter((n) => !isNaN(n));
      if (!allScores.length) return;

      const currentScore = Number(userScores[movie.Const]);
      const sortedScores = allScores.sort((a, b) => b - a);
      const rank = sortedScores.indexOf(currentScore) + 1;
      const percentile =
        ((sortedScores.length - rank + 1) / sortedScores.length) * 100;

      setMyScorePercentile(Math.round(percentile));
      setMyScoreRank(rank);
    };

    calculateDetails();
  }, [userScores, movie]);

  const formatStatEntry = (name, value, rank, score) => {
    return {
      name,
      value: value != null ? Math.round(value) + "%" : undefined,
      rank: rank != null ? rank : undefined,
      score: score != null ? score.toLocaleString() : undefined,
    };
  };

  const stats = [
    formatStatEntry(
      "Curator",
      movie.CuratorScorePercentile,
      movie.CuratorScoreRank,
      movie.CuratorScore
    ),
    formatStatEntry(
      "Letterboxd",
      movie.LetterScorePercentile,
      movie.LetterScoreRank,
      movie.LetterScore
    ),
    formatStatEntry(
      "IMDb",
      movie.UserPercentile,
      movie.UserRank,
      movie.User * 10
    ),
    formatStatEntry(
      "Metacritic",
      movie.CriticPercentile,
      movie.CriticRank,
      movie.Critic
    ),
    formatStatEntry(
      "Popularity",
      movie.popularityPercentile,
      movie.popularityRank,
      `#${movie.popularityRank}`
    ),
  ];

  // Calculate total ratings if available
  if (movie.VoteCountPercentile != null) {
    const totalRatings = (movie.Rarity || 0) + (movie.LetterCount || 0);
    stats.push(
      formatStatEntry(
        "Total Ratings",
        movie.VoteCountPercentile,
        movie.VoteCountRank,
        totalRatings
      )
    );
  }

  // Calculate total ratings if available
  if (myScorePercentile != null && myScoreRank !== null) {
    stats.push(
      formatStatEntry(
        "MyScore",
        Math.round(myScorePercentile),
        myScoreRank,
        userScores[movie.Const]
      )
    );
  }

  // Sort stats by percentile in descending order
  stats.sort((a, b) => {
    const aValue = parseInt(a.value, 10) || 0;
    const bValue = parseInt(b.value, 10) || 0;
    return bValue - aValue;
  });

  // Determine highest percentile for highlighting
  const highestPercentile = stats[0].value;

  // Function to determine the style based on percentile value
  const determineStyle = (value) => {
    if (typeof value === "undefined") {
      return {}; // No style if the value is undefined
    }
    const numericValue = parseInt(value, 10); // Ensure value is treated as a number
    if (isNaN(numericValue)) {
      return {}; // Return default style if value isn't a number
    }
    if (numericValue === highestPercentile) {
      return { color: "#0a84ff" };
    } else if (numericValue > 85) {
      return { color: "rgb(20,255,0)" };
    } else if (numericValue > 66) {
      return { color: "rgb(220, 255, 0)" };
    }
    return {};
  };

  const getDecadeStart = (year) => {
    return year - (year % 10);
  };

  // Assuming `movie.Year` is correctly set in your movie object
  const currentYear = movie.Year;
  const decadeStart = getDecadeStart(currentYear);
  const decadeEnd = decadeStart + 9;

  const decadeTotal = movies.filter((movie) => {
    const releaseYear = parseInt(movie.Year, 10);
    return releaseYear >= decadeStart && releaseYear <= decadeEnd;
  }).length;

  const yearTotal = movies.filter(
    (movie) => parseInt(movie.Year, 10) === currentYear
  ).length;

  // Helper to calculate percentile
  const calculatePercentile = (rank, total) => {
    const percentile = Math.round((1 - (rank - 1) / total) * 100);
    return percentile;
  };

  // Updated getStyle function to use rank and total entries
  const getStyle = (type, rank, total) => {
    const percentile = calculatePercentile(rank, total);
    switch (type) {
      case "year":
      case "decade":
      case "allTime":
        if (percentile >= 85) return { color: "rgb(20, 255, 0)" };
        if (percentile >= 66) return { color: "rgb(220, 255, 0)" };
        break;
      default:
        return {};
    }
  };

  // const decadeTotal = movies.filter(
  //   (movie) => movie.Year >= 2010 && movie.Year <= 2019
  // ).length;

  // const getStyle = (type, value) => {
  //   switch (type) {
  //     case "rank":
  //       if (value === 1) return { color: "rgb(20,255,0)" }; // Top movie of the year
  //       if (value <= 10) return { color: "rgb(220, 255, 0)" }; // Top 10 movie of the year
  //       break;
  //     case "decade":
  //       if (value <= 3) return { color: "rgb(20,255,0)" }; // Top 3 movie of the decade
  //       if (value <= 25) return { color: "rgb(220, 255, 0)" }; // Top 10 movie of the decade
  //       break;
  //     case "allTime":
  //       if (value >= 85) return { color: "rgb(20,255,0)" }; // Top  movie of the decade
  //       if (value >= 66) return { color: "rgb(220, 255, 0)" }; // Top 10 movie of the decade
  //       break;
  //     case "career":
  //       if (value === 1) return { color: "rgb(20,255,0)" }; // Top movie by star or director
  //       if (value <= 3) return { color: "rgb(220, 255, 0)" }; // Top 3 movie by star or director
  //       break;
  //     default:
  //       return {};
  //   }
  // };

  // Simulate video load after 2 seconds
  useEffect(() => {
    // setVideoLoaded(false); 
    const timer = setTimeout(() => {
      // setVideoLoaded(false); 
    }, 2000); 

    return () => clearTimeout(timer);
  }, []);

  // Toggle mute state
  const toggleMute = () => {
    // console.log('toggleMute');
    setIsMuted(!isMuted);
  };

  // Toggle mute state
  const togglePlaying = () => {
    // console.log('togglePlaying');
    setIsPlaying(!isPlaying);
  };

  const getStreamingUrl = (streamingService) => {
    return (
      StreamingURL[streamingService] ||
      "https://www.google.com/search?q=watch+movies+online"
    );
  };

  const streamingUrl2 = getStreamingUrl(movie.Streaming);

  const getYesStyle = (condition) => {
    return condition ? { color: "rgb(20,255,0)" } : {};
  };

  // const renderConditionalText = (condition) => {
  //   return condition ? <strong style={{ color: "rgb(20,255,0)" }}>Yes</strong> : "No";
  // };

  const renderConditionalText = (condition, awardType, year) => {
    const yesText = `${year} ${awardType} Winner`;
    const noText = `${year} ${awardType} Nominated`;
    return condition ? (
      <strong style={{ color: "rgb(20,255,0)" }}>{yesText}</strong>
    ) : (
      noText
    );
  };

  const renderAwardStatus = (isWinner, isNominated, awardType, year) => {
    if (!isWinner && !isNominated) return null;

    let text = "";
    let style = {};

    if (awardType === "Oscar") {
      if (isWinner) {
        text = "Oscar Best Picture Winner";
        style = { color: "#0a84ff" };
      } else if (isNominated) {
        text = "Oscar Best Picture Nominee";
        style = { color: "rgb(20,255,0)" };
      } else {
        text = "No Awards";
      }
    } else if (awardType === "Curator") {
      if (isWinner) {
        text = "The Movie of the Year";
        style = { color: "#0a84ff" };
      } else if (isNominated) {
        text = "Top 10 Movie of the Year";
        style = { color: "rgb(20,255,0)" };
      } else {
        text = "No Awards";
      }
    } else {
      text = "No Awards";
    }

    const yearText = year ? ` (${year})` : "";

    return (
      <strong style={style}>
        {text}
      </strong>
    );
  };

  const curatorVoteCount = (movie.LetterCount || 0) + (movie.Rarity || 0);

  return (
    <div className="boomba" ref={topRef}>
      <MovieSchema movie={movie} />
      <div className="">
        <div className="cc-modal__header wider740">
          <span className={`cc-modal__apple-icon`}></span>

          <button
            aria-label=""
            className="cc-modal__close-button"
            onClick={closePopup}
          >
            <span className="cc-modal__close-button-circle">
              <span className="cc-modal__close-button-icon"></span>
            </span>
          </button>
        </div>

        <div className={`movie-detail-content`}>
          <div className="product-header__wrapper">
            <div>
              <div
                className="product-header__video-wrapper"
                // onLoad={() => timer()}
              >
                <iframe
                  className="product-header__video product-header__video__container animate background-video contain fade-in"
                  src={youtubeEmbedUrl}
                  fit="contain"
                  aria-hidden="false"
                  allowFullScreen
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  title={`${movie.Title} YouTube Trailer`}
                  style={{ "--joe-color": "rgb(0,0,0)" }}
                  crossOrigin="anonymous"
                  muted={true}
                  onLoad={() => setVideoLoaded(true)}
                ></iframe>
              </div>

              {/* <div data-play-state="paused" data-audio-state="muted" className="product-header__video-control__container animate fade-in" aria-hidden="false">
                                    <button onClick={toggleMute} data-metrics-click='{"actionType":"mute","targetId":"Mute","targetType":"button"}' className="product-header__video-control--mute header-button header-button--mute" aria-label="Unmute Preview" type="button">
                                    {isMuted ? (
                                        <svg className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" title="Unmute Preview"><path d="M12.5041016,24.9455078 C19.3341797,24.9455078 24.9455078,19.3341797 24.9455078,12.5041016 C24.9455078,5.66132812 19.3341797,0.05 12.4914062,0.05 C5.66132812,0.05 0.05,5.66132812 0.05,12.5041016 C0.05,19.3341797 5.66132812,24.9455078 12.5041016,24.9455078 Z M12.5041016,23.9806641 C6.15644531,23.9806641 1.01484375,18.8390625 1.01484375,12.5041016 C1.01484375,6.16914062 6.15644531,1.01484375 12.4914062,1.01484375 C18.8390625,1.01484375 23.9806641,6.16914062 23.9806641,12.5041016 C23.9806641,18.8390625 18.8390625,23.9806641 12.5041016,23.9806641 Z M19.4611328,19.4484375 C19.6134766,19.2833984 19.6134766,19.0548828 19.4611328,18.9025391 L6.06757812,5.49628906 C5.91523437,5.34394531 5.67402344,5.34394531 5.52167969,5.49628906 C5.35664062,5.64863281 5.36933594,5.88984375 5.52167969,6.02949219 L18.9279297,19.4484375 C19.0675781,19.6007812 19.3214844,19.6134766 19.4611328,19.4484375 Z M15.1828125,13.7101562 L15.1828125,7.2609375 C15.1828125,6.96894531 15.0177734,6.72773437 14.7130859,6.72773437 C14.4845703,6.72773437 14.3322266,6.8546875 14.1544922,7.00703125 L11.2726562,9.8 L15.1828125,13.7101562 Z M14.7130859,18.2169922 C14.9669922,18.2169922 15.1066406,18.0392578 15.1320312,17.7980469 L15.1574219,16.4777344 L8.83515625,10.1935547 L8.45429687,10.1935547 C7.971875,10.2316406 7.565625,10.6632812 7.565625,11.1837891 L7.565625,13.8244141 C7.565625,14.4591797 8.04804687,14.9416016 8.65742187,14.9416016 L10.815625,14.9416016 C10.9171875,14.9416016 10.9933594,14.9669922 11.0695312,15.0304687 L14.1544922,17.9630859 C14.3195312,18.1027344 14.4972656,18.2169922 14.7130859,18.2169922 Z" transform="translate(2 2)"></path></svg>
                                    ) : (
                                        <svg class="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" title="Mute Preview"><path d="M12.5041016,24.9455078 C19.3341797,24.9455078 24.9455078,19.3341797 24.9455078,12.5041016 C24.9455078,5.66132812 19.3341797,0.05 12.4914062,0.05 C5.66132812,0.05 0.05,5.66132812 0.05,12.5041016 C0.05,19.3341797 5.66132812,24.9455078 12.5041016,24.9455078 Z M12.5041016,23.9806641 C6.15644531,23.9806641 1.01484375,18.8390625 1.01484375,12.5041016 C1.01484375,6.16914062 6.15644531,1.01484375 12.4914062,1.01484375 C18.8390625,1.01484375 23.9806641,6.16914062 23.9806641,12.5041016 C23.9806641,18.8390625 18.8390625,23.9806641 12.5041016,23.9806641 Z M14.9923828,18.5470703 C15.3224609,18.5470703 15.5001953,18.3058594 15.5001953,18.0011719 L15.5001953,6.96894531 C15.5001953,6.6515625 15.3224609,6.41035156 14.9923828,6.41035156 C14.7765625,6.41035156 14.5988281,6.52460937 14.4083984,6.68964844 L11.1583984,9.85078125 C11.0822266,9.91425781 10.9933594,9.95234375 10.8664062,9.95234375 L8.60664062,9.95234375 C7.93378906,9.95234375 7.43867187,10.4347656 7.43867187,11.1203125 L7.43867187,13.9005859 C7.43867187,14.5861328 7.93378906,15.08125 8.60664062,15.08125 L10.8664062,15.08125 C10.9933594,15.08125 11.0822266,15.1193359 11.1583984,15.1828125 L14.4083984,18.2677734 C14.5861328,18.4328125 14.7892578,18.5470703 14.9923828,18.5470703 Z" transform="translate(2 2)"></path></svg>
                                    )}
                                    </button>
                            </div> */}
            </div>

            <picture>
              <img
                src={
                  window.innerWidth > 70 && movie.backdrop_path
                    ? `${movie.backdrop_path.replace(
                        "/",
                        "https://image.tmdb.org/t/p/w1280/"
                      )}`
                    : movie.poster_path
                    ? `${movie.poster_path.replace(
                        "/",
                        "https://image.tmdb.org/t/p/w1280/"
                      )}`
                    : `${movie.Poster.replace(
                        "_V1_.jpg",
                        "_V1_FMjpg_UX900_.jpg"
                      )}`
                }
                alt={movie.Title}
                // className={`product-header__image-bg
                // ${!videoLoaded ? "product-header__image-bg--hidden" : ""}
                // `}
                className={`product-header__image-bg`}
                loading="eager"
              />
            </picture>

            {/* <div className="product-header__brand-logo__blur"></div> */}
            <div className="product-header__image-logo__blur"></div>

            <div className="product-header__main__container wider740">
              <div className="product-header__content__container">
                <div className="product-header__blur"></div>

                <div
                  className="review-card__title typ-headline-emph"
                  style={{ paddingBottom: "0px" }}
                >
                  <span className="review-card__title--sub typ-footnote clr-secondary-text">
                    <ul className="review-card__title--sub--list">
                      <li
                        style={{
                          paddingBottom: "3px",
                          fontSize: "18px",
                          color: "var(--systemPrimary-onDark)",
                        }}
                      ></li>
                    </ul>
                  </span>
                  <span
                    style={{
                      fontSize: "26px",
                      paddingBottom: "2px",
                      marginLeft: "-2px",
                    }}
                  >
                    {movie.Title}
                  </span>
                  <span className="review-card__title--sub typ-footnote clr-secondary-text">
                    <ul className="review-card__title--sub--list">
                      <li style={{ paddingBottom: "7px", fontSize: "14px" }}>
                        <strong>{movie.CuratorScore}</strong> •{" "}
                        {formatRarityRounded(movie.Rarity)} Ratings
                      </li>
                      <li style={{ paddingBottom: "7px", fontSize: "14px" }}>
                        <strong>{movie.Streaming}</strong> ‧ {movie.Genre}
                      </li>
                      <li style={{ paddingBottom: "7px", fontSize: "14px" }}>
                        {movie.Year} ‧{" "}
                        {movie.number_of_seasons > 0
                          ? `${movie.number_of_seasons} seasons`
                          : formatRuntime(movie.Runtime)}{" "}
                        ‧ {movie.Certified}
                      </li>
                    </ul>
                  </span>
                </div>

                <div className="product-header__content__buttons">
                  <ul className="video-data-services-buttons__list">
                    <li className="video-data-services-buttons__list-item">
                      <button
                        title="Availability"
                        className="video-data-services-button typography-title-3-emphasized"
                        type="button"
                      >
                        <span className="video-data-services-button__text">
                          {movie.Streaming === "Buy"
                            ? "Buy or Rent"
                            : `${movie.Streaming}`}
                        </span>
                      </button>
                    </li>
                    <li className="video-data-services-buttons__list-item">
                      <button
                        onClick={() =>
                          handleAddAndRateMovie(movie.Const, movie._id)
                        }
                        title="Add to Up Next"
                        className="video-data-services-button typography-title-3-emphasized"
                        type="button"
                      >
                        <span className="video-data-services-button__text">
                          {userScores && userScores[movie.Const]
                            ? `My Score ${userScores[movie.Const]} / 100`
                            : "Click to Rate"}
                        </span>
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="product-main">
            {/* <div className="divider-xs-only"></div> */}

            {/* <div className="shelf-grid shelf-grid--onhover">
                                <div className="shelf-grid__header">
                                    <h2 className="typ-headline-emph">
                                        Cast & Crew
                                    </h2>
                                </div>
                                <div className="shelf-grid__body">
                                    <ul className="shelf-grid__list">
                                        <li className="shelf-grid__list-item">
                                            <a className="profile-lockup" >
                                                <div className="canvas-lockup canvas-lockup--profile">
                                                    <div className="media-artwork-v2__container media-artwork-v2--image-did-attempt-download  profile-lockup__image" >
                                                        <picture>
                                                            <source srcset="https://is1-ssl.mzstatic.com/image/thumb/o4J_mYc27JFF628qrcHG9A/246x246bb.webp 246w, https://is1-ssl.mzstatic.com/image/thumb/o4J_mYc27JFF628qrcHG9A/166x166bb.webp 166w, https://is1-ssl.mzstatic.com/image/thumb/o4J_mYc27JFF628qrcHG9A/164x164bb.webp 164w, https://is1-ssl.mzstatic.com/image/thumb/o4J_mYc27JFF628qrcHG9A/167x167bb.webp 167w, https://is1-ssl.mzstatic.com/image/thumb/o4J_mYc27JFF628qrcHG9A/492x492bb.webp 492w, https://is1-ssl.mzstatic.com/image/thumb/o4J_mYc27JFF628qrcHG9A/332x332bb.webp 332w, https://is1-ssl.mzstatic.com/image/thumb/o4J_mYc27JFF628qrcHG9A/328x328bb.webp 328w, https://is1-ssl.mzstatic.com/image/thumb/o4J_mYc27JFF628qrcHG9A/334x334bb.webp 334w" type="image/webp"/>
                                                            <source srcset="https://is1-ssl.mzstatic.com/image/thumb/o4J_mYc27JFF628qrcHG9A/246x246bb.jpg 246w, https://is1-ssl.mzstatic.com/image/thumb/o4J_mYc27JFF628qrcHG9A/166x166bb.jpg 166w, https://is1-ssl.mzstatic.com/image/thumb/o4J_mYc27JFF628qrcHG9A/164x164bb.jpg 164w, https://is1-ssl.mzstatic.com/image/thumb/o4J_mYc27JFF628qrcHG9A/167x167bb.jpg 167w, https://is1-ssl.mzstatic.com/image/thumb/o4J_mYc27JFF628qrcHG9A/492x492bb.jpg 492w, https://is1-ssl.mzstatic.com/image/thumb/o4J_mYc27JFF628qrcHG9A/332x332bb.jpg 332w, https://is1-ssl.mzstatic.com/image/thumb/o4J_mYc27JFF628qrcHG9A/328x328bb.jpg 328w, https://is1-ssl.mzstatic.com/image/thumb/o4J_mYc27JFF628qrcHG9A/334x334bb.jpg 334w" type="image/jpeg"/>
                                                            <img src="/assets/artwork/1x1.gif" decoding="async" loading="lazy" className="media-artwork-v2__image" alt="Leonardo DiCaprio" role="presentation" sizes="(min-width:300px) and (max-width:739px) 246px, (min-width:740px) and (max-width:999px) 166px, (min-width:1000px) and (max-width:1319px) 164px, 167px"/>
                                                        </picture>
                                                    </div>
                                                </div>
                                                <div className="profile-lockup__details">
                                                    <p className="typ-caption profile-lockup__details--one-or-two-lines">
                                                        Leonardo DiCaprio
                                                    </p>
                                                    <p className="typ-caption clr-secondary-text profile-lockup__details--one-or-two-lines">
                                                        Ernest Burkhart
                                                    </p>
                                                </div>
                                            </a>
                                        </li>
                                        <li className="shelf-grid__list-item">
                                            <a className="profile-lockup" >
                                                <div className="canvas-lockup canvas-lockup--profile">
                                                    <div className="media-artwork-v2__container media-artwork-v2--image-did-attempt-download  profile-lockup__image">
                                                    <picture>
                                                        <source srcset="https://is1-ssl.mzstatic.com/image/thumb/qLPMrrUiRvqhQQUsr5g_ng/246x246bb.webp 246w, https://is1-ssl.mzstatic.com/image/thumb/qLPMrrUiRvqhQQUsr5g_ng/166x166bb.webp 166w, https://is1-ssl.mzstatic.com/image/thumb/qLPMrrUiRvqhQQUsr5g_ng/164x164bb.webp 164w, https://is1-ssl.mzstatic.com/image/thumb/qLPMrrUiRvqhQQUsr5g_ng/167x167bb.webp 167w, https://is1-ssl.mzstatic.com/image/thumb/qLPMrrUiRvqhQQUsr5g_ng/492x492bb.webp 492w, https://is1-ssl.mzstatic.com/image/thumb/qLPMrrUiRvqhQQUsr5g_ng/332x332bb.webp 332w, https://is1-ssl.mzstatic.com/image/thumb/qLPMrrUiRvqhQQUsr5g_ng/328x328bb.webp 328w, https://is1-ssl.mzstatic.com/image/thumb/qLPMrrUiRvqhQQUsr5g_ng/334x334bb.webp 334w" type="image/webp"/>

                                                        <source srcset="https://is1-ssl.mzstatic.com/image/thumb/qLPMrrUiRvqhQQUsr5g_ng/246x246bb.jpg 246w, https://is1-ssl.mzstatic.com/image/thumb/qLPMrrUiRvqhQQUsr5g_ng/166x166bb.jpg 166w, https://is1-ssl.mzstatic.com/image/thumb/qLPMrrUiRvqhQQUsr5g_ng/164x164bb.jpg 164w, https://is1-ssl.mzstatic.com/image/thumb/qLPMrrUiRvqhQQUsr5g_ng/167x167bb.jpg 167w, https://is1-ssl.mzstatic.com/image/thumb/qLPMrrUiRvqhQQUsr5g_ng/492x492bb.jpg 492w, https://is1-ssl.mzstatic.com/image/thumb/qLPMrrUiRvqhQQUsr5g_ng/332x332bb.jpg 332w, https://is1-ssl.mzstatic.com/image/thumb/qLPMrrUiRvqhQQUsr5g_ng/328x328bb.jpg 328w, https://is1-ssl.mzstatic.com/image/thumb/qLPMrrUiRvqhQQUsr5g_ng/334x334bb.jpg 334w" type="image/jpeg"/>

                                                        <img src="/assets/artwork/1x1.gif" decoding="async" loading="lazy" className="media-artwork-v2__image" alt="Robert De Niro" role="presentation" sizes="(min-width:300px) and (max-width:739px) 246px, (min-width:740px) and (max-width:999px) 166px, (min-width:1000px) and (max-width:1319px) 164px, 167px"/>
                                                    </picture>
                                                    </div>
                                                </div>
                                                <div className="profile-lockup__details">
                                                    <p className="typ-caption profile-lockup__details--one-or-two-lines">
                                                        Robert De Niro
                                                    </p>
                                                    <p className="typ-caption clr-secondary-text profile-lockup__details--one-or-two-lines">
                                                        William Hale
                                                    </p>
                                                </div>
                                            </a>
                                        </li>
                                        <li className="shelf-grid__list-item">
                                            <a className="profile-lockup" >
                                                <div className="canvas-lockup canvas-lockup--profile">
                                                    <div className="canvas-lockup canvas-lockup--profile">
                                                            <div className="media-artwork-v2__container  media-artwork-v2--image-did-error profile-lockup__image">
                                                                <div className="artwork--interstitial profile-placeholder">
                                                        <svg className="media-artwork-v2__image profile-placeholder" width="100%" height="100%" viewBox="0 0 640 640" xmlns="http://www.w3.org/2000/svg">
                                                            <defs>
                                                                <linearGradient id="a" x1="50%" y1="0%" x2="50%" y2="100%">
                                                                    <stop offset="0%" stop-color="#A5ABB8"></stop>
                                                                    <stop offset="100%" stop-color="#848993"></stop>
                                                                </linearGradient>
                                                            </defs>
                                                            <rect width="100%" height="100%" fill="url(#a)"></rect>
                                                            <text x="320" y="50%" dy="0.35em" font-size="250" fill="#fff" text-anchor="middle" font-family="SF Pro Display,-apple-system,Helvetica,Arial" font-weight="500">
                                                                SR
                                                            </text>
                                                        </svg>
                                                    </div>

                                                    </div>

                                                    </div>
                                                </div>
                                                <div className="profile-lockup__details">
                                                    <p className="typ-caption profile-lockup__details--one-or-two-lines">
                                                        Leonardo DiCaprio
                                                    </p>
                                                    <p className="typ-caption clr-secondary-text profile-lockup__details--one-or-two-lines">
                                                        Ernest Burkhart
                                                    </p>
                                                </div>
                                            </a>
                                        </li>
                                        <li className="shelf-grid__list-item">
                                            <a className="profile-lockup" >
                                                <div className="canvas-lockup canvas-lockup--profile">
                                                    <div className="media-artwork-v2__container media-artwork-v2--image-did-attempt-download  profile-lockup__image" >
                                                        <picture>
                                                            <source srcset="https://is1-ssl.mzstatic.com/image/thumb/o4J_mYc27JFF628qrcHG9A/246x246bb.webp 246w, https://is1-ssl.mzstatic.com/image/thumb/o4J_mYc27JFF628qrcHG9A/166x166bb.webp 166w, https://is1-ssl.mzstatic.com/image/thumb/o4J_mYc27JFF628qrcHG9A/164x164bb.webp 164w, https://is1-ssl.mzstatic.com/image/thumb/o4J_mYc27JFF628qrcHG9A/167x167bb.webp 167w, https://is1-ssl.mzstatic.com/image/thumb/o4J_mYc27JFF628qrcHG9A/492x492bb.webp 492w, https://is1-ssl.mzstatic.com/image/thumb/o4J_mYc27JFF628qrcHG9A/332x332bb.webp 332w, https://is1-ssl.mzstatic.com/image/thumb/o4J_mYc27JFF628qrcHG9A/328x328bb.webp 328w, https://is1-ssl.mzstatic.com/image/thumb/o4J_mYc27JFF628qrcHG9A/334x334bb.webp 334w" type="image/webp"/>
                                                            <source srcset="https://is1-ssl.mzstatic.com/image/thumb/o4J_mYc27JFF628qrcHG9A/246x246bb.jpg 246w, https://is1-ssl.mzstatic.com/image/thumb/o4J_mYc27JFF628qrcHG9A/166x166bb.jpg 166w, https://is1-ssl.mzstatic.com/image/thumb/o4J_mYc27JFF628qrcHG9A/164x164bb.jpg 164w, https://is1-ssl.mzstatic.com/image/thumb/o4J_mYc27JFF628qrcHG9A/167x167bb.jpg 167w, https://is1-ssl.mzstatic.com/image/thumb/o4J_mYc27JFF628qrcHG9A/492x492bb.jpg 492w, https://is1-ssl.mzstatic.com/image/thumb/o4J_mYc27JFF628qrcHG9A/332x332bb.jpg 332w, https://is1-ssl.mzstatic.com/image/thumb/o4J_mYc27JFF628qrcHG9A/328x328bb.jpg 328w, https://is1-ssl.mzstatic.com/image/thumb/o4J_mYc27JFF628qrcHG9A/334x334bb.jpg 334w" type="image/jpeg"/>
                                                            <img src="/assets/artwork/1x1.gif" decoding="async" loading="lazy" className="media-artwork-v2__image" alt="Leonardo DiCaprio" role="presentation" sizes="(min-width:300px) and (max-width:739px) 246px, (min-width:740px) and (max-width:999px) 166px, (min-width:1000px) and (max-width:1319px) 164px, 167px"/>
                                                        </picture>
                                                    </div>
                                                </div>
                                                <div className="profile-lockup__details">
                                                    <p className="typ-caption profile-lockup__details--one-or-two-lines">
                                                        Leonardo DiCaprio
                                                    </p>
                                                    <p className="typ-caption clr-secondary-text profile-lockup__details--one-or-two-lines">
                                                        Ernest Burkhart
                                                    </p>
                                                </div>
                                            </a>
                                        </li>
                                        <li className="shelf-grid__list-item">
                                            <a className="profile-lockup" >
                                                <div className="canvas-lockup canvas-lockup--profile">
                                                    <div className="media-artwork-v2__container media-artwork-v2--image-did-attempt-download  profile-lockup__image" >
                                                        <picture>
                                                            <source srcset="https://is1-ssl.mzstatic.com/image/thumb/o4J_mYc27JFF628qrcHG9A/246x246bb.webp 246w, https://is1-ssl.mzstatic.com/image/thumb/o4J_mYc27JFF628qrcHG9A/166x166bb.webp 166w, https://is1-ssl.mzstatic.com/image/thumb/o4J_mYc27JFF628qrcHG9A/164x164bb.webp 164w, https://is1-ssl.mzstatic.com/image/thumb/o4J_mYc27JFF628qrcHG9A/167x167bb.webp 167w, https://is1-ssl.mzstatic.com/image/thumb/o4J_mYc27JFF628qrcHG9A/492x492bb.webp 492w, https://is1-ssl.mzstatic.com/image/thumb/o4J_mYc27JFF628qrcHG9A/332x332bb.webp 332w, https://is1-ssl.mzstatic.com/image/thumb/o4J_mYc27JFF628qrcHG9A/328x328bb.webp 328w, https://is1-ssl.mzstatic.com/image/thumb/o4J_mYc27JFF628qrcHG9A/334x334bb.webp 334w" type="image/webp"/>
                                                            <source srcset="https://is1-ssl.mzstatic.com/image/thumb/o4J_mYc27JFF628qrcHG9A/246x246bb.jpg 246w, https://is1-ssl.mzstatic.com/image/thumb/o4J_mYc27JFF628qrcHG9A/166x166bb.jpg 166w, https://is1-ssl.mzstatic.com/image/thumb/o4J_mYc27JFF628qrcHG9A/164x164bb.jpg 164w, https://is1-ssl.mzstatic.com/image/thumb/o4J_mYc27JFF628qrcHG9A/167x167bb.jpg 167w, https://is1-ssl.mzstatic.com/image/thumb/o4J_mYc27JFF628qrcHG9A/492x492bb.jpg 492w, https://is1-ssl.mzstatic.com/image/thumb/o4J_mYc27JFF628qrcHG9A/332x332bb.jpg 332w, https://is1-ssl.mzstatic.com/image/thumb/o4J_mYc27JFF628qrcHG9A/328x328bb.jpg 328w, https://is1-ssl.mzstatic.com/image/thumb/o4J_mYc27JFF628qrcHG9A/334x334bb.jpg 334w" type="image/jpeg"/>
                                                            <img src="/assets/artwork/1x1.gif" decoding="async" loading="lazy" className="media-artwork-v2__image" alt="Leonardo DiCaprio" role="presentation" sizes="(min-width:300px) and (max-width:739px) 246px, (min-width:740px) and (max-width:999px) 166px, (min-width:1000px) and (max-width:1319px) 164px, 167px"/>
                                                        </picture>
                                                    </div>
                                                </div>
                                                <div className="profile-lockup__details">
                                                    <p className="typ-caption profile-lockup__details--one-or-two-lines">
                                                        Leonardo DiCaprio
                                                    </p>
                                                    <p className="typ-caption clr-secondary-text profile-lockup__details--one-or-two-lines">
                                                        Ernest Burkhart
                                                    </p>
                                                </div>
                                            </a>
                                        </li>
                                    </ul>
                                </div> */}
            {/* <div className="shelf-grid-nav">
                                    <ul>
                                        <li>
                                            <button className="shelf-grid-nav__arrow shelf-grid-nav__arrow--next" aria-label="Next Page" data-test-media-shelf-paddle="next" type="button"></button>
                                        </li>
                                        <li>
                                            <button className="shelf-grid-nav__arrow shelf-grid-nav__arrow--previous" aria-label="Previous Page" data-test-media-shelf-paddle="previous" type="button"></button>
                                        </li>
                                    </ul>
                                </div> */}
            {/* </div> */}

            <div className="product-footer3 secondary-bg-color">
              <div className="product-header__content__container3">
                <div className="movie-poster3">
                  <img
                    src={
                      window.innerWidth > 10000 && movie.backdrop_path
                        ? `${movie.backdrop_path.replace(
                            "/",
                            "https://image.tmdb.org/t/p/w1280/"
                          )}`
                        : movie.poster_path
                        ? `${movie.poster_path.replace(
                            "/",
                            "https://image.tmdb.org/t/p/w1280/"
                          )}`
                        : `${movie.Poster.replace(
                            "_V1_.jpg",
                            "_V1_FMjpg_UX900_.jpg"
                          )}`
                    }
                    decoding="async"
                    loading="lazy"
                    className="movie-poster-img"
                    alt={movie.Title}
                    role="presentation"
                  />
                </div>
                <div className="product-header__main__container smaller740">
                  <div className="review-card side-card3">
                    <div
                      className="review-card__title typ-headline-emph"
                      style={{ paddingBottom: "0px" }}
                    >
                      <span
                        style={{
                          fontSize: "24px",
                          paddingBottom: "0px",
                          marginLeft: "0px",
                        }}
                      >
                        {movie.Title}
                      </span>
                      <span className="review-card__title--sub typ-footnote clr-secondary-text">
                        <ul className="review-card__title--sub--list">
                          <li
                            style={{ paddingBottom: "5px", fontSize: "14px" }}
                          >
                            {movie.Year} ‧{" "}
                            {movie.number_of_seasons > 0
                              ? `${movie.number_of_seasons} seasons`
                              : formatRuntime(movie.Runtime)}{" "}
                            ‧ {movie.Certified}
                          </li>
                          <li
                            style={{ paddingBottom: "5px", fontSize: "14px" }}
                          >
                            <strong>{movie.Streaming}</strong> ‧ {movie.Genre}
                          </li>
                          <li
                            style={{ paddingBottom: "5px", fontSize: "14px" }}
                          >
                            <strong>{movie.CuratorScore}</strong> (
                            {Math.round(movie.CuratorScorePercentile)}%){" "}
                            {formatRarityRounded(curatorVoteCount)} Ratings
                          </li>
                        </ul>
                      </span>
                    </div>
                  </div>
                  {/* <div className="product-header__blur" ></div>

                                    <div className="review-card__title typ-headline-emph" style={{paddingBottom: "0px"}}>
                                        <span className="review-card__title--sub typ-footnote clr-secondary-text">
                                            <ul className="review-card__title--sub--list">
                                                <li style={{paddingBottom: "3px", fontSize: "18px", color: "var(--systemPrimary-onDark)"}}>
                                                    
                                                    <strong>{movie.CuratorScore} / 100</strong> {formatRarityRounded(movie.Rarity)} Ratings
                                                </li>
                                            </ul>
                                        </span>
                                        <span style={{fontSize: "26px", paddingBottom: "2px", marginLeft: "-2px"}}>
                                            {movie.Title}
                                        </span>
                                        <span className="review-card__title--sub typ-footnote clr-secondary-text">
                                            <ul className="review-card__title--sub--list">
                                                <li style={{paddingBottom: "7px"}}>
                                                    {movie.Certified} ‧ {movie.Year} ‧ {movie.Genre} ‧ {movie.number_of_seasons > 0 ? `${movie.number_of_seasons} seasons` : formatRuntime(movie.Runtime)}
                                                </li>
                                            </ul>
                                        </span>
                                    </div>

                                    <div className="product-header__content__buttons">
                                        <ul className="video-data-services-buttons__list">
                                            <li className="video-data-services-buttons__list-item">
                                                <button
                                                    title="Availability"
                                                    className="video-data-services-button typography-title-3-emphasized"
                                                    type="button"
                                                >
                                                    <span className="video-data-services-button__text">
                                                        {movie.Streaming === "Buy" ? "Buy or Rent" : `${movie.Streaming}`} 
                                                    </span>
                                                </button>
                                            </li>
                                            <li className="video-data-services-buttons__list-item">
                                                <button onClick={() => handleAddAndRateMovie(movie.Const, movie._id)} title="Add to Up Next" className="video-data-services-button typography-title-3-emphasized" type="button">
                                                    <span className="video-data-services-button__text">
                                                    {userScores && userScores[movie.Const] ? `My Score ${userScores[movie.Const]} / 100` : 'Click to Rate'}
                                                    </span>
                                                </button>
                                            </li>
                                        </ul>
                                    </div> */}
                </div>
              </div>

              <div className="product-footer__container">
                <div className="product-footer__info even-split bollum2">
                  <div
                    className="product-footer__container"
                    style={{ gridColumn: "span 2", padding: "0px" }}
                  >
                    <div className="product-footer__info even-split">
                      <div
                        className={`filter-container filter-container-explore wide-card-full`}
                        style={{
                          padding: "0px",
                          marginTop: "10px",
                          margin: "0px",
                        }}
                      >
                        {movie.Streaming !== "Buy" && (
                          <div className="dropdown">
                            <button
                              onClick={() =>
                                window.open(
                                  streamingUrl2,
                                  "_blank",
                                  "noopener,noreferrer"
                                )
                              }
                              className="dropdown-button3"
                              style={{
                                backgroundColor: "transparent",
                                border: "none",
                                cursor: "pointer",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "24px",
                                width: "24px",
                                padding: "6px",
                              }}
                            >
                              <StreamingIcon
                                serviceName={movie.Streaming}
                                style={{
                                  left: "0px",
                                  position: "relative",
                                  height: "30px",
                                  width: "30px",
                                }}
                              />
                            </button>
                          </div>
                        )}

                        <div className="dropdown">
                          <button
                            onClick={() =>
                              handleAddAndRateMovie(movie.Const, movie._id)
                            }
                            className={`dropdown-button3 ${
                              userScores && userScores[movie.Const]
                                ? "active"
                                : ""
                            }`}
                            style={{
                              backgroundColor:
                                userScores && userScores[movie.Const]
                                  ? getColorForScore3(userScores[movie.Const])
                                  : "var(--appSecondaryBackgroundColor)",
                              color:
                                userScores && userScores[movie.Const]
                                  ? "black"
                                  : "var(--systemPrimary)",
                              borderRadius: "25%",
                              width: "24px",
                              padding: "6px",
                            }}
                          >
                            <span
                              className="button-text"
                              style={{
                                padding: "0px",
                                borderRadius: "5px",
                                width: "100%",
                              }}
                            >
                              {userScores && userScores[movie.Const]
                                ? `${userScores[movie.Const]}`
                                : `${movie.CuratorScore}`}
                            </span>
                          </button>
                        </div>

                        <div className="dropdown">
                          <button
                            onClick={(e) => toggleSeenStatus(movie, e)}
                            className={`dropdown-button3 ${
                              isSeen ? "active" : ""
                            }`}
                          >
                            {renderSeenIcon(movie)}
                            {/* <DropDown className="dropdown-icon" /> */}

                            <span
                              className="button-text"
                              style={{ padding: "5px" }}
                            >
                              {isSeen ? <p>Seen</p> : <p>Seen</p>}
                            </span>

                            {/* <h2
                              className="explore-landing__title typography-large-title-emphasized toggle-moviesB4"
                              style={{ paddingTop: "0px", padding: "5px" }}
                            >
                              {isSeen ? <p>Seen</p> : <p>Seen</p>}
                            </h2> */}
                          </button>
                        </div>

                        <div className="dropdown">
                          <button
                            onClick={(e) => toggleWatchlistStatus(movie, e)}
                            className={`dropdown-button3 ${
                              isInWatchlist ? "active" : ""
                            }`}
                          >
                            {/* <DropDown className="dropdown-icon" /> */}
                            {renderWatchlistIcon(movie)}
                            <span
                              className="button-text"
                              style={{ padding: "5px" }}
                            >
                              {isInWatchlist ? <p>Save</p> : <p>Save</p>}
                            </span>
                          </button>
                        </div>
                      </div>

                      <div
                        className="review-card tertiary-bg-color rating-card wide-card-full"
                        style={{ position: "relative", paddingBottom: "25px" }}
                      >
                        <div className="review-card__title typ-headline-emph">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div style={{}}>
                              <dt className="typ-caption review-card__title typ-headline-emph">
                                Curator
                              </dt>
                              <dd className="typ-caption clr-secondary-text">
                                OVERALL SCORE
                              </dd>
                            </div>
                            <img
                              width={30}
                              height={30}
                              src={CuratorLogo}
                              alt="Curator Logo"
                              style={{
                                marginBottom: "20px",
                                borderRadius: "50%",
                              }}
                            />
                          </div>

                          <dd className="typ-caption clr-secondary-text">
                            {movie.CuratorScore} / 100 •{" "}
                            {formatRarity4(curatorVoteCount)} •{" "}
                            {Math.round(movie.CuratorScorePercentile)}%
                          </dd>
                          {movie.CuratorScoreRank &&
                            movie.CuratorScorePercentile && (
                              <>
                                <dd className="typ-caption clr-secondary-text"></dd>
                              </>
                            )}
                        </div>
                        <div
                          className="barbar3"
                          style={{
                            width: "100%",
                            backgroundColor: "#49494949",
                            marginTop: "10px",
                            borderRadius: "0px",
                          }}
                        >
                          <div
                            style={{
                              ...scoreDisplayStyle(
                                movie.Const,
                                movie["CuratorScorePercentile"],
                                {}
                              ),
                              width: `${Math.round(
                                movie.CuratorScorePercentile
                              )}%`,
                              height: "10px",
                              borderRadius: "2px",
                            }}
                          ></div>
                        </div>
                      </div>

                      {/* {movie.LetterScore && (
                        <div
                          className="review-card tertiary-bg-color rating-card"
                          style={{
                            position: "relative",
                            paddingBottom: "25px",
                          }}
                        >
                          <div className="review-card__title typ-headline-emph">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div style={{}}>
                                <dt className="typ-caption review-card__title typ-headline-emph">
                                  Letterboxd
                                </dt>
                                <dd className="typ-caption clr-secondary-text">
                                  USER SCORE
                                </dd>
                              </div>
                              <img
                                width={30}
                                height={30}
                                src={LetterLogo}
                                alt="Metacritic Logo"
                                style={{
                                  marginBottom: "20px",
                                  borderRadius: "50%",
                                }}
                              />
                            </div>
                            <dd className="typ-caption clr-secondary-text">
                              {movie.LetterScore} / 100 •{" "}
                              {formatRarity4(movie.LetterCount)} •{" "}
                              {Math.round(movie.LetterScorePercentile)}%
                            </dd>

                            {movie.LetterScoreRank &&
                              movie.LetterScorePercentile && (
                                <>
                                  <dd className="typ-caption clr-secondary-text">
                                  </dd>
                                </>
                              )}
                            <dd className="typ-caption clr-secondary-text"></dd>
                          </div>
                          <div
                            className="barbar3"
                            style={{
                              width: "100%",
                              backgroundColor: "#49494949",
                              marginTop: "10px",
                              borderRadius: "0px",
                            }}
                          >
                            <div
                              style={{
                                ...scoreDisplayStyle(
                                  movie.Const,
                                  movie["LetterScorePercentile"],
                                  {}
                                ),
                                width: `${Math.round(
                                  movie.LetterScorePercentile
                                )}%`,
                                height: "10px",
                                borderRadius: "2px",
                              }}
                            ></div>
                          </div>
                        </div>
                      )}

                      {movie.Critic && (
                        <div
                          className="review-card tertiary-bg-color rating-card"
                          style={{
                            position: "relative",
                            paddingBottom: "25px",
                          }}
                        >
                          <div className="review-card__title typ-headline-emph">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div style={{}}>
                                <dt className="typ-caption review-card__title typ-headline-emph">
                                  Metacritic
                                </dt>
                                <dd className="typ-caption clr-secondary-text">
                                  CRITIC SCORE
                                </dd>
                              </div>
                              <img
                                width={30}
                                height={30}
                                src={MetaLogo}
                                alt="Metacritic Logo"
                                style={{
                                  marginBottom: "20px",
                                  borderRadius: "50%",
                                }}
                              />
                            </div>
                            <dd className="typ-caption clr-secondary-text">
                              {movie.Critic} / 100 •{" "}
                              {Math.round(movie.CriticPercentile)}%
                            </dd>
                            {movie.CriticRank && movie.CriticPercentile && (
                              <>
                                <dd className="typ-caption clr-secondary-text">
                                </dd>
                              </>
                            )}
                          </div>
                          <div
                            className="barbar3"
                            style={{
                              width: "100%",
                              backgroundColor: "#49494949",
                              marginTop: "10px",
                              borderRadius: "0px",
                            }}
                          >
                            <div
                              style={{
                                ...scoreDisplayStyle(
                                  movie.Const,
                                  movie["CriticPercentile"],
                                  {}
                                ),
                                width: `${Math.round(movie.CriticPercentile)}%`,
                                height: "10px",
                                borderRadius: "2px",
                              }}
                            ></div>
                          </div>
                        </div>
                      )} */}

                      {/* <div
                        className="review-card tertiary-bg-color rating-card"
                        style={{ position: "relative", paddingBottom: "25px" }}
                      >
                        <div className="review-card__title typ-headline-emph">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div style={{}}>
                              <dt className="typ-caption review-card__title typ-headline-emph">
                                IMDb
                              </dt>
                              <dd className="typ-caption clr-secondary-text">
                                USER SCORE
                              </dd>
                            </div>
                            <img
                              width={30}
                              height={30}
                              src={ImdbLogo}
                              alt="ImdbLogo Logo"
                              style={{
                                marginBottom: "20px",
                                borderRadius: "50%",
                              }}
                            />
                          </div>

                          <dd className="typ-caption clr-secondary-text">
                            {movie.User * 10} / 100 •{" "}
                            {formatRarity4(movie.Rarity)} •{" "}
                            {Math.round(movie.UserPercentile)}%
                          </dd>
                          {movie.UserRank && movie.UserPercentile && (
                            <>
                              <dd className="typ-caption clr-secondary-text">
                              </dd>
                            </>
                          )}
                        </div>
                        <div
                          className="barbar3"
                          style={{
                            width: "100%",
                            backgroundColor: "#49494949",
                            marginTop: "10px",
                            borderRadius: "0px",
                          }}
                        >
                          <div
                            style={{
                              ...scoreDisplayStyle(
                                movie.Const,
                                movie["UserPercentile"],
                                {}
                              ),
                              width: `${Math.round(movie.UserPercentile)}%`,
                              height: "10px",
                              borderRadius: "2px",
                            }}
                          ></div>
                        </div>
                      </div> */}

                      <div className="review-card tertiary-bg-color wide-card-full">
                        <div className="review-card__title typ-headline-emph">
                          <span>Plot Overview</span>
                          <span className="review-card__title--sub typ-footnote clr-secondary-text">
                            <ul className="review-card__title--sub--list">
                              <li>{movie.overview ? " TMDB" : " IMDB"}</li>
                            </ul>
                          </span>
                        </div>
                        <div className="line-clamp-wrapper">
                          {/* <div className="product-footer__description typ-subhead clr-secondary-text" dir="auto"> */}
                          <div
                            className="product-footer__description typ-subhead clr-secondary-text"
                            style={{ linkLength: "4" }}
                            dir="auto"
                          >
                            {/* <div className="line-clamp line-clamp--with-more product-footer__description typ-subhead clr-secondary-text" style={{ linkLength: "4" }} dir="auto"> */}
                            {movie.overview || movie.Plot}
                          </div>
                          {/* <button className="line-clamp__more lt-line-clamp__more typography-footnote-emphasized" type="button">
                                            MORE
                                        </button> */}
                        </div>
                      </div>

                      <div className="review-card tertiary-bg-color rating-card wide-card3">
                        <div className="review-card__title typ-headline-emph">
                          <dl>
                            <dt className="typ-caption review-card__title typ-headline-emph">
                              Extended Details
                            </dt>
                            <br></br>
                            <dt
                              className="typ-caption review-card__title typ-headline-emph"
                              style={{
                                fontSize: "14px",
                                fontWeight: "700",
                                marginBottom: "3px",
                              }}
                            >
                              Cast and Crew
                            </dt>
                            {movie.Director && movie.Director.length > 0 && (
                              <>
                                <dt className="typ-caption">
                                  <strong>Director</strong>
                                  {Array.isArray(movie.Director) &&
                                  movie.Director.length > 1
                                    ? "s"
                                    : ""}
                                </dt>
                                <dd className="typ-caption clr-secondary-text">
                                  {Array.isArray(movie.Director)
                                    ? movie.Director.join(", ")
                                    : movie.Director}
                                </dd>
                                <br></br>
                              </>
                            )}
                            {/* <>
                              <dt className="typ-caption">
                                <strong>Stars</strong>{" "}
                              </dt>
                              <dd className="typ-caption clr-secondary-text">
                                {renderAllRankings()}
                              </dd>
                              <br></br>
                            </> */}
                            <dt className="typ-caption">
                              <strong>Starring</strong>
                            </dt>
                            <dd className=" typ-caption clr-secondary-text">
                              {Array.isArray(movie.Stars) ? (
                                movie.Stars.map((star, index) => (
                                  <div key={index}>{star}</div>
                                ))
                              ) : (
                                <div>{movie.Stars}</div>
                              )}
                            </dd>
                            <br></br>
                            <dt
                              className="typ-caption review-card__title typ-headline-emph"
                              style={{
                                fontSize: "14px",
                                fontWeight: "700",
                                marginBottom: "3px",
                              }}
                            >
                              More Details
                            </dt>

                            <dd className="typ-caption clr-secondary-text">
                              {renderAwardStatus(
                                movie.YearRank === 1,
                                movie.YearRank && movie.YearRank <= 10,
                                "Curator",
                                movie.Year
                              )}
                              <br />
                              {renderAwardStatus(
                                movie["Won Best Picture"],
                                movie["Nominated for Best Picture"],
                                "Oscar",
                                movie.Year
                              )}
                            </dd>
                            <br />

                            <dt
                              className="typ-caption review-card__title typ-headline-emph"
                              style={{
                                fontSize: "14px",
                                fontWeight: "700",
                                marginBottom: "3px",
                              }}
                            >
                              Rankings
                            </dt>
                            <>
                              <dt className="typ-caption">
                                <strong>Time Period</strong>
                                <br />
                                <strong
                                  style={getStyle(
                                    "year",
                                    movie.YearRank,
                                    yearTotal
                                  )}
                                >
                                  {Math.round(
                                    calculatePercentile(
                                      movie.YearRank,
                                      yearTotal
                                    )
                                  )}
                                  %{" "}
                                </strong>{" "}
                                from {movie.Year}{" "}
                                <strong
                                  style={getStyle("rank", movie.YearRank)}
                                >
                                  #{movie.YearRank}
                                </strong>
                              </dt>

                              <dd className="typ-caption clr-secondary-text">
                                <strong
                                  style={getStyle(
                                    "decade",
                                    movie.DecadeRank,
                                    decadeTotal
                                  )}
                                >
                                  {Math.round(
                                    calculatePercentile(
                                      movie.DecadeRank,
                                      decadeTotal
                                    )
                                  )}
                                  %
                                </strong>{" "}
                                from {Math.floor(movie.Year / 10) * 10}s{" "}
                                <strong
                                  style={getStyle("decade", movie.DecadeRank)}
                                >
                                  #{movie.DecadeRank}
                                </strong>
                              </dd>
                              <br />
                              <dd className="typ-caption clr-secondary-text">
                                <strong>Other</strong>
                              </dd>
                              {stats.map((stat) => (
                                <dd
                                  key={stat.name}
                                  className="typ-caption clr-secondary-text"
                                >
                                  <strong style={determineStyle(stat.value)}>
                                    {stat.value}
                                  </strong>{" "}
                                  {stat.name} <strong>{stat.score}</strong>
                                </dd>
                              ))}

                              <br />
                              <dd className="typ-caption clr-secondary-text">
                                <strong>Cast and Crew</strong>
                              </dd>
                              <dd className="typ-caption clr-secondary-text">
                                {renderDirectorRanks()}
                              </dd>
                              <dd className="typ-caption clr-secondary-text">
                                {renderAllRankings()}
                              </dd>

                              {/* <dt className="typ-caption">
                                <strong
                                  style={getStyle(
                                    "year",
                                    movie.YearRank,
                                    yearTotal
                                  )}
                                >
                                  {Math.round(percentile)}%{" "}
                                </strong>{" "}
                                My Score{" "}
                                <strong
                                  style={getStyle("rank", movie.YearRank)}
                                >
                                  {currentScore}
                                </strong>
                              </dt> */}

                              <br />
                            </>
                            {/* <dt className="typ-caption">Countries of Origin</dt>
                                            <dd className="typ-caption clr-secondary-text">
                                                {movie.countriesOfOrigin.countries.map((country, index) => (
                                                <div key={index}>{country.id}</div>
                                                ))}
                                            </dd> 
                                            <br></br> */}
                            {/* <dt className="typ-caption">Languages</dt>
                                            <dd className="typ-caption clr-secondary-text">
                                                {movie.spokenLanguages.spokenLanguages.map((language, index) => (
                                                <div key={index}>{language.text}</div>
                                                ))}
                                            </dd>
                                            <br></br> */}
                            {/* <dt className="typ-caption">Box Office</dt>
                                            <dd className="typ-caption clr-secondary-text">
                                                ${movie.lifetimeGross.total.amount.toLocaleString()} {movie.lifetimeGross.total.currency}
                                            </dd>
                                            <br></br> */}
                            {/* <dt className="typ-caption">Production Companies</dt>
                                            <dd className="typ-caption clr-secondary-text">
                                                {movie.production.edges.map((edge, index) => <div key={index}>{edge.node.company.companyText.text}</div>)}
                                            </dd>
                                            <br></br>

                                            <dt className="typ-caption">Keywords</dt>
                                            <dd className="typ-caption clr-secondary-text">
                                                {movie.keywords.join(", ")}
                                            </dd>
                                            <br></br> */}
                            {/* 
                                            <dt className="typ-caption">Last Updated</dt>
                                            <dd className="typ-caption clr-secondary-text">
                                                {new Date(movie.LastUpdated.$date).toLocaleDateString()}
                                            </dd> 
                                            <br></br>
                                            */}
                          </dl>
                        </div>
                      </div>

                      {/* <div className="review-card tertiary-bg-color rating-card">
                                    <div className="review-card__title typ-headline-emph">
                                        <dl>
                                        {movie.SimilarMovies && (
                                            <>
                                            <dt className="typ-caption">Similar Movie IDs</dt>
                                            <dd className=" typ-caption clr-secondary-text">
                                                {Array.isArray(movie.SimilarMovies) ? (
                                                movie.SimilarMovies.map((movie, index) => (
                                                    <div key={index}>{movie}</div>
                                                ))
                                            ) : (
                                                <div>{movie.SimilarMovies}</div> 
                                            )}
                                            </dd>
                                            </>
                                        )}
                                        </dl>
                                    </div>
                                </div> */}
                    </div>
                  </div>

                  <div
                    className="shelf-grid shelf-grid--onhover bigger1000"
                    style={{
                      marginTop: "20px",
                      display: "block",
                      scrollbarWidth: "none",
                      padding: "0px",
                      margin: "0px",
                      backgroundColor: "transparent",
                      msOverflowStyle: "none",
                    }}
                  >
                    {uniqueSortedSimilarMovies.length > 0 ? (
                      <React.Fragment>
                        <MovieShelf
                          title="More Like This"
                          // subtitle="Because you liked this"
                          // movies={movie.SimilarMovies}
                          movies={uniqueSortedSimilarMovies}
                          onMovieSelect={handleSelectSimilarMovie}
                          userScores={userScores}
                          userId={user?.sub}
                          seenMoviesProp={seenMovies}
                          watchlistProp={watchlist}
                          munich={true}
                        />
                      </React.Fragment>
                    ) : (
                      // Optional: display a message or alternative content when there are no similar movies
                      <div style={{ padding: "20px", color: "#666" }}>
                        No similar movies found.
                      </div>
                    )}
                  </div>

                  <div></div>
                </div>

                {/* <div className="divider"></div> */}
                <div
                  className="shelf-grid shelf-grid--onhover smaller1000"
                  style={{ marginTop: "20px" }}
                >
                  {uniqueSortedSimilarMovies.length > 0 ? (
                    <React.Fragment>
                      <MovieShelf
                        title="More Like This"
                        // subtitle="Because you liked this"
                        // movies={movie.SimilarMovies}
                        movies={uniqueSortedSimilarMovies}
                        onMovieSelect={handleSelectSimilarMovie}
                        userScores={userScores}
                        userId={user?.sub}
                        seenMoviesProp={seenMovies}
                        watchlistProp={watchlist}
                        munich={true}
                      />
                    </React.Fragment>
                  ) : (
                    // Optional: display a message or alternative content when there are no similar movies
                    <div style={{ padding: "20px", color: "#666" }}>
                      No similar movies found.
                    </div>
                  )}
                </div>

                {/* <div className="shelf-grid__header">
                                <div className="shelf-header--with-see-all">
                                <a href="/movies" className="typ-headline-emph">
                                    <h2 className="typ-headline-emph">
                                    Similar Movies
                                    </h2> 
                                </a>
                                </div>  
                            </div> */}

                {/* <div className="shelf-grid__body">
                                <ul className="shelf-grid__list special-shelf">
                                {sortedSimilarMovies.map((similarMovie, simIndex) => (
                                    <li key={simIndex} className="shelf-grid__list-item">
                                        <div className="ordinal-chart__artwork" onClick={() => onSimilarMovieSelect(similarMovie._id)}>
                                        <div className="canvas-lockup">
                                            <div className={`media-artwork-v2__container media-artwork-v2--image-did-attempt-download canvas-lockup__artwork ${similarMovie.Rank > 750 ? '' : similarMovie.Rank > 500 ? 'bronze' : similarMovie.Rank > 250 ? 'silver' : similarMovie.Rank > 100 ? 'gold' : 'diamond'}`} style={{ paddingTop: '56.25%', height: '100%', aspectRatio: '.66' }}>
                                                <picture>
                                                    <img 
                                                        src={
                                                            `${similarMovie.poster_path.replace('/', 'https://image.tmdb.org/t/p/w600_and_h900_bestv2/')}`
                                                        } 
                                                        decoding="async" 
                                                        loading="eager" 
                                                        className="media-artwork-v2__image" 
                                                        alt={similarMovie.Title} 
                                                        role="presentation"
                                                    />
                                                </picture>
                                                </div>
                                                <div className={`lockup-overlay ${similarMovie.Rank > 750 ? '' : similarMovie.Rank > 500 ? '' : similarMovie.Rank > 250 ? '' : similarMovie.Rank > 100 ? '' : ''} lockup-overlay--button-container lockup-overlay__generic_content`}>
                                                    
                                                    <button onClick={() => onSimilarMovieSelect(similarMovie._id)} className={`lockup-button ${similarMovie.Rank > 750 ? '' : similarMovie.Rank > 500 ? '' : similarMovie.Rank > 250 ? '' : similarMovie.Rank > 100 ? '' : ''} lockup-button--context-menu lockup-button--grouped`} type="button">
                                                    </button>

                                                    <div className="ordinal-chart-item__title similar-text clr-primary-text lt-line-clamp lt-line-clamp--multi-line ember-view" style={{ textAlign: "center", top: "0px" }}>
                                                        <strong>{similarMovie.CuratorScore} • {similarMovie.Streaming}</strong>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="ordinal-chart__content">
                                        </div>
                                    </li>
                                ))}
                                </ul>
                            </div> */}
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
        {/* <Footer /> */}
      </div>
    </div>
  );
};

export default MovieDetailPopup;
