import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Auth0Provider } from "@auth0/auth0-react";
import history from "./utils/history";
import { getConfig } from "./config";

const onRedirectCallback = (appState) => {
  history.push(appState?.returnTo || window.location.pathname);
};

const config = getConfig();

const root = createRoot(document.getElementById('root'));
root.render(
  <Auth0Provider
    domain={config.domain}
    clientId={config.clientId}
    cacheLocation="localstorage"
    useRefreshTokens={true}
    onRedirectCallback={onRedirectCallback}
    scope="openid profile email offline_access"
    authorizationParams={{
      redirect_uri: window.location.origin, // Moved redirectUri here
      scope: "openid profile email offline_access",
    }}
  >
    <App />
  </Auth0Provider>,
);

serviceWorker.unregister();
