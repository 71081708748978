import React from 'react';
import streamingServiceIcons from './streamingServiceIcons';
import streamingServiceIconsMy from './streamingServiceIconsMy';
import DefaultIcon from '../assets/eee.svg';

const StreamingIcon = ({ serviceName }) => {
  const baseLogoUrl = "https://media.themoviedb.org/t/p/original";
  
  // Find the logo URL for the given service name
  const service = streamingServiceIconsMy.find(s => s.name === serviceName);

  // If the service is found, render its logo. Otherwise, render a fallback UI.
  return service ? (
    <div className="streaming-service">
      <img src={`${baseLogoUrl}${service.logo_url}`} alt={`${serviceName} logo`} className="streaming-service-icon"/>
    </div>
  ) : (
    <div className="streaming-service">
      {/* <img src={DefaultIcon} className="streaming-service-icon"/> */}
    </div>
  );
};

export default StreamingIcon;
