import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import AutoplayVideo from "./AutoplayVideo";
import StreamingIcon from "./StreamingIcon";

const MovieShelf = ({ className, title, subtitle, movies, onMovieSelect, displayUserScore, userScores, userScores2: initialUserScores, isFirstShelf, scoreType, generateRef, userId, updateUserData, seenMoviesProp, watchlistProp, munich }) => {
  const [userScores2, setUserScores2] = useState(initialUserScores);
  const [hoveredMovieId, setHoveredMovieId] = useState(null);
  const getVideoSrc = (videoId) => `https://www.youtube.com/embed/${videoId}?autoplay=1&mute=1`;
  const munichClass = munich || false; // Ensures munichClass is at least false if munich is undefined

  let apiBaseUrl = '';
  // console.log(`ENV: ${process.env.REACT_APP_NODE_ENV}`);
  if (process.env.REACT_APP_NODE_ENV === 'development') {
      // Use the development base URL
      apiBaseUrl = 'http://localhost:3001';
  } else {
      // Use the production base URL (empty string for relative URL) e
      apiBaseUrl = '';
  }
  // console.log(`User watchlist:`, watchlistProp);
  // console.log(`User seen movies`, seenMoviesProp);

  // Convert seenMoviesProp and watchlistProp to Sets for easier manipulation
  const [seenMovies, setSeenMovies] = useState(new Set(seenMoviesProp));
  const [watchlist, setWatchlist] = useState(new Set(watchlistProp));
  const { user, getAccessTokenSilently, isAuthenticated} = useAuth0();

  // const hasMoviesToDisplay = movies.some(movie => movie && movie.poster_path);
  const hasMoviesToDisplay = movies.some(movie => movie && (movie.poster_path || movie.Poster));
  // const hasMoviesToDisplay = true;
  // console.log(title, movies);

  useEffect(() => {
    // Update local state when props change
    setSeenMovies(new Set(seenMoviesProp));
    setWatchlist(new Set(watchlistProp));
  }, [seenMoviesProp, watchlistProp]);

  const toggleSeenStatus = async (movie, event) => {
    event.stopPropagation(); 
    const isSeen = seenMovies.has(movie.Const);
    const endpoint = isSeen ? '/api/user/removeMovie' : '/api/user/addMovie';
    const movieData = isSeen ? { userId, movieConst: movie.Const } : { userId, movie };

    try {
      await axios.post(`${apiBaseUrl}${endpoint}`, movieData);
      isSeen ? seenMovies.delete(movie.Const) : seenMovies.add(movie.Const);
      setSeenMovies(new Set([...seenMovies]));
    } catch (error) {
      console.error('Error toggling seen status:', error);
    }
  };

  const toggleWatchlistStatus = async (movie, event) => {
    event.stopPropagation(); // Prevent triggering movie card click
    const isInWatchlist = watchlist.has(movie.Const);
    const endpoint = isInWatchlist ? '/api/user/removeFromWatchlist' : '/api/user/addToWatchlist';
    const movieData = { userId, movieConst: movie.Const };

    try {
      await axios.post(`${apiBaseUrl}${endpoint}`, movieData);
      isInWatchlist ? watchlist.delete(movie.Const) : watchlist.add(movie.Const);
      setWatchlist(new Set([...watchlist]));
    } catch (error) {
      console.error('Error toggling watchlist status:', error);
    }
  };

  const getTextColorStyleBasedOnBackground = (dominantColor) => {
    const isDarkBackground = isColorDark(dominantColor);
    // Define text color for light backgrounds if needed
    const colorForLightBackground = 'hsla(0, 0%, 20%, 1)'; // Adjust as needed
    return {
      labelColor: isDarkBackground ? 'hsla(0, 0%, 100%, .64)' : colorForLightBackground,
      valueColor: isDarkBackground ? 'white' : 'black', // Adjust as needed for light backgrounds
    };
  };

  // Helper function to dynamically generate score text
  const getScoreDetails = (movie) => {
    // console.log('seen movies:', seenMovies);
    // const textColorStyle = getTextColorStyleBasedOnBackground(movie.DominantColor || 'rgb(0, 0, 0)'); // Fallback to a dark background
    const textColorStyle = getTextColorStyleBasedOnBackground('rgb(0, 0, 0)'); // Fallback to a dark background

    // Determine If userscore for the movie exists, use that, otherwise use the movie.CuratorScore
    const score = displayUserScore && userScores && userScores[movie.Const] ? userScores[movie.Const] : movie.CuratorScore;

    // console.log(`userScores:`, userScores);
    // const score = userScores[movie.Const];

    const scoreLabel = userScores && userScores[movie.Const] ? 'My' : 'Cu';
    //Print combination of score and scoreLabel
    const displayScore = `${scoreLabel}${score}`;

    const details = [
      { label: movie.Streaming, value: score },
    ];
    
    // Conditionally add user score if it exists
    // if (displayUserScore && userScores && userScores[movie.Const]) {
    //   details.push({ label: 'My Score', value: userScores[movie.Const] });
    // }

    return details.map(detail => (
      <div key={movie.Const}>
        <span>{detail.value}</span><br></br>
        {/* <span style={{ color: textColorStyle.valueColor }}>{detail.label}</span> */}
      </div>
    ));
  };

  const renderWatchlistIcon = (movie) => {
    const isInWatchlist = watchlist.has(movie.Const);
    // const { iconFillColor } = getButtonStyles(movie.DominantColor || 'rgb(0, 0, 0)'); // Assuming a dark default
    const { iconFillColor } = getButtonStyles('rgb(0, 0, 0)'); // Assuming a dark default

    return (
      <span>
        {isInWatchlist ? 
          (<svg style={{ width: "24px", fill: iconFillColor, stroke: iconFillColor, strokeWidth: "2px", marginBottom: "7px" }} focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M17 3H7c-1.1 0-1.99.9-1.99 2L5 21l7-3 7 3V5c0-1.1-.9-2-2-2z"></path></svg>) : 
          (<svg style={{ width: "24px", fill: "none", stroke: iconFillColor, strokeWidth: "2px", marginBottom: "7px" }} focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M17 3H7c-1.1 0-1.99.9-1.99 2L5 21l7-3 7 3V5c0-1.1-.9-2-2-2zm0 21.35z"/></svg>)}
      </span>
    );
  };

  // (<svg style={{ width: "50%", fill: "none", stroke: iconFillColor, strokeWidth: "2px", marginBottom: "7px" }} focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M17 3H7c-1.1 0-1.99.9-1.99 2L5 21l7-3 7 3V5c0-1.1-.9-2-2-2zm0 21.35z"/></svg>)}

  const renderSeenIcon = (movie) => {
    const isSeen = seenMovies.has(movie.Const);
    // const { iconFillColor } = getButtonStyles(movie.DominantColor || 'rgb(0, 0, 0)'); // Assuming a dark default
    const { iconFillColor } = getButtonStyles('rgb(0, 0, 0)'); // Assuming a dark default


    return (
      <span>
        {isSeen ? 
          (<svg style={{ width: "24px", fill: iconFillColor, stroke: iconFillColor, strokeWidth: "2px", marginBottom: "7px" }} focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M17 3H7c-1.1 0-1.99.9-1.99 2L5 21l7-3 7 3V5c0-1.1-.9-2-2-2z"></path></svg>) : 
          (<svg style={{ width: "24px", fill: "none", stroke: iconFillColor, strokeWidth: "2px", marginBottom: "7px" }} focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M17 3H7c-1.1 0-1.99.9-1.99 2L5 21l7-3 7 3V5c0-1.1-.9-2-2-2zm0 21.35z"/></svg>)}
      </span>
    );
  };

  const isColorDark = (rgb) => {
    const color = rgb.match(/\d+/g).map(Number);
    const luminance = 0.299 * color[0] + 0.587 * color[1] + 0.114 * color[2];
    return luminance < 86;
  };

  const formatRarity = (rarity) => {
    let formattedRarity;
    if (rarity < 1000) {
        formattedRarity = rarity.toString();
    } else if (rarity < 1000000) {
        formattedRarity = (rarity / 1000).toFixed(0) + 'K';
    } else {
        formattedRarity = (rarity / 1000000).toFixed(0) + 'M';
    }
    return `(${formattedRarity})`;
  };

  const getDominantColorStyle = (dominantColor) => {
    const isDark = isColorDark(dominantColor);
    // const isDark = true;

    return {
      backgroundColor: dominantColor,
      color: !isDark ? 'black' : 'white',
      fontWeight: '500',
    };
  };

  // Convert the dominant color to rgba format with the desired opacity
  const convertRgbToRgba = (rgb, opacity) => {
    // Assuming rgb is in format "rgb(x, y, z)"
    const rgba = rgb.replace('rgb', 'rgba').replace(')', `, ${opacity})`);
    return rgba;
  };

  // Function to get button and icon styles based on background color
  const getButtonStyles = (dominantColor) => {
    const isDarkBackground = isColorDark(dominantColor);
    // const isDarkBackground = true;
    return {
      backgroundColor: !isDarkBackground ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.3)', // Slightly transparent for visual depth
      iconFillColor: !isDarkBackground ? 'black' : 'white', // This assumes icons need to contrast with button background
    };
  };

  const formatRuntime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    if (hours === 0) return `${minutes} min`;
    if (minutes === 0) return `${hours} hr`;
    return `${hours} hr ${minutes} min`;
  };
  

  const handleAddAndRateMovie = async (movieConst, movieId) => {
    if (!isAuthenticated) {
      alert("Please log in to rate movies."); // Replace this with a UI-based prompt when you're ready.
      return; // Exit the function early.
    }
  
    // Continue with your logic for adding and rating a movie.
    const newScore = prompt('Enter your score (1-99):');
    if (newScore && newScore >= 1 && newScore <= 99) {
      if (!seenMovies.has(movieConst)) {
        await addMovieToSeenList(movieId);
      }
      await handleScoreChange(movieConst, newScore);
    }
  };

  const handleScoreChange = async (constId, newScore) => {
    try {
        const token = await getAccessTokenSilently();
        await axios.post(`${apiBaseUrl}/api/user/rateMovie`, {
            userId: user.sub,
            Const: constId,
            score: newScore
        }, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        // Update the userScores state to include the new score
        setUserScores2(prevUserScores => ({ ...prevUserScores, [constId]: newScore }));

        // Check if the movie is already in seenMovies before adding it
        if (!seenMovies.has(constId)) {
            setSeenMovies(prevSeenMovies => new Set([...prevSeenMovies, constId]));
        }
    } catch (error) {
        console.error('Error rating movie:', error);
    }
};

const removeMovieFromSeenList = async (movieConst) => {
  try {
    const token = await getAccessTokenSilently();
    await axios.post(`${apiBaseUrl}/api/user/removeMovie`, {
      userId: user.sub,
      movieConst: movieConst, // Send the Const value
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    // Update local seenMovies state
    const newSeenMovies = new Set(seenMovies);
    newSeenMovies.delete(movieConst);
    setSeenMovies(newSeenMovies);
  } catch (error) {
    console.error('Error removing movie from seen list:', error);
  }
};  



  const addMovieToSeenList = async (movieId) => {
    try {
      const token = await getAccessTokenSilently();
      const movie = movies.find(m => m._id === movieId);
      if (!movie) {
        console.error('Movie not found');
        return;
      }
  
      if (seenMovies.has(movie.Const)) {
        // Remove movie from seen list
        await removeMovieFromSeenList(movie.Const);
      } else {
      await axios.post(`${apiBaseUrl}/api/user/addMovie`, {
        userId: user.sub,
        name: user.name,
        movie: { Const: movie.Const, Title: movie.Title }
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // Update the seenMovies state to include the new movie
      setSeenMovies(new Set([...seenMovies, movie.Const]));
      }

    } catch (error) {
      console.error('Error adding movie to seen list:', error);
    }
  };

  // const scoreDisplayStyle = (constId, userScore, curatorScore) => {
  //   const isRated = userScores.hasOwnProperty(constId);
  //   const scoreColor = isRated ? getColorForScore(userScore || curatorScore) : 'transparent'; // Transparent for unrated
  //   const borderColor = isRated ? getColorForScore(userScore) : getColorForScore(curatorScore);
  //   const borderRadius = isRated ? '50px' : '50px'; // 6px for rated, 50px for unrated

  //   return {
  //     backgroundColor: scoreColor,
  //     border: `4px solid ${borderColor}`,
  //     borderRadius: borderRadius
  //   };
  // };

  const scoreDisplayStyle = (constId, score, additionalStyles) => {
    const isRated = true;
    const scoreColor = isRated
      ? getColorForScore(score || score)
      : "transparent"; // Transparent for unrated
    const borderColor = isRated
      ? getColorForScore(score)
      : getColorForScore(score);
    const borderRadius = isRated ? "50px" : "50px"; // 6px for rated, 50px for unrated

    const baseStyle = {
      backgroundColor: scoreColor,
      border: `4px solid ${borderColor}`,
      borderRadius: borderRadius,
      height: "30px",
      width: "30px",
      zIndex: "10",
    };

    return { ...baseStyle, ...additionalStyles };
  };

  // const getColorForScore = (score) => {
  //   let red, green;
  //   if (score >= 80) {
  //       // Score is between 80 and 100, blend from yellow to green
  //       red = Math.round(255 * (100 - score) / 20); // Decrease red as score approaches 100
  //       green = 255;
  //   } else if (score > 60) {
  //       // Score is between 60 and 80, blend from red to yellow
  //       red = 255;
  //       green = Math.round(255 * (score - 60) / 20); // Increase green as score approaches 80
  //   } else {
  //       // Score is 60 or below, display red
  //       red = 255;
  //       green = 0;
  //   }
  //   return `rgb(${red}, ${green}, 0)`; // Return RGB color string
  // };

  const getColorForScore = (score) => {
    let red, green;
    if (score >= 50) {
      red = Math.round((255 * (100 - score)) / 50);
      green = 255;
    } else {
      red = 255;
      green = Math.round((255 * score) / 50);
    }
    return `rgb(${red}, ${green}, 0)`;
  };

  const formatRarityRounded = (rarity) => {
    let formattedRarity;
    if (rarity < 100000) {
        // Round to the nearest ten thousand if less than 100k
        const rounded = Math.round(rarity / 10000) * 10000;
        formattedRarity = rounded < 1000 ? rarity.toString() : (rounded / 1000).toFixed(0) + 'K+';
    } else if (rarity >= 100000 && rarity < 1000000) {
        // Different rounding logic for 100k to 1 million
        const rounded = Math.round(rarity / 100000) * 100000;
        formattedRarity = (rounded / 1000).toFixed(0) + 'K+';
    } else if (rarity >= 1000000) {
        // Different rounding logic for more than 1 million
        const rounded = Math.round(rarity / 1000000) * 1000000;
        formattedRarity = (rounded / 1000000).toFixed(0) + 'M+';
    } else {
        // Handle case for rarity < 1000 without special rounding
        formattedRarity = rarity.toString();
    }
    return `${formattedRarity}`;
};

    // const [isHovered, setIsHovered] = useState(false);
    // const youtubeVideoId = "RlbR5N6veqw"; // Example YouTube video ID
    // // Function to generate YouTube embed URL with autoplay
    // const getVideoSrc = (videoId) => `https://www.youtube.com/embed/${videoId}?autoplay=1&mute=1`;


  return hasMoviesToDisplay ? (
    <div className={`shelf-grid ${className} shelf-grid--onhover snap-shelf ${munich ? 'munich-padless' : ''} `}>
      <div className={`shelf-grid__header ${munich ? 'munich-header' : ''} `}>
        {/* If similar movies exist display title div: */}
        <div className="shelf-header--with-see-all">
          <h2 className="typ-headline-emph">{title}</h2>
          <span className="typ-subhead shelf-subheader clr-secondary-text">{subtitle}</span>
        </div>
      </div>
      <div className="shelf-grid__body">
        <ul className={`shelf-grid__list special-shelf first-shelf ${munich ? 'munich' : ''} `} style={{ '--grid-rows': '1','--grid-column-gap': '20px' }}>
          {movies.map((movie, index) => {
            // console.log(movie);
            if (!movie || (movie.poster_path === null && movie.Poster === null) || (movie.poster_path === undefined && movie.Poster === undefined)) {
              // Option 1: Skip rendering this movie
              return null;

              //Option 2: Render a placeholder for unavailable movie
              // return (
              //   <li key={index} className="shelf-grid__list-item first-shelf">
              //     <div className="canvas-lockup">
              //       <div className="media-artwork-v2__container media-artwork-v2--image-did-attempt-download canvas-lockup__artwork" style={{ paddingTop: '56.25%', height: '100%', aspectRatio: '.66' }}>
              //         {/* <img src={`${movie.poster_path.replace('/', 'https://image.tmdb.org/t/p/w600_and_h900_bestv2/')}`} loading={isFirstShelf && index < 10 ? "eager" : "lazy"} alt={movie.Title} className="media-artwork-v2__image" /> */}
              //         {/* <img src={`${movie.backdrop_path.replace('/', 'https://image.tmdb.org/t/p/w1280/')}`} loading={isFirstShelf && index < 10 ? "eager" : "lazy"} alt={movie.Title} className="media-artwork-v2__image" /> */}
              //       </div>
              //       <div className="lockup-overlay lockup-overlay--button-container lockup-overlay__generic_content">
              //       </div>
              //     </div>
              //   </li>
              // );
            }

            // const dominantColor = movie.DominantColor || 'rgb(0, 0, 0)'; // Fallback to black if undefined
            const dominantColor = 'rgb(0, 0, 0)'; // Fallback to black if undefined
            const dominantColorStyle = getDominantColorStyle(dominantColor);
            const buttonStyles = getButtonStyles(dominantColor);
            const overlayStyle = {
              background: `linear-gradient(to bottom, ${convertRgbToRgba(dominantColor, 0.1)} 0%, ${convertRgbToRgba(dominantColor, 0.3)} 50%, ${convertRgbToRgba(dominantColor, 0.6)} 92%, ${dominantColor} 92%)`,
            };

            return (
              <li
                key={index}
                // className="shelf-grid__list-item first-shelf mickie3"
                className={`shelf-grid__list-item first-shelf ${
                  munich ? "munich-list" : ""
                }`}
                ref={generateRef ? generateRef(movie.Const) : null}
                onMouseEnter={() => setHoveredMovieId(movie.Const)}
                onMouseLeave={() => setHoveredMovieId(null)}
              >
                <a
                  className="ordinal-chart-lockup"
                  onClick={() => onMovieSelect(movie)}
                >
                  <div className="ordinal-chart__artwork">
                    <div className="canvas-lockup">
                      <div
                        className="media-artwork-v2__container media-artwork-v2--image-did-attempt-download canvas-lockup__artwork"
                        style={{ paddingTop: "56.25%", height: "100%" }}
                      >
                        {/* <div
                          className="barbar3"
                          style={{
                            width: "100%",
                            backgroundColor: "#49494900",
                            backdropFilter: "unset",
                            marginTop: "10px",
                            borderRadius: "0px",
                            bottom: "-10px",
                            zIndex: "2",
                            display: "flex",
                            justifyContent: "center",
                            height: "50px",
                            borderBottomLeftRadius: "12px",
                            borderBottomRightRadius: "12px",
                          }}
                        >
                          {movie.DecadeRank && movie.DecadeRank <= 10 ? (
                            <div
                              style={{
                                backgroundColor: "rgba(0, 0, 0, 0.9)",
                                padding: "10px",
                                borderRadius: "3px",
                                height: "35px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",

                                borderTopLeftRadius: "12px",
                                borderTopRightRadius: "12px",
                              }}
                            >
                              <span
                                style={{
                                  borderRadius: "0px",
                                  zIndex: "2",
                                  fontSize: "13px",
                                  paddingBottom: "0px",
                                  justifyContent: "center",
                                }}
                              >
                                #{movie.YearRank} Movie from the{" "}
                                {Math.floor(movie.Year / 10) * 10}s <br />
                              </span>
                            </div>
                          ) : movie.YearRank && movie.YearRank <= 10 ? (
                            <div
                              style={{
                                backgroundColor: "rgba(0, 0, 0, 0.9)",
                                padding: "10px",
                                borderRadius: "3px",
                                height: "35px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",

                                borderTopLeftRadius: "12px",
                                borderTopRightRadius: "12px",
                              }}
                            >
                              <span
                                style={{
                                  borderRadius: "0px",
                                  zIndex: "2",
                                  fontSize: "13px",
                                  paddingBottom: "0px",
                                  justifyContent: "center",
                                }}
                              >
                                #{movie.YearRank} Movie from {movie.Year}
                              </span>
                            </div>
                          ) : movie["Won Best Picture"] ? (
                            <div
                              style={{
                                backgroundColor: "rgba(0, 0, 0, 0.9)",
                                padding: "10px",
                                borderRadius: "3px",
                                height: "35px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",

                                borderTopLeftRadius: "12px",
                                borderTopRightRadius: "12px",
                              }}
                            >
                              <span
                                style={{
                                  borderRadius: "0px",
                                  zIndex: "2",
                                  fontSize: "13px",
                                  paddingBottom: "0px",
                                  justifyContent: "center",
                                }}
                              >
                                {movie.Year} Oscar® Winner
                              </span>
                            </div>
                          ) : movie["Nominated for Best Picture"] ? (
                            <div
                              style={{
                                backgroundColor: "rgba(0, 0, 0, 0.9)",
                                padding: "10px",
                                borderRadius: "3px",
                                height: "35px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",

                                borderTopLeftRadius: "12px",
                                borderTopRightRadius: "12px",
                              }}
                            >
                              <span
                                style={{
                                  borderRadius: "0px",
                                  zIndex: "2",
                                  fontSize: "13px",
                                  paddingBottom: "0px",
                                  justifyContent: "center",
                                }}
                              >
                                {movie.Year} Oscar® Nomminated
                              </span>
                            </div>
                          ) : null}
                        </div> */}

                        <img
                          loading={
                            isFirstShelf && index < 10 ? "eager" : "lazy"
                          }
                          alt={movie.Title}
                          className="media-artwork-v2__image"
                          src={
                            window.innerWidth > 10000 && movie.backdrop_path
                              ? `${movie.backdrop_path.replace(
                                  "/",
                                  "https://image.tmdb.org/t/p/w1280/"
                                )}`
                              : movie.poster_path
                              ? `${movie.backdrop_path.replace(
                                  "/",
                                  "https://image.tmdb.org/t/p/w1280/"
                                )}`
                              : `${movie.Poster.replace(
                                  "_V1_.jpg",
                                  "_V1_FMjpg_UX900_.jpg"
                                )}`
                          }
                        />

                        {/* {hoveredMovieId === movie.Const ? (
                          <AutoplayVideo
                            videoId={movie.youtubeTrailerId}
                            className="media-artwork-v2__image"
                          />
                        ) : (
                          <img
                            loading={
                              isFirstShelf && index < 10 ? "eager" : "lazy"
                            }
                            alt={movie.Title}
                            className="media-artwork-v2__image"
                            src={
                              window.innerWidth > 10000 && movie.backdrop_path
                                ? `${movie.backdrop_path.replace(
                                    "/",
                                    "https://image.tmdb.org/t/p/w1280/"
                                  )}`
                                : movie.poster_path
                                ? `${movie.backdrop_path.replace(
                                    "/",
                                    "https://image.tmdb.org/t/p/w1280/"
                                  )}`
                                : `${movie.Poster.replace(
                                    "_V1_.jpg",
                                    "_V1_FMjpg_UX900_.jpg"
                                  )}`
                            }
                          />
                        )} */}
                      </div>
                      <div className="lockup-overlay lockup-overlay--button-container lockup-overlay__generic_content">
                        <button
                          aria-label="More"
                          className="lockup-button lockup-button--context-menu lockup-button--grouped"
                          type="button"
                        >
                          <StreamingIcon serviceName={movie.Streaming} />
                        </button>
                      </div>
                    </div>
                  </div>

                  <div
                    className={`ordinal-chart__content ${
                      munich ? "munich-ordinal" : ""
                    } `}
                  >
                    <div className="ordinal-chart__ordinal">
                      <span className="clr-secondary-text">
                        {getScoreDetails(movie)}
                        {/* {movie.CuratorPercentile}% */}
                      </span>
                    </div>
                    <div className="ordinal-chart__metadata">
                      <div
                        className="ordinal-chart-item__title clr-primary-text lt-line-clamp lt-line-clamp--multi-line ember-view"
                        style={{ WebkitLineClamp: "2" }}
                      >
                        {movie.Title}
                      </div>
                      <p className="ordinal-chart-item__metadata clr-secondary-text">
                        {/* <span>
                            {movie.Year}
                        </span>
                        &nbsp;·&nbsp; */}
                        <span>{movie.Streaming}</span>
                      </p>
                    </div>
                  </div>

                  {/* <div className="ordinal-chart__content">
                    <div className="ordinal-chart__metadata">
                      <div className="ordinal-chart-item__title clr-primary-text lt-line-clamp lt-line-clamp--multi-line ember-view otter-bar-top">
                        <div className="review-card__title typ-headline-emph" style={{paddingBottom: "0px", width: "100%"}}>
                          <span style={{fontSize: "26px", paddingBottom: "2px", marginLeft: "-2px"}}>
                              {movie.Title}
                          </span> */}
                  {/* <span className="review-card__title--sub typ-footnote clr-secondary-text">
                              <ul className="review-card__title--sub--list">
                                  <li style={{paddingBottom: "3px", fontSize: "18px", color: "var(--systemPrimary-onDark)"}}>
                                      
                                      <strong>{movie.CuratorScore} / 100</strong> {formatRarityRounded(movie.Rarity)} Ratings
                                  </li>
                              </ul>
                          </span> */}
                  {/* <span className="review-card__title--sub typ-footnote clr-secondary-text">
                              <ul className="review-card__title--sub--list">
                                  <li style={{paddingBottom: "7px"}}>
                                      {movie.Certified} ‧ {movie.Year} ‧ {movie.Genre} ‧ {movie.number_of_seasons > 0 ? `${movie.number_of_seasons} seasons` : formatRuntime(movie.Runtime)}
                                  </li>
                              </ul>
                          </span> */}
                  {/* </div>
                      </div>
                    </div>
                  </div> */}
                  {/* <div className="ordinal-chart__artwork">
                    
                    
                  </div> */}
                  {/* <div className="ordinal-chart__content">
                    <div className="ordinal-chart__metadata">
                      <div className="ordinal-chart-item__title clr-primary-text lt-line-clamp lt-line-clamp--multi-line ember-view otter-bar-bottom" style={{display: "flex"}}>
                        <button
                          onClick={(e) => toggleWatchlistStatus(movie, e)}
                          className={`noImgPlsLeft`}
                          style={{ pointerEvents: "auto", fontWeight: "500"}}>
                              {renderWatchlistIcon(movie)}
                              <br></br>Watchlist
                        </button>
                        <button
                          className={`movie-bottom-text`}
                          style={{ pointerEvents: "auto", fontWeight: "500", alignItems: "center"}}>
                              
                              {getScoreDetails(movie)}
                        </button>
                        <button
                        onClick={(e) => toggleSeenStatus(movie, e)}
                        className={`noImgPlsRight`}
                        style={{ pointerEvents: "auto", fontWeight: "500"}}>
                            {renderSeenIcon(movie)}
                            <br></br>Seen
                        </button>
                      </div>
                    </div>
                  </div> */}
                </a>
                {/* <div className='movie-wrapper-div'>
                  <div className="group-wrapper" key={movie._id} onClick={() => onMovieSelect(movie)}>
                      <div 
                          className="group-2"
                          // onClick={() => openMoviePopup(movie)}
                      >
                        <div className="div-wrapper">
                          <div
                              className="overlap-group-2"
                              style={scoreDisplayStyle(movie.Const, userScores[movie.Const], movie["CuratorScore"])}
                              onClick={() => handleAddAndRateMovie(movie.Const, movie._id)}
                          >
                            <div 
                              className="text-wrapper-2"
                              style={{ color: userScores.hasOwnProperty(movie.Const) ? 'black' : '--systemPrimary-onDark: rgba(255, 255, 255, 0.92)' }} // Conditionally text color
                            >
                              {userScores[movie.Const] || movie["CuratorScore"]}
                            </div>
                          </div>
                        </div>
                        <div className="group-3">
                          <div className="text-wrapper-3">{movie.Title}</div>
                          <div className="text-wrapper-4">#{movie.CuratorRank} ‧ {movie.User} {formatRarity(movie.Rarity)} ‧ {movie.Critic} ‧ {movie.Certified}</div>
                          <div className="text-wrapper-41">{movie.Year} ‧ {movie.Streaming} ‧ {movie.Genre}</div>
                        </div>
                        
                        <div className="movie-poster">
                          <img 
                            // src={
                            //   `${movie.Poster}@._V1_QL55_UX190_.jpg`
                          
                            // } 
                            src={ 
                              window.innerWidth > 10000 && movie.backdrop_path
                                ? `${movie.backdrop_path.replace('/', 'https://image.tmdb.org/t/p/w1280/')}`
                                : movie.poster_path
                                  ? `${movie.poster_path.replace('/', 'https://image.tmdb.org/t/p/w1280/')}`
                                  : `${movie.Poster.replace('_V1_.jpg', '_V1_FMjpg_UX900_.jpg')}`
                            }
                            decoding="async" 
                            loading="lazy" 
                            className="movie-poster-img" 
                            alt={movie.Title} 
                            role="presentation" 
                            // sizes="(max-width: 480px) 100vw, (max-width: 720px) 50vw, 25vw"
                            // srcSet={`
                            //     ${movie.Poster}@._V1_QL75_UX190_CR0,0,190,281_.jpg 190w,
                            //     ${movie.Poster}@._V1_QL75_UX285_CR0,0,285,422_.jpg 285w,
                            //     ${movie.Poster}@._V1_QL75_UX380_CR0,0,380,562_.jpg 380w
                            // `}
                          />
                        </div>
                      </div>
                  </div>
                  </div> */}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  ) : null;
};

export default MovieShelf;
