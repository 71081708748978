
export function getConfig() {
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE && process.env.REACT_APP_AUTH0_AUDIENCE !== "YOUR_API_IDENTIFIER" ? process.env.REACT_APP_AUTH0_AUDIENCE : null;
  
  return {
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
    redirectUri: window.location.origin,
    scope: "openid profile email offline_access", // Requesting offline_access scope
    useRefreshTokens: true,
    cacheLocation: "localstorage",
  };
}
