import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import "./style.css";
import "./style2.css";
import { useNavigate, useParams, useLocation } from "react-router-dom";

const MoviePair = () => {
  const apiBaseUrl =
    process.env.REACT_APP_NODE_ENV === "development"
      ? "http://localhost:3001"
      : "";
  const { user, isAuthenticated, getAccessTokenSilently, loginWithRedirect } =
    useAuth0();
  const [allMovies, setAllMovies] = useState([]);
  const [seenMovies, setSeenMovies] = useState([]);
  const [currentPair, setCurrentPair] = useState([]);
  const [isMoviesLoading, setIsMoviesLoading] = useState(true);
  const navigate = useNavigate();
  const [selectedMovie, setSelectedMovie] = useState(null);
  const [matchupHistory, setMatchupHistory] = useState([]);

  const calculateNewScores = (selectedMovie, opponentMovie) => {
    const selectedMovieScore = getMovieScore(selectedMovie);
    const opponentMovieScore = getMovieScore(opponentMovie);

    let newSelectedMovieScore = selectedMovieScore;
    let newOpponentMovieScore = opponentMovieScore;

    if (selectedMovieScore > opponentMovieScore) {
      // No change in scores if the selected movie already has a higher score
      newSelectedMovieScore = selectedMovieScore;
      newOpponentMovieScore = opponentMovieScore;
    } else {
      // Swap the scores if the selected movie has a lower score than the opponent
      newSelectedMovieScore = opponentMovieScore;
      newOpponentMovieScore = selectedMovieScore;
    }

    return {
      newSelectedMovieScore: Math.round(newSelectedMovieScore),
      newOpponentMovieScore: Math.round(newOpponentMovieScore),
    };
  };

  const updateMovieRatings = async (
    selectedMovie,
    opponentMovie,
    newSelectedMovieScore,
    newOpponentMovieScore
  ) => {
    try {
      const token = await getAccessTokenSilently();

      // Update the selected movie's rating
      await axios.post(
        `${apiBaseUrl}/api/user/rateMovie`,
        {
          userId: user.sub,
          Const: selectedMovie.Const,
          score: newSelectedMovieScore,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Update the opponent movie's rating
      await axios.post(
        `${apiBaseUrl}/api/user/rateMovie`,
        {
          userId: user.sub,
          Const: opponentMovie.Const,
          score: newOpponentMovieScore,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // console.log("Movie ratings updated successfully");
    } catch (error) {
      console.error("Error updating movie ratings:", error);
    }
  };

  const pickRandomMovies = () => {
    // console.log("Picking random movies...");
    const seenMoviesSet = new Set(seenMovies.map((movie) => movie._id));
    const eligibleMovies = allMovies.filter((movie) =>
      seenMoviesSet.has(movie._id)
    );

    if (eligibleMovies.length > 1) {
      const shuffled = [...eligibleMovies].sort(() => Math.random() - 0.5);

      const findPair = (scoreDifference) => {
        for (let i = 0; i < shuffled.length - 1; i++) {
          for (let j = i + 1; j < shuffled.length; j++) {
            const movie1Score = getMovieScore(shuffled[i]);
            const movie2Score = getMovieScore(shuffled[j]);
            if (
              movie1Score !== movie2Score &&
              Math.abs(movie1Score - movie2Score) <= scoreDifference
            ) {
              return [shuffled[i], shuffled[j]];
            }
          }
        }
        return null;
      };

      // Selecting the maximum score difference based on a simulated normal distribution
      const maxScoreDiff = Math.floor(
        Math.abs(Math.random() * Math.random() * Math.random() * 20)
      ); // 20 can be adjusted based on desired spread

      let newPair = findPair(maxScoreDiff);

      // Fallback: If no suitable pair is found, try again with a larger score difference
      if (!newPair) {
        newPair = findPair(Infinity); // Infinity ensures any score difference is accepted
      }

      if (newPair) {
        setCurrentPair(newPair);
        // console.log("New pair:", newPair);
      } else {
        // console.log("No suitable pair found.");
      }
    } else {
      // console.log("Not enough movies to form a pair.");
    }
  };

  // Function to fetch seen movies and update the state
  const fetchSeenMovies = async () => {
    if (!isAuthenticated || !user?.sub) {
      return;
    }
    try {
      // pickRandomMovies();
      const accessToken = await getAccessTokenSilently();
      const response = await axios.get(`${apiBaseUrl}/api/user/seenMovies`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "user-id": user.sub,
        },
      });
      const sortedMovies = response.data.sort(
        (a, b) =>
          (b.userScore || b.CuratorScore) - (a.userScore || a.CuratorScore)
      );
      setSeenMovies(sortedMovies);
      setIsMoviesLoading(false);
      // pickRandomMovies(sortedMovies);
    } catch (error) {
      console.error("Error fetching user specific data:", error);
      setIsMoviesLoading(false);
      loginWithRedirect();
    }
  };

  useEffect(() => {
    fetchSeenMovies();
    pickRandomMovies();
  }, [user, isAuthenticated, getAccessTokenSilently, loginWithRedirect]);

  useEffect(() => {
    // pickRandomMovies();
    // fetchSeenMovies();
  }, [allMovies, seenMovies]);

  useEffect(() => {
    pickRandomMovies();
    fetchSeenMovies();
  }, [allMovies]);

  // useEffect(() => {
  //   const fetchUserSpecificData = async () => {
  //     if (!isAuthenticated || !user?.sub) {
  //       return;
  //     }
  //     try {
  //       const accessToken = await getAccessTokenSilently();
  //       const response = await axios.get(`${apiBaseUrl}/api/user/seenMovies`, {
  //         headers: {
  //           Authorization: `Bearer ${accessToken}`,
  //           "user-id": user.sub,
  //         },
  //       });
  //       const userSeenMovies = response.data;
  //       setSeenMovies(userSeenMovies);
  //       setIsMoviesLoading(false);
  //     } catch (error) {
  //       console.error("Error fetching user specific data:", error);
  //       setIsMoviesLoading(false);
  //       loginWithRedirect();
  //     }
  //   };

  //   fetchUserSpecificData();
  // }, [user, isAuthenticated, getAccessTokenSilently, loginWithRedirect]);

  const fetchContent = async () => {
    try {
      const response = await axios.get(`${apiBaseUrl}/api/content?type=movies`);
      const fetchedContent = response.data;
      fetchedContent.sort((a, b) => b.CuratorScore - a.CuratorScore);
      setAllMovies(fetchedContent);
    } catch (error) {
      console.error("Error fetching content data:", error);
    }
  };

  useEffect(() => {
    fetchContent();
  }, []);

  const createSlug = (title) => {
    return title
      .toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]/g, "");
  };

  const getMovieScore = (movie) => {
    const seenMovie = seenMovies.find((m) => m._id === movie._id);
    return seenMovie
      ? seenMovie.userScore || movie.CuratorScore
      : movie.CuratorScore;
  };

  const handleMovieSelect = (selectedMovie) => {
    const slug = createSlug(selectedMovie.Title);
    // navigate(`/explore/${selectedMovie.TMDBID}-${slug}`);
    // console.log("movie clicked: ", selectedMovie);

    const opponentMovie = currentPair.find(
      (movie) => movie._id !== selectedMovie._id
    );
    const { newSelectedMovieScore, newOpponentMovieScore } = calculateNewScores(
      selectedMovie,
      opponentMovie
    );

    const matchupDetails = {
      selectedMovie: {
        title: selectedMovie.Title,
        oldScore: getMovieScore(selectedMovie),
        newScore: newSelectedMovieScore,
        scoreDiff: newSelectedMovieScore - getMovieScore(selectedMovie),
      },
      opponentMovie: {
        title: opponentMovie.Title,
        oldScore: getMovieScore(opponentMovie),
        newScore: newOpponentMovieScore,
        scoreDiff: newOpponentMovieScore - getMovieScore(opponentMovie),
      },
    };

    setMatchupHistory((prevHistory) => [matchupDetails, ...prevHistory]);

    updateMovieRatings(
      selectedMovie,
      opponentMovie,
      newSelectedMovieScore,
      newOpponentMovieScore
    );

    setSelectedMovie(selectedMovie);
    fetchSeenMovies();
    pickRandomMovies();
  };

  return (
    <div className="overlap-wrapper">
      <div className="iphone-pro-max">
        <div></div>
        <div className="overlap">
          <div className="top3-container-display">
            <div className="div landing-page-with-nav-header">
              <div className="movie-wrapper-div">
                <h2>Fine-tune Your Movie Ratings</h2>
                {isMoviesLoading ? (
                  <p>Loading movies...</p>
                ) : (
                  currentPair.map((movie, index) => (
                    <div
                      className="group-wrapper"
                      key={movie._id}
                      onClick={() => handleMovieSelect(movie)}
                    >
                      <div className="group-2">
                        <div className="group-3">
                          <div className="text-wrapper-3">
                            <span>{movie.Title}</span>
                          </div>
                          <div className="text-wrapper-4">
                            <strong>
                              {seenMovies.some(
                                (seenMovie) =>
                                  seenMovie._id === movie._id &&
                                  seenMovie.userScore
                              )
                                ? `My Score: ${getMovieScore(movie)}`
                                : `Curator Score: ${movie.CuratorScore}`}
                            </strong>
                          </div>
                        </div>
                        <div className="movie-poster">
                          <img
                            src={
                              movie.backdrop_path
                                ? `https://image.tmdb.org/t/p/w1280/${movie.backdrop_path}`
                                : movie.poster_path
                                ? `https://image.tmdb.org/t/p/w1280/${movie.poster_path}`
                                : movie.Poster
                                ? `${movie.Poster.replace(
                                    "_V1_.jpg",
                                    "_V1_FMjpg_UX900_.jpg"
                                  )}`
                                : "https://via.placeholder.com/300x450?text=No+Image" // Default placeholder image URL
                            }
                            decoding="async"
                            loading="lazy"
                            className="movie-poster-img"
                            alt={movie.Title}
                            role="presentation"
                            style={{ width: "60px", height: "60px" }}
                          />
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
              <div className="matchup-history">
                <h3>Matchup History</h3>
                {matchupHistory.map((matchup, index) => (
                  <div key={index} className="matchup-details">
                    <p>
                      <strong>Matchup #{matchupHistory.length - index}:</strong>
                    </p>
                    <p>
                      {matchup.selectedMovie.title} • <strong>WINNER</strong> •{" "}
                      {matchup.selectedMovie.newScore} NEW •{" "}
                      <span
                        style={{
                          color:
                            matchup.selectedMovie.scoreDiff > 0
                              ? "rgb(20,255,0)"
                              : matchup.selectedMovie.scoreDiff < 0
                              ? "rgb(255,20,0)"
                              : "inherit",
                        }}
                      >
                        ({matchup.selectedMovie.scoreDiff >= 0 ? "+" : ""}
                        {matchup.selectedMovie.scoreDiff})
                      </span>{" "}
                      • ({matchup.selectedMovie.oldScore} OLD)
                    </p>
                    <p>
                      {matchup.opponentMovie.title} • <strong>LOSER</strong> •{" "}
                      {matchup.opponentMovie.newScore} NEW •{" "}
                      <span
                        style={{
                          color:
                            matchup.opponentMovie.scoreDiff > 0
                              ? "rgb(20,255,0)"
                              : matchup.opponentMovie.scoreDiff < 0
                              ? "rgb(255,20,0)"
                              : "inherit",
                        }}
                      >
                        ({matchup.opponentMovie.scoreDiff >= 0 ? "+" : ""}
                        {matchup.opponentMovie.scoreDiff})
                      </span>{" "}
                      • ({matchup.opponentMovie.oldScore} OLD)
                    </p>
                    {index !== matchupHistory.length - 1 && <hr />}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MoviePair;
