import React, { Fragment } from "react";
import MovieList2 from "../components/MovieList2";

const Home = () => (
  <Fragment>
    <MovieList2 />
  </Fragment>
);

export default Home;
