import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import MovieDetailPopup from "./MovieDetailPopup";

const ListDetail = () => {
  let apiBaseUrl = "";
  if (process.env.REACT_APP_NODE_ENV === "development") {
    apiBaseUrl = "http://localhost:3001";
  } else {
    apiBaseUrl = "";
  }
  const { decade } = useParams();
  const navigate = useNavigate();
  const [movies, setMovies] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedMovie, setSelectedMovie] = useState(null);

  // const handleMovieSelect = (movie) => {
  //   navigate(`/explore/${movie._id}`, { state: { movie: movie } });
  // };

  const createSlug = (title) => {
    return title
      .toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]/g, "");
  };

  const handleMovieSelect = (movie) => {
    const slug = createSlug(movie.Title);
    navigate(`/explore/${movie.TMDBID}-${slug}`);
    setSelectedMovie(movie);
  };

  const closePopup = () => {
    navigate(`/lists/${decade}`);
    setSelectedMovie(null);
  };

  useEffect(() => {
    const fetchMovies = async () => {
      try {
        const response = await axios.get(
          `${apiBaseUrl}/api/movies/decade?decade=${decade}`
        );
        setMovies(response.data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching movies:", error);
        setIsLoading(false);
      }
    };

    fetchMovies();
  }, [decade]);

  return (
    <div className="overlap-wrapper">
      <div className="iphone-pro-max">
        <div></div>
        <div className="overlap">
          <div className="top3-container-display">
            {selectedMovie && (
              <MovieDetailPopup
                movie={selectedMovie}
                closePopup={closePopup}
                // onSimilarMovieSelect={handleSimilarMovieSelect}
                // seenMovies={seenMovies}
                // userScores={userScores}
                // movies={movies}
                // updateUserScore={handleAddAndRateMovie}
                // watchlistMovies={watchlistMovies}
                // userId={user?.sub}
                // seenMoviesProp={seenMovies}
                // watchlistProp={watchlistMovies}
              />
            )}

            <div className="div landing-page-with-nav-header">
              <div className="movie-wrapper-div">
                <h2>Top Movies of the {decade}s</h2>
                {isLoading ? (
                  <p>Loading movies...</p>
                ) : (
                  movies.map((movie, index) => (
                    <div
                      className="group-wrapper"
                      key={movie._id}
                      onClick={() => handleMovieSelect(movie)}
                    >
                      <div className="group-2">
                        <div className="group-3">
                          <div className="text-wrapper-3">
                            <span>{movie.Title}</span>
                          </div>
                          <div className="text-wrapper-4">
                            <strong>{movie.CuratorScore}</strong>{" "}
                            {movie.Streaming}
                          </div>
                        </div>
                        <div className="movie-poster">
                          <img
                            src={
                              window.innerWidth > 10000 && movie.backdrop_path
                                ? `${movie.backdrop_path.replace(
                                    "/",
                                    "https://image.tmdb.org/t/p/w1280/"
                                  )}`
                                : movie.poster_path
                                ? `${movie.backdrop_path.replace(
                                    "/",
                                    "https://image.tmdb.org/t/p/w1280/"
                                  )}`
                                : `${movie.Poster.replace(
                                    "_V1_.jpg",
                                    "_V1_FMjpg_UX900_.jpg"
                                  )}`
                            }
                            decoding="async"
                            loading="lazy"
                            className="movie-poster-img"
                            alt={movie.Title}
                            role="presentation"
                            style={{ width: "60px", height: "60px" }}
                          />
                        </div>

                        <div className="movie-rank-cont">
                          <div className="movie-rank-pos">
                            <span>{index + 1}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListDetail;
