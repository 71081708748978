// streamingServiceIcons.js
const streamingServiceIconsMy = [
  {"name": "Bury", "logo_url": ""},
  // {"name": "Buy", "logo_url": "/9ghgSC0MA082EL6HLCW3GalykFD.jpg"},

  {"name": "Max", "logo_url": "/fksCUZ9QDWZMUwL2LgMtLckROUN.jpg"},
  {"name": "Disney+", "logo_url": "/7YPdUs60C9qQQQfOFCgxpnF07D9.jpg"},
  {"name": "Paramount", "logo_url": "/h5DcR0J2EESLitnhR8xLG1QymTE.jpg"},
  {"name": "Netflix", "logo_url": "/pbpMk2JmcoNnQwx5JGpXngfoWtp.jpg"},
  {"name": "Prime", "logo_url": "/dQeAar5H991VYporEjUspolDarG.jpg"},
  {"name": "Hulu", "logo_url": "/bxBlRPEPpMVDc4jMhSrTf2339DW.jpg"},
  {"name": "Peacock", "logo_url": "/2aGrp1xw3qhwCYvNGAJZPdjfeeX.jpg"},
  {"name": "Freevee", "logo_url": "/4VOCKZGiAtXMtoDyOrvHAN33uc2.jpg"},
  {"name": "Pluto", "logo_url": "/dB8G41Q6tSL5NBisrIeqByfepBc.jpg"},
  {"name": "AMC", "logo_url": "/92Kx25Od0habmgRBTqT6XWgwgKt.jpg"},
  {"name": "STARZ", "logo_url": "/yIKwylTLP1u8gl84Is7FItpYLGL.jpg"},
  {"name": "Cinemax", "logo_url": "/ohcwolMl8E743CkS8MnhmJKOlRj.jpg"},
  {"name": "MGM", "logo_url": "/ctiRpS16dlaTXQBSsiFncMrgWmh.jpg"},
  {"name": "FXNOW", "logo_url": "/m4KUe3UoTnLgN4g6txYMnBqeUI5.jpg"},
  {"name": "Apple", "logo_url": "/2E03IAZsX4ZaUqM7tXlctEPMGWS.jpg"},
  {"name": "TBS", "logo_url": "/cZvP3XsDKlHFhNIyHYCVPStXT5l.jpg"},
  {"name": "The", "logo_url": "/wQzSN83BnWVgO7xEh0SeTVqtrFv.jpg"},
  {"name": "Tubi", "logo_url": "/o4JMLTkDfjei1XrsVk1vSjXfdBk.jpg"},
  {"name": "MUBI", "logo_url": "/fj9Y8iIMFUC6952HwxbGixTQPb7.jpg"},
  {"name": "Plex", "logo_url": "/vLZKlXUNDcZR7ilvfY9Wr9k80FZ.jpg"},
  {"name": "Crackle", "logo_url": "/iCHCSuraj6zUKmMYgQhsm8jmoqi.jpg"},
  {"name": "Crunchyroll", "logo_url": "/mXeC4TrcgdU6ltE9bCBCEORwSQR.jpg"},
  {"name": "Freeform", "logo_url": "/4cHGd32hhEHmFjDGJcjVEAwFQg0.jpg"},
  {"name": "Shout", "logo_url": "/y9jFndski0fRO4MHh3yg5PIL9ZI.jpg"},
  {"name": "Sundance", "logo_url": "/1Edma9SrJnqkQW3BqFd2rJNHZvX.jpg"},
  {"name": "Showtime", "logo_url": "/kOI9M2PeWKFbfQ7m9VvTU0OdIe1.jpg"},

  {"name": "fuboTV", "logo_url": "/rugttVJKzDAwVbM99gAV6i3g59Q.jpg"},
  {"name": "HBO Max", "logo_url": "/b8edpTaLCHFrUnhpGQIZJUpFX7T.jpg"},
  {"name": "HBO Max Free", "logo_url": "/oScZixdKdYI4XvSRVEX7Gspg6Dd.jpg"},
  {"name": "Max Amazon Channel", "logo_url": "/erfmV26akboHUerxGjpl8pBwww3.jpg"},
  {"name": "MGM Plus Amazon Channel", "logo_url": "/efu1Cqc63XrPBoreYnf2mn0Nizj.jpg"},
  {"name": "Crunchyroll Amazon Channel", "logo_url": "/j7wdfXtHKUpV9CNwnXUa68HImFx.jpg"},
  {"name": "Peacock Premium", "logo_url": "/drPlq5beqXtBaP7MNs8W616YRhm.jpg"},
  {"name": "Kocowa", "logo_url": "/hwsU65QW7A4dbMEWkDpgHyCNcfS.jpg"},
  {"name": "Amazon Video", "logo_url": "/seGSXajazLMCKGB5hnRCidtjay1.jpg"},
  {"name": "Google Play Movies", "logo_url": "/8z7rC8uIDaTM91X0ZfkRf04ydj2.jpg"},
  {"name": "YouTube", "logo_url": "/pTnn5JwWr4p3pG8H6VrpiQo7Vs0.jpg"},
  {"name": "Paramount+ with Showtime", "logo_url": "/kkUHFtdjasnnOknZN69TbZ2fCTh.jpg"},
  {"name": "Paramount Plus Apple TV Channel ", "logo_url": "/tJqmTmQ8jp9WfyaZfApHK8lSywA.jpg"},
  {"name": "Starz Apple TV Channel", "logo_url": "/1C5EVCWyQD798CE1DFfcm6oAbxP.jpg"},
  {"name": "AMC Plus Apple TV Channel ", "logo_url": "/oTQdXIqM9iewlN4MC2nhKB0gHw.jpg"},
  {"name": "Britbox Apple TV Channel ", "logo_url": "/xp12VXTZfunk568CAPs2Xqknimq.jpg"},
  {"name": "Paramount+ Amazon Channel", "logo_url": "/hExO4PtimLIYn3kBOrzsejNv7cT.jpg"},
  {"name": "Starz Play Amazon Channel", "logo_url": "/esiLBRzDUwodjfN8gA4qj7l3ZF7.jpg"},
  {"name": "Discovery+ Amazon Channel", "logo_url": "/lgudHqEtTOzkMWlpTjU1oUyoUSZ.jpg"},
  {"name": "AMC+ Amazon Channel", "logo_url": "/2ino0WmHA4GROB7NYKzT6PGqLcb.jpg"},
  {"name": "Funimation Now", "logo_url": "/cm4NDOpuuslZq68VHHCPlGoAD2D.jpg"},
  {"name": "Showtime Roku Premium Channel", "logo_url": "/12hpEMyph4xWZP7SKM6aAqmcBnZ.jpg"},
  {"name": "Paramount+ Roku Premium Channel", "logo_url": "/ywIoxSjoYJGUIbR6BfxUiCHdPi3.jpg"},
  {"name": "Starz Roku Premium Channel", "logo_url": "/9laPF1MAiUxlqM8T98F3Gj0bhzd.jpg"},
  {"name": "AMC+ Roku Premium Channel", "logo_url": "/gAGrSQCTAisxy2CsWbijVvJEnRo.jpg"},
  {"name": "Showtime Amazon Channel", "logo_url": "/palbLJjkRhNAccU32ycThJNcbI5.jpg"},
  {"name": "AMC+", "logo_url": "/ovmu6uot1XVvsemM2dDySXLiX57.jpg"},
  {"name": "MGM Plus Roku Premium Channel", "logo_url": "/lD7HKUmXDvUya58DceiTA809Zbf.jpg"},
  {"name": "YouTube Premium", "logo_url": "/rMb93u1tBeErSYLv79zSTR07UdO.jpg"},
  {"name": "YouTube Free", "logo_url": "/4tJBhJcuF1ZstI5Yqu82G0yroLa.jpg"},
  {"name": "The CW", "logo_url": "/spcwROYevucLluqZZ8Fv75UuTBt.jpg"},
  {"name": "Hoopla", "logo_url": "/j7D006Uy3UWwZ6G0xH6BMgIWTzH.jpg"},
  {"name": "Vudu", "logo_url": "/nVzxU8EPk0aXqQkBZniVA2kat1I.jpg"},
  {"name": "VUDU Free", "logo_url": "/6By0jm0Gr2WMOqUeHWhzRWaMdOo.jpg"},
  {"name": "Criterion Channel", "logo_url": "/yhrtzYd43pFIhRq0ruO8umJPuyn.jpg"},
  {"name": "PBS", "logo_url": "/iLjStQKQwzyxXJb3jyNpvDmW9mx.jpg"},
  {"name": "Pantaflix", "logo_url": "/zR3ae8RpBIlmqAWv4lePcXGbrU8.jpg"},
  {"name": "Comedy Central", "logo_url": "/c2ZJdxr5Ex2MU1nGqyCacjWCqxx.jpg"},
  {"name": "Kanopy", "logo_url": "/rcBwnERpNfPfWB5DaSTyEMCZbCA.jpg"},
  {"name": "Microsoft Store", "logo_url": "/5vfrJQgNe9UnHVgVNAwZTy0Jo9o.jpg"},
  {"name": "Redbox", "logo_url": "/xHwgUa7XzU43Ia6tnrt0zjW1wf9.jpg"},
  {"name": "Sun Nxt", "logo_url": "/6KEQzITx2RrCAQt5Nw9WrL1OI8z.jpg"},
  {"name": "DIRECTV", "logo_url": "/vg3nIjHVhLXnMKjQkNhQaSlsPub.jpg"},
  {"name": "Fandor", "logo_url": "/45lSM3J7Ts4TXTtDv0EuTPL0eH5.jpg"},
  {"name": "Curiosity Stream", "logo_url": "/oR1aNm1Qu9jQBkW4VrGPWhqbC3P.jpg"},
  {"name": "History", "logo_url": "/xqZSzhIcq8qaAU13rMhVhzDi4T8.jpg"},
  {"name": "Lifetime", "logo_url": "/tWq6XvHqPjBW1BZTpf4Nziy8CDD.jpg"},
  {"name": "Shudder", "logo_url": "/vEtdiYRPRbDCp1Tcn3BEPF1Ni76.jpg"},
  {"name": "Acorn TV", "logo_url": "/doCc555FPPgGtuaZJxf9QZVpIp5.jpg"},
  {"name": "Screambox", "logo_url": "/x2w49qSgz7bU3o3rENO9Yc11OmC.jpg"},
  {"name": "Popcornflix", "logo_url": "/41gE6Mv0BCfZGNTwPkYIXLVsDMD.jpg"},
  {"name": "GuideDoc", "logo_url": "/eKVmLFHW5PeNhuR7Nedd8OIxW2M.jpg"},
  {"name": "BritBox", "logo_url": "/1pDWY6EKtfclVbZTPVWc7WPwy8Q.jpg"},
  {"name": "Netflix Kids", "logo_url": "/kwVegvKCinXTPuzZmYT1J3i1HJz.jpg"},
  {"name": "Boomerang", "logo_url": "/mH5wvu4raXvVdnrOXg367xieZXa.jpg"},
  {"name": "Urban Movie Channel", "logo_url": "/dgO7LfzsczUIW2h7xS66yla22ne.jpg"},
  {"name": "History Vault", "logo_url": "/z5jtxEEeJEK1kYDqbyXzfquolC9.jpg"},
  {"name": "Yupp TV", "logo_url": "/6msAJGK2yF39S2PGrinWiVCNmJn.jpg"},
  {"name": "Eros Now", "logo_url": "/ouJUBLjxevh4UaEGV4ZUjsSWdRq.jpg"},
  {"name": "WWE Network", "logo_url": "/7Wo1H7YMmRy7S56sz6HJAMYRdGq.jpg"},
  {"name": "Noggin Amazon Channel", "logo_url": "/uXFEctwGkorVz163dVSZeZkBEQU.jpg"},
  {"name": "Smithsonian Channel", "logo_url": "/zUiU5rjc2HGUW1XpXCpZEL8M0MH.jpg"},
  {"name": "Laugh Out Loud", "logo_url": "/pNNWgwfYenkTfddAO4fqHEIrnXv.jpg"},
  {"name": "Pure Flix", "logo_url": "/7L4eXQD0yFVDKZ2qwYtxcV5gm6n.jpg"},
  {"name": "Boomerang Amazon Channel", "logo_url": "/3CPBjF6tQQONCEvqC6KIIkiUG81.jpg"},
  {"name": "Lifetime Movie Club", "logo_url": "/m4ofXxkTOj04N8df5KJGCIjnWii.jpg"},
  {"name": "PBS Kids Amazon Channel", "logo_url": "/lIXDKV7LrZfF3SR2m8EQrMVRI5C.jpg"},
  {"name": "Hallmark Movies Now Amazon Channel", "logo_url": "/614YTzAiVrSIm7bIVcwg4lIMQr7.jpg"},
  {"name": "PBS Masterpiece Amazon Channel", "logo_url": "/xN1vKpcypShJrWmf1t3dyGJM7sO.jpg"},
  {"name": "Viewster Amazon Channel", "logo_url": "/769jLV69ZSa0x4sy9BZZQbizvuk.jpg"},
  {"name": "MZ Choice Amazon Channel", "logo_url": "/hTAIgvUnmu1Yv0wFVJRu9KKAPtJ.jpg"},
  {"name": "Spamflix", "logo_url": "/k2YgZyxij5RcnS1qqUYEUrJB4oQ.jpg"},
  {"name": "HiDive", "logo_url": "/iCV9oPBeoLDC5okFRZEgQkx7je0.jpg"},
  {"name": "VIX ", "logo_url": "/jwRPknT20dfU1GeVqbcDXFyvtdG.jpg"},
  {"name": "Topic", "logo_url": "/peZq5Zo0ZO0RyZsSQGLrWEaVNhr.jpg"},
  {"name": "Night Flight Plus", "logo_url": "/zOWoxUMmGW0OfDFAQsCosho2PZz.jpg"},
  {"name": "Retrocrush", "logo_url": "/5zUnCq0pvixEtQafzOBBWqxNRvX.jpg"},
  {"name": "Classix", "logo_url": "/uFjAjvrKMII0H766QzyHDNkZdKX.jpg"},
  {"name": "Dekkoo", "logo_url": "/x6nRFzF32hCzMHaVM4RHRo7lsgS.jpg"},
  {"name": "OVID", "logo_url": "/jxavsObOHN6UqlbuZQ1UpVRVv6X.jpg"},
  {"name": "Chai Flicks", "logo_url": "/qesugDq8sUCixmOOsqDM1xAnBYz.jpg"},
  {"name": "Shudder Amazon Channel", "logo_url": "/qb6Lj5BhNJavdmRVDzAqAjd4Tj3.jpg"},
  {"name": "MUBI Amazon Channel", "logo_url": "/a4IDLKjvP5gvq7tNlg2Xw5YyEkI.jpg"},
  {"name": "AcornTV Amazon Channel", "logo_url": "/1wYmvbAuVZz2JnKvYfYN8Qolnb.jpg"},
  {"name": "BritBox Amazon Channel", "logo_url": "/tLBLABfFYYETf9Zk8gKEWnjhMai.jpg"},
  {"name": "Fandor Amazon Channel", "logo_url": "/3eVIcUeRXji5SBV7gEv4f5U4CqV.jpg"},
  {"name": "Screambox Amazon Channel", "logo_url": "/vmXC3D9Kqtt9TEohDEmcaxnLYA5.jpg"},
  {"name": "Sundance Now Amazon Channel", "logo_url": "/i8PzzzOVJfXWLE1v5Up7nGTWWhp.jpg"},
  {"name": "USA Network", "logo_url": "/A95qgiMz6ulV2SxeKcJ5Gc9pqGS.jpg"},
  {"name": "FlixFling", "logo_url": "/yFGu4sSzwUMfhwmSsZgez8QhaVl.jpg"},
  {"name": "Bet+ Amazon Channel", "logo_url": "/2AvUqoIHAq2lLxRy2IxOBUgCYef.jpg"},
  {"name": "Rakuten Viki", "logo_url": "/5L2bwr9DhUg28oSMEPRCNwB2y7B.jpg"},
  {"name": "AMC on Demand", "logo_url": "/1g3ULbVMEW8OVKOJymLvfboCrMv.jpg"},
  {"name": "TCM", "logo_url": "/8SIxng8eznACvCTRsN1FZfd5Ycg.jpg"},
  {"name": "TNT", "logo_url": "/76CvnJAKAPbJuiXYwQGN7PFqOci.jpg"},
  {"name": "Darkmatter TV", "logo_url": "/pmHszqqm55p228vRgFeujRiPdYU.jpg"},
  {"name": "BBC America", "logo_url": "/jfXLhMzHHmBYrtE9ZaW7as2RA98.jpg"},
  {"name": "IndieFlix", "logo_url": "/mjsNfr0lswbRx9hbEFkgqJkBjFt.jpg"},
  {"name": "Here TV", "logo_url": "/kiNWL1wGBg3z2FBXJcd8R8MaZrV.jpg"},
  {"name": "Flix Premiere", "logo_url": "/wCKOLlsilq4I0Iv1NSJTToPEQAo.jpg"},
  {"name": "AsianCrush", "logo_url": "/jCgWMxqGaq0h0VOxyJPfQdTqmii.jpg"},
  {"name": "FILMRISE", "logo_url": "/iS5NCgmSqTnkNoTQY8nBvSZsEnF.jpg"},
  {"name": "Revry", "logo_url": "/llQ8zV50Wqh4gYCYfr52R1b9gfD.jpg"},
  {"name": "DOCSVILLE", "logo_url": "/5zqbck5mo8PuVbGu2ngBUdn5Yga.jpg"},
  {"name": "Spectrum On Demand", "logo_url": "/aAb9CUHjFe9Y3O57qnrJH0KOF1B.jpg"},
  {"name": "Hi-YAH", "logo_url": "/pPoqVHcko7XoCRrPus4boDW90uM.jpg"},
  {"name": "tru TV", "logo_url": "/mMJ6AQUpHDsD5s2Q2dnbpX1NFwp.jpg"},
  {"name": "DisneyNOW", "logo_url": "/d8HzRaIa5aGE5mJB1SsIA9hODdx.jpg"},
  {"name": "ARROW", "logo_url": "/wAuMUrRTVaJ2CZ4ZpyQbayZx0iU.jpg"},
  {"name": "Plex Player", "logo_url": "/ez70jk7n53zrBLI5eOhtUzrVRet.jpg"},
  {"name": "WOW Presents Plus", "logo_url": "/6dET59jNU0ADysghEjl8Unuc7Ca.jpg"},
  {"name": "Magellan TV", "logo_url": "/mSH24WQcRDJ2fsL5iucXqqRnSRb.jpg"},
  {"name": "BroadwayHD", "logo_url": "/6IYZ4NjwPikxN7J9cfSmuyeHeMm.jpg"},
  {"name": "Filmzie", "logo_url": "/eUBxtrqO26wAJfYOZJOzhQEo3mm.jpg"},
  {"name": "MovieSaints", "logo_url": "/9vMVMnVnWwxt0TkCazKLYW1zuqY.jpg"},
  {"name": "Dogwoof On Demand", "logo_url": "/7m26fd7Lqw8l779ZRMHHADAcslm.jpg"},
  {"name": "True Story", "logo_url": "/aRPDQvVcpeY07sjI6lAALMCL0ti.jpg"},
  {"name": "DocAlliance Films", "logo_url": "/vbXJBJVv3u3YWt6ml0l0ldDblXT.jpg"},
  {"name": "KoreaOnDemand", "logo_url": "/273ohemep46c1r93V1rHOG7hraZ.jpg"},
  {"name": "Hoichoi", "logo_url": "/u7dwMceEbjxd1N3TLEUBILSK2x6.jpg"},
  {"name": "iQIYI", "logo_url": "/fi3a86qRzJ4xwO2bFCCmfOhllFc.jpg"},
  {"name": "Metrograph", "logo_url": "/q4WTdA5wH3TXDFiYKejCCwSOyJ8.jpg"},
  {"name": "Public Domain Movies", "logo_url": "/aN0Y2BNZQBH91JkVOeLTs8IhQrH.jpg"},
  {"name": "Kino Now", "logo_url": "/Al4bo8C7muDl8TLsE8u25xPUDy2.jpg"},
  {"name": "Cultpix", "logo_url": "/uauVx3dGWt0GICqdMCBYJObd3Mo.jpg"},
  {"name": "Eventive", "logo_url": "/fwx5Ed64TkfWiRH1SOSkc4781Ts.jpg"},
  {"name": "aha", "logo_url": "/8WerMI8XcZXqPpkHTZNtzMzousF.jpg"},
  {"name": "FilmBox+", "logo_url": "/fbveJTcro9Xw2KuPIIoPPePHiwy.jpg"},
  {"name": "ShortsTV Amazon Channel", "logo_url": "/2nFBw1zMzN1AujFaCsmsgSKWdfS.jpg"},
  {"name": "Bet+", "logo_url": "/biHGb84xAzYrNkdvX5XWQTmgzC8.jpg"},
  {"name": "Takflix", "logo_url": "/ed0vz5bryWIhQB5sHiuGvHKnHHn.jpg"},
  {"name": "Klassiki", "logo_url": "/rFQs3pau1TkSUY489iJloxNs3A3.jpg"},
  {"name": "Starz Amazon Channel", "logo_url": "/esiLBRzDUwodjfN8gA4qj7l3ZF7.jpg"},
  {"name": "Netflix basic with Ads", "logo_url": "/kICQccvOh8AIBMHGkBXJ047xeHN.jpg"},
  {"name": "Cohen Media Amazon Channel", "logo_url": "/QAtDmj6XHlkPvw5xAjF08ZQARh.jpg"},
  {"name": "Popflick", "logo_url": "/f5yNbaK23OSWV5IocAj8FZwIOQj.jpg"},
  {"name": "Viaplay", "logo_url": "/bnoTnLzz2MAhK3Yc6P9KXe5drIz.jpg"},
  {"name": "Runtime", "logo_url": "/oJRCAz1WKkKM9Ge7mX5KFnegTNY.jpg"},
  {"name": "Reveel", "logo_url": "/en3dbHAWzDpvDhsI0G8LphUhpBr.jpg"},
  {"name": "Angel Studios", "logo_url": "/znTpq1dy5TYxm1QLoxORzuOuwYq.jpg"},
  {"name": "Cineverse", "logo_url": "/jpEV1w3CnrpDQ1vRvGQIZF1S6vA.jpg"},
  {"name": "AD tv", "logo_url": "/mK8nfCXfwoAa6cAkHUSKCkLEIKK.jpg"},
  {"name": "Midnight Pulp", "logo_url": "/8s4adSGz4UmVOP5uegNkoikSFLV.jpg"},
  {"name": "Xumo Play", "logo_url": "/xfKqqWYYIyvjECOFOaYtJdD7gl3.jpg"},
  {"name": "National Geographic", "logo_url": "/nlOrX8YVYjxFkEpfvJHayNg9UAB.jpg"},
  {"name": "Shahid VIP", "logo_url": "/7qZED0kLBtiV8mLRNBtW4PQCAqW.jpg"},
  {"name": "DistroTV", "logo_url": "/6gdKp9OwHgDLloethSPxZbtovkl.jpg"},
  {"name": "myfilmfriend", "logo_url": "/mWAOUnJY6hnQghUZpjuDB815efG.jpg"},
  {"name": "Outside Watch", "logo_url": "/jLD5zHgsAhSWBRTnMUOLaXrUuVI.jpg"},
  {"name": "Full Moon Amazon Channel", "logo_url": "/xIu1EW7y2EC7RD3Wqe0Ogi5NAuo.jpg"},
  {"name": "Univer Video", "logo_url": "/44E23wCM3WLsvAVRyk6fye76cZb.jpg"},
  {"name": "GlewedTV", "logo_url": "/pr64iEnZZ3qjsUqWS39R5JC97CX.jpg"},
  {"name": "Noggin Apple TV Channel", "logo_url": "/zu0bo6dHlgBsfd1nk0zz6TBoB3W.jpg"},
  {"name": "A&E Crime Central Apple TV Channel", "logo_url": "/a0vfnBfWPAF6OtKZCzlyiW9A9l6.jpg"},
  {"name": "Acorn TV Apple TV", "logo_url": "/kx8rGgFYxX6aJkG7RHx2mtijglC.jpg"},
  {"name": "ALLBLK Apple TV channel", "logo_url": "/voNwdFHk9uc2ZEWKZlwSQDsfRJN.jpg"},
  {"name": "UP Faith & Family Apple TV Channel", "logo_url": "/92iY4soQNVzNM02mHGjqovPdzdF.jpg"},
  {"name": "Topic Apple TV Channel", "logo_url": "/kjHUSd4AVuz9duG3xulGJLsYw4E.jpg"},
  {"name": "Sundance Now Apple TV Channel", "logo_url": "/1x0LxXHUibIolT5fxdSamGGMBC3.jpg"},
  {"name": "Shudder Apple TV Channel", "logo_url": "/kLfq0I2MwiUFUY9yI1GwOeKxX8f.jpg"},
  {"name": "Showtime Apple TV Channel", "logo_url": "/cm1tVzivRWvZjcRSe5dwNqtw26E.jpg"},
  {"name": "ScreenPix Apple TV Channel", "logo_url": "/96HJkA4nMkcOtiqH9oF1YYtGBHK.jpg"},
  {"name": "OUTtv Apple TV Channel", "logo_url": "/1SuB6tXdKaW4qDRDiqm9C1nr1mD.jpg"},
  {"name": "Lifetime Movie Club Apple TV Channel", "logo_url": "/cpSQ9P0ntzr1gZcxHIyaryihpgH.jpg"},
  {"name": "Hallmark Movies Now Apple TV Channel", "logo_url": "/ce9sTSF5WsvqEHv7kxOM1LlQha5.jpg"},
  {"name": "Eros Now Select Apple TV Channel", "logo_url": "/cqgeSQHqKEkfcmsiy7GoCnf1Q2I.jpg"},
  {"name": "CuriosityStream Apple TV Channel", "logo_url": "/z9x1xFFKjHTYlLYWtiPhAjN4jOp.jpg"},
  {"name": "Cinemax Apple TV Channel", "logo_url": "/zXCdY4pczONheFCU9GkrMbGI1o5.jpg"},
  {"name": "BET+  Apple TV channel", "logo_url": "/yFk3WffvGGUtIjJBSV0pgsciaEB.jpg"},
  {"name": "Carnegie Hall+ Apple TV Channel", "logo_url": "/dDHyeNrfnloy6skW0j6oIUXFa3Z.jpg"},
  {"name": "IFC Films Unlimited Apple TV Channel", "logo_url": "/tPvmybuDUn84aDps72GiMbvr2xU.jpg"},
  {"name": "HISTORY Vault Apple TV Channel", "logo_url": "/fsert5S2L3CWSFyaAODiWPOScmz.jpg"},
  {"name": "HISTORY Vault Amazon Channel", "logo_url": "/fr8lHD0EAYU01UIhg6u8kJrk7jQ.jpg"},
  {"name": "Carnegie Hall+ Amazon Channel", "logo_url": "/xT5I4zZ2XTLhOVA5ooQIsqjSC3R.jpg"},
  {"name": "Troma NOW", "logo_url": "/2CYF8CNs5WK3sB69K0AlmwUxrzv.jpg"},
  {"name": "Lifetime Movie Club Amazon Channel", "logo_url": "/bKWKqZYT3wrpq1Lyi2qSmx1qlqh.jpg"},
  {"name": "Film Movement Plus", "logo_url": "/8cjvUhc1ga7ySEdAFM7Ok9G6HOr.jpg"},
  {"name": "NetMovies", "logo_url": "/jlyafDbXLyNXNNFZbIgB9VrlScW.jpg"},
  {"name": "maxdome Store", "logo_url": "/9lFSDdj10l7QAXyTZPDWuwkKJT3.jpg"},
  {"name": "Stan", "logo_url": "/sSfxJXq7s8oHf3XWd0FtqagPDsF.jpg"},
  {"name": "Quickflix Store", "logo_url": "/xOHYHSKSh11rllvQGKqGgXyhxow.jpg"},
  {"name": "Netzkino", "logo_url": "/4bKgwiotEIN6wiZNQhQfjp7qPCk.jpg"},
  {"name": "Sky Go", "logo_url": "/1UrT2H9x6DuQ9ytNhsSCUFtTUwS.jpg"},
  {"name": "WOW", "logo_url": "/9r5zFWuYnwjzO1JrNjSbLQwUc3P.jpg"},
  {"name": "Alleskino", "logo_url": "/m0zZQjEHcp8np3lkMdMZE9QRwjA.jpg"},
  {"name": "Rakuten TV", "logo_url": "/bZvc9dXrXNly7cA0V4D9pR8yJwm.jpg"},
  {"name": "BBC iPlayer", "logo_url": "/nc8Tpsr8SqCbsTUogPDD06gGzB3.jpg"},
  {"name": "Now TV", "logo_url": "/g0E9h3JAeIwmdvxlT73jiEuxdNj.jpg"},
  {"name": "Chili", "logo_url": "/rVEXDsaHzpwK1YhXEZrsmvfpdOR.jpg"},
  {"name": "ITVX", "logo_url": "/1LuvKw01c2KQCt6DqgAgR06H2pT.jpg"},
  {"name": "Looke", "logo_url": "/9HhIlyFlilVtx0sMTcPbhs5qR31.jpg"},
  {"name": "Volta", "logo_url": "/jucp0SZobmXWbpz6p7Yh9W7hPrr.jpg"},
  {"name": "BoxOffice", "logo_url": "/4JswJbaxqZhut2TpJ3IPUBnNdy8.jpg"},
  {"name": "ShowMax", "logo_url": "/guv81FtHO7GmnFwwmJA7QmfkSnZ.jpg"},
  {"name": "OCS Go", "logo_url": "/z64xZ5CIT6k4VyI5ThKtxJARDOZ.jpg"},
  {"name": "Canal VOD", "logo_url": "/cAUTwxqoI7zc9XtYr1SMtWZEsXg.jpg"},
  {"name": "Bbox VOD", "logo_url": "/s7mg9n4UHrcdJQL78JmaU6oAdHt.jpg"},
  {"name": "Orange VOD", "logo_url": "/9Fyo6njYvroAIxAzTBbLgnjgYga.jpg"},
  {"name": "Atres Player", "logo_url": "/oN6g8QorcoYo3mx4BulU22ghKq4.jpg"},
  {"name": "Filmin", "logo_url": "/kO2SWXvDCHAquaUuTJBuZkTBAuU.jpg"},
  {"name": "Filmin Plus", "logo_url": "/ozZU2vSlyL11rFGEkq1HE0yxIJq.jpg"},
  {"name": "Filmin Latino", "logo_url": "/ivrHpNeP8kIMlYNQ7IzKIigVwc1.jpg"},
  {"name": "Pathé Thuis", "logo_url": "/lnOkJKyFl87mzqNebAbE8ADdHoB.jpg"},
  {"name": "Videoland", "logo_url": "/qN7uDYanT47WI0MmbwOr5HFFot.jpg"},
  {"name": "tenplay", "logo_url": "/68wIIGfS8iE1Vk06mAftEnBiFjJ.jpg"},
  {"name": "U-NEXT", "logo_url": "/a5T7vNaGvoeckYO6rQkHolvyYf4.jpg"},
  {"name": "dTV", "logo_url": "/kGoilkiNL5iSjDawAa2hfuHRenn.jpg"},
  {"name": "Naver Store", "logo_url": "/crFbxg6jkiKc14gpIGMkre9Y3mu.jpg"},
  {"name": "Watcha", "logo_url": "/5gmEivxOGPdq4Afpq1f8ktLtEW1.jpg"},
  {"name": "Channel 4", "logo_url": "/uMWCgjsGnO5IoQtqxXOjnQA5gt9.jpg"},
  {"name": "Timvision", "logo_url": "/6FDKQWcR6JfmRKLqezSsvGgRuUY.jpg"},
  {"name": "Infinity+", "logo_url": "/tDlGQeD2LaDaHO62itqblktNwmM.jpg"},
  {"name": "Ivi", "logo_url": "/4smEkH3vlf5V3vo2vwrOQ6Chb35.jpg"},
  {"name": "Okko", "logo_url": "/5z8dpQN27kybhn21EVLZcJPpMEo.jpg"},
  {"name": "Amediateka", "logo_url": "/dNaqCdDy4wsIzjZoYwvLRiOK4Tx.jpg"},
  {"name": "Kinopoisk", "logo_url": "/51wuCkUdkEQTUtB8TrtZzzxp3Tj.jpg"},
  {"name": "Amazon Prime Video", "logo_url": "/dQeAar5H991VYporEjUspolDarG.jpg"},
  {"name": "Hotstar", "logo_url": "/zdTSUEVZFXp3E0EkOMGN99QPVJp.jpg"},
  {"name": "Bookmyshow", "logo_url": "/hAKPOEwWdjE9evzxByVdF8QUMH3.jpg"},
  {"name": "Sky Store", "logo_url": "/6AKbY2ayaEuH4zKg2prqoVQ9iaY.jpg"},
  {"name": "SBS On Demand", "logo_url": "/cR4okiAS0zcXb4ufs3mi4PImXPB.jpg"},
  {"name": "Videobuster", "logo_url": "/82hFfWsPYtyskBrnkL8Y36vHcVf.jpg"},
  {"name": "Foxtel Now", "logo_url": "/fejdSG7TwNQ5E0p6u7A6LVs280R.jpg"},
  {"name": "ABC iview", "logo_url": "/zR1TJmEwssf0ZThB2iByNtZi2Oo.jpg"},
  {"name": "FILMO", "logo_url": "/br0wtjMDSjMahkhzn5XAXYWyr3V.jpg"},
  {"name": "Cineplex", "logo_url": "/d1mUAhpJpxy0YMjwVOZ4lxAAbeT.jpg"},
  {"name": "iciTouTV", "logo_url": "/7rJJlPpuGz0DV5OLjVW1HzYaFj9.jpg"},
  {"name": "Sixplay", "logo_url": "/95XT3uBPpodnXNMqIVYYOsEUrhw.jpg"},
  {"name": "Movistar Plus", "logo_url": "/7K6rVbpWXB6ByDI0PRzGGRXRBSY.jpg"},
  {"name": "blue TV", "logo_url": "/47klot430ytIqldQUUx2avN45Sr.jpg"},
  {"name": "Viu", "logo_url": "/o7WsYI2r1llIf9h6JTGVX9yTHPx.jpg"},
  {"name": "Catchplay", "logo_url": "/9qSVItdgkVHH3Rrl9db2yRWsgBq.jpg"},
  {"name": "iflix", "logo_url": "/vCTY2WtY1oJ8EKpp0UCz4SRpE4S.jpg"},
  {"name": "Hollystar", "logo_url": "/5Y8vMRHqoPQ8jq0m2CdhG3fzpR8.jpg"},
  {"name": "Claro video", "logo_url": "/21M5CpiOYGOhHj2sVPXqwt6yeTO.jpg"},
  {"name": "Watchbox", "logo_url": "/krduN1ScpvEt0dTaqmAZWOK89RZ.jpg"},
  {"name": "MagentaTV", "logo_url": "/lrEigPPAhggq02q53uM8vdWAIUX.jpg"},
  {"name": "Hollywood Suite", "logo_url": "/dg4Kj9s7N5pZcvJDW6vt5d9j7Uf.jpg"},
  {"name": "Universal Pictures", "logo_url": "/8pvjGOr83RSlPwKfYi6e99mOS4.jpg"},
  {"name": "Curzon Home Cinema", "logo_url": "/pXQhPQmlIYsIZD4urWs8Ul8PhA6.jpg"},
  {"name": "Sky", "logo_url": "/ytApMa9fThUQUFTn696AeNBrB8f.jpg"},
  {"name": "ARD Mediathek", "logo_url": "/avItehPq07h5nKCJgwNTxl6OD0y.jpg"},
  {"name": "Jio Cinema", "logo_url": "/boMYreJ9JWNDnXiHUfoix4oYhBc.jpg"},
  {"name": "Rai Play", "logo_url": "/cmURKKdS72Ckr52615xvc2JPsJm.jpg"},
  {"name": "BFI Player", "logo_url": "/nIm8xq1JYgHfusIcDnccEBQkoWp.jpg"},
  {"name": "Telecine Play", "logo_url": "/igttZCNzvyaHf8930XTJ540cSN0.jpg"},
  {"name": "Crave", "logo_url": "/ewOptMVIYcOadMGGJz8DJueH2bH.jpg"},
  {"name": "Zee5", "logo_url": "/vPIW5b0ebTLj9bFCZypoBbF8wSl.jpg"},
  {"name": "Arte", "logo_url": "/vPZrjHe7wvALuwJEXT2kwYLi0gV.jpg"},
  {"name": "France TV", "logo_url": "/maeiT4ORBxykOVlaW9gCsLuFPnS.jpg"},
  {"name": "Sony Liv", "logo_url": "/3973zlBbBXdXxaWqRWzGG2GYxbT.jpg"},
  {"name": "Universcine", "logo_url": "/11Yzww3g6lYZznKrom3cSrKP4St.jpg"},
  {"name": "Meo", "logo_url": "/ldkEX7JzLoWBvMP0GUHEfwAnK3B.jpg"},
  {"name": "VOD Poland", "logo_url": "/j6fAlCfbL22p5EitBREFMkc0bpn.jpg"},
  {"name": "7plus", "logo_url": "/uU4gqmoX0koF8L5yJSlfaaFd9Tz.jpg"},
  {"name": "Horizon", "logo_url": "/eoWlEQTQDeEutDyfADhAQ0HKogI.jpg"},
  {"name": "Neon TV", "logo_url": "/iscLKFDwQlr0BAgVDBcuRapLiwC.jpg"},
  {"name": "QubitTV", "logo_url": "/kE2LiK4LzsBWD8nJXRr0FbXZ4Mf.jpg"},
  {"name": "BBC Player Amazon Channel", "logo_url": "/jhhFTFVWjKVi2JjDYoqoI4dHsmL.jpg"},
  {"name": "ZDF Herzkino Amazon Channel", "logo_url": "/FtIkEXYkqTxKwCwLK79yKJW8i7.jpg"},
  {"name": "BFI Player Amazon Channel", "logo_url": "/4SJW6thrCvN6ya2hO6naksfRZDF.jpg"},
  {"name": "Ziggo TV", "logo_url": "/ozUfbWZXVdymn8iC3zze0cQPeUi.jpg"},
  {"name": "RTL+", "logo_url": "/jmR0t1kjzHcyV7raynzMbF87J9d.jpg"},
  {"name": "Joyn", "logo_url": "/tDnDBkSLLLQgFb03J9ttQhZQxZs.jpg"},
  {"name": "Crave Starz", "logo_url": "/csPQMbeJWY7bjwWruZjtc27xf2l.jpg"},
  {"name": "Globoplay", "logo_url": "/7Cg8esVVXOijXAm1f1vrS7jVjcN.jpg"},
  {"name": "O2 TV", "logo_url": "/27yD8cEx7qC1lSzaDvFlW4avXyu.jpg"},
  {"name": "LaCinetek", "logo_url": "/1syoSwH2yIskHUqeOiK9re8AMJC.jpg"},
  {"name": "Be TV Go", "logo_url": "/acANFKCTLQuvDPjJAb5SnmzJaT6.jpg"},
  {"name": "VRT MAX", "logo_url": "/gqxv8W54I95K3hNS6UT6sDRe2Az.jpg"},
  {"name": "CBC Gem", "logo_url": "/2fkcDlmwbRkApLWicbysPGcFpji.jpg"},
  {"name": "Alt Balaji", "logo_url": "/i7knsL0QgDJyuts5FbfjREUqHvu.jpg"},
  {"name": "Sky X", "logo_url": "/67FAj8KvIiV5iZMJuPUdMrqMftw.jpg"},
  {"name": "Yle Areena", "logo_url": "/nrORhu39C2YjBhx9v8rU8oFlulj.jpg"},
  {"name": "Cinemas a la Demande", "logo_url": "/AboEF9vQyH2AAndLimdO043IzLh.jpg"},
  {"name": "CTV", "logo_url": "/xoFyQOXR3qINRsdnCQyd7jGx8Wo.jpg"},
  {"name": "My5", "logo_url": "/5qLpN8ah2FZC8NpYFwRFwxlNjRn.jpg"},
  {"name": "Filmtastic Amazon Channel", "logo_url": "/ni0t0Bj4HRAQ9f6zSv1Bl9MDjLK.jpg"},
  {"name": "Ruutu", "logo_url": "/c7SqVo4DFrbK3RwhGbxvQ9SRco2.jpg"},
  {"name": "MovistarTV", "logo_url": "/tRNA2CRgA4XHvd7Mx9dH3sFtDVb.jpg"},
  {"name": "blutv", "logo_url": "/dqRpKX6vcos334v9huMpNPKHlD8.jpg"},
  {"name": "puhutv", "logo_url": "/ffvIywFtojPQnVwHVmTSZEdemdt.jpg"},
  {"name": "Kino on Demand", "logo_url": "/yTwdlHzReo6l9HLWrZqEaEUECBt.jpg"},
  {"name": "wavve", "logo_url": "/hPcjSaWfMwEqXaCMu7Fkb529Dkc.jpg"},
  {"name": "DocPlay", "logo_url": "/pVoZH9ZMGfrr1Ajk1UD29weO6iA.jpg"},
  {"name": "Mediaset Infinity", "logo_url": "/2hBbMVUI2G4GAGRAD0RZCZqDMUh.jpg"},
  {"name": "NPO Start", "logo_url": "/jPNvTunAw7309LeRMT6m3yZy1Lm.jpg"},
  {"name": "VOD Club", "logo_url": "/y7gH7FKJEE6LrO5h6wZTHQdmukO.jpg"},
  {"name": "Go3", "logo_url": "/1y6ds7KDN5Q33Jb0ZxnroEJ58Gd.jpg"},
  {"name": "BritBox", "logo_url": "/3TOx3XY1hqMz5e1i76fck5PPx7I.jpg"},
  {"name": "Canal+", "logo_url": "/eBXzkFEupZjKaIKY7zBUaSdCY8I.jpg"},
  {"name": "TV 2", "logo_url": "/fewcYbfwWEZksgE4o3WbXEsISE4.jpg"},
  {"name": "BINGE", "logo_url": "/7QX5OdsQZrXGNBKq9SPzoPV9OYQ.jpg"},
  {"name": "Sooner", "logo_url": "/jzWBemDX69VRYhudQA44IESMxQ1.jpg"},
  {"name": "wedotv", "logo_url": "/jAwT86Q0H9U8RESR3zR2FVKe83D.jpg"},
  {"name": "FlixOlé", "logo_url": "/ozMgkAAoi6aDI5ce8KKA2k8TGvB.jpg"},
  {"name": "TVNZ", "logo_url": "/wbqJss5NNebssp5RBkAdxIHecv2.jpg"},
  {"name": "Film1", "logo_url": "/uYJwhSwKHy1BahLwm3KnjP7ajgt.jpg"},
  {"name": "VVVVID", "logo_url": "/Z2up2zbp5sdLWagSQmaPozkIKM.jpg"},
  {"name": "Anime Digital Networks", "logo_url": "/w86FOwg0bbgUSHWWnjOTuEjsUvq.jpg"},
  {"name": "Joyn Plus", "logo_url": "/5aHLriu21FRDGlxY5zbzyEuoSvA.jpg"},
  {"name": "Blockbuster", "logo_url": "/auWzottqpExOHUlJwDZ4DvdHGoL.jpg"},
  {"name": "HBO Go", "logo_url": "/f7z2izTDn3zyYtDymYHqhjEqFuj.jpg"},
  {"name": "SF Anytime", "logo_url": "/sNmIwrniZZORvNJOmEuWGhwBJNf.jpg"},
  {"name": "CONtv", "logo_url": "/RnSjeA2dgmKNLlFV3JE8cmPyLS.jpg"},
  {"name": "Telstra TV", "logo_url": "/n8STI6Hi6GCzLXHiibl9nIJdnaP.jpg"},
  {"name": "TV 2 Play", "logo_url": "/tpfmd22xEapb1aW2gzjSM5104rx.jpg"},
  {"name": "OzFlix", "logo_url": "/hP3ymMLPyvxUCA1ODfIHJ4pu3c2.jpg"},
  {"name": "Draken Films", "logo_url": "/vyu1bwP7H0p7B8BLB3QRwHVmvlA.jpg"},
  {"name": "Fetch TV", "logo_url": "/9B7l9ZSos54kFrZbliVExt2z9C9.jpg"},
  {"name": "Hungama Play", "logo_url": "/pMTVKAUyjMb0xdMnHqfNj73MGH6.jpg"},
  {"name": "ThreeNow", "logo_url": "/vcsLhyNRm528LzfVqQJDddusP27.jpg"},
  {"name": "NFB", "logo_url": "/zwTo48JWc9FNCgJ1ADPAfkhYUuA.jpg"},
  {"name": "NRK TV", "logo_url": "/teyfWuW8nS8wsk6rVXIbynrxIX6.jpg"},
  {"name": "Filmstriben", "logo_url": "/eRRc8n7fzQewjh15d2fao45Xxvi.jpg"},
  {"name": "Belas Artes à La Carte", "logo_url": "/1P601MpWM0sdOEDXr3fFS356PdC.jpg"},
  {"name": "Beamafilm", "logo_url": "/mKHumLTIy1SmihWSVqN9iPxFnqf.jpg"},
  {"name": "Global TV", "logo_url": "/o2qiZykonJuLglxwBbtUbHu4wIO.jpg"},
  {"name": "Picl", "logo_url": "/mj1iZQtWmq9s0LmPhZ7KjUtgVja.jpg"},
  {"name": "RTPplay", "logo_url": "/9HQD7iNLyJQYhEq3Z9blyn7P4Vk.jpg"},
  {"name": "Mitele ", "logo_url": "/273VHxSrDdEwLTXJrsnE6yJMqNZ.jpg"},
  {"name": "RTBF", "logo_url": "/5lDbFEGk2OwLdPZJLNk1Z6iyuoL.jpg"},
  {"name": "Kirjastokino", "logo_url": "/27Zotk8HAfHk7qPz9x6DWWhOpb7.jpg"},
  {"name": "Believe", "logo_url": "/zvVNXNs1pKM4ggBoj30bXwhLxCa.jpg"},
  {"name": "Bioskop Online", "logo_url": "/rvHcdDDyfuFRD2C4mLkTUtKk2CS.jpg"},
  {"name": "DIRECTV GO", "logo_url": "/nr5UBW4IGKgBwmhpTMOfcvnX2vX.jpg"},
  {"name": "genflix", "logo_url": "/o506FcbahVPxvVcsSA5dFLj9drV.jpg"},
  {"name": "Club Illico", "logo_url": "/cQjWvOiKRPeSuWRNGegcBjyqVbR.jpg"},
  {"name": "NLZIET", "logo_url": "/88ssxWDyhabjrwSrbSf87nCDK2y.jpg"},
  {"name": "ShemarooMe", "logo_url": "/ec3kgfQ6YXbT3AFRh8bkQZQFLb2.jpg"},
  {"name": "EPIC ON", "logo_url": "/ymlo2k6RlX0zF5Te1AYwXRQ7Pra.jpg"},
  {"name": "GOSPEL PLAY", "logo_url": "/vPqYxDMMtoHlYg8ZzEXS5S6y6iQ.jpg"},
  {"name": "History Play", "logo_url": "/sYVlH6nNSaR2syC0sjkxErwOOjx.jpg"},
  {"name": "Home of Horror", "logo_url": "/ntvIffmJe7ZO1SSobwSx8ABAZFG.jpg"},
  {"name": "Filmtastic", "logo_url": "/eptSSZPhXJllBI8QywFDmgOMNkU.jpg"},
  {"name": "ArthouseCNMA", "logo_url": "/eBt8WXNGuJGBgQ28W88Y3eTAWsD.jpg"},
  {"name": "ManoramaMax", "logo_url": "/tFkqZYsDhNe6hJCx50Aw6oma24w.jpg"},
  {"name": "MAX Stream", "logo_url": "/zDSJn28xTG4f6h2yxZ6UmCPvZ8j.jpg"},
  {"name": "NOW", "logo_url": "/xzN54gRvHqG9CkJPYPeS6DmJokS.jpg"},
  {"name": "tvo", "logo_url": "/yQhSfwOzTF9EATdq47LlRFghmiO.jpg"},
  {"name": "Vidio", "logo_url": "/l2ZNwMC37iK0fquVuYWfcgIJJdF.jpg"},
  {"name": "CINE", "logo_url": "/21uSo4VQUdEmeA6RJ6gPSRwusbt.jpg"},
  {"name": "SVT", "logo_url": "/oBdaG066fB5O7XYrYKQflOZvd89.jpg"},
  {"name": "Cineasterna", "logo_url": "/k35BlS63LM7D0Q8e0jktRyEsnTq.jpg"},
  {"name": "Tele2 Play", "logo_url": "/jy4yvY3szonb0p6rtMXvF5stuX9.jpg"},
  {"name": "Oldflix", "logo_url": "/rXNXvz2096ri69ZlZsFwKxZYJsS.jpg"},
  {"name": "Tata Play", "logo_url": "/5VLMQDq6LWfftQCl7sYrtTseXRA.jpg"},
  {"name": "Player", "logo_url": "/jhMNVBV2UocEGepRkr9oFPD7Gpb.jpg"},
  {"name": "Discovery+", "logo_url": "/eMTnWwNVtThkjvQA6zwxaoJG9NE.jpg"},
  {"name": "iWantTFC", "logo_url": "/nHxedyutqerk1yWBsc17mF8gteZ.jpg"},
  {"name": "TNTGo", "logo_url": "/3A46LAupJwaNhzvE6bFW4ZUO7Pw.jpg"},
  {"name": "Shadowz", "logo_url": "/qwRq7klF8EijYs7XgvxSaYd6v6w.jpg"},
  {"name": "MX Player", "logo_url": "/ayHY6wKxvCKj2PU8eRPFxnPc6B0.jpg"},
  {"name": "Noovo", "logo_url": "/ss6JfWLwwrIjO1AfEsBy8GYM1EU.jpg"},
  {"name": "TriArt Play", "logo_url": "/sgws7qaFHYTEeYhc8RCjZ5D7IlH.jpg"},
  {"name": "Popcorntimes", "logo_url": "/3kmmwlTET45ryW7azWfFJ0Piwll.jpg"},
  {"name": "Discovery+", "logo_url": "/bPW3J8KlLrot95sLzadnpzVe61f.jpg"},
  {"name": "Knowledge Network", "logo_url": "/7wADjxAylhGGqWYNz86jGOQSoZG.jpg"},
  {"name": "Amazon Arthaus Channel", "logo_url": "/9MABvFilVMUAV86vLUgBgeM5LQQ.jpg"},
  {"name": "Argo", "logo_url": "/jQMOpRluniNQSEp6V7IvtKxXMW.jpg"},
  {"name": "ZDF", "logo_url": "/ugus0p8BGGhE8sxhwAjJz2o8jyy.jpg"},
  {"name": "Viddla", "logo_url": "/zDWv7ROPCqZXsIKSVFRANOTC1wx.jpg"},
  {"name": "Elisa Viihde", "logo_url": "/eglAxQEXSO13p6gNf3HKymrIu7y.jpg"},
  {"name": "rtve", "logo_url": "/3QQKYFUDt13Q2Zm6JM2cOjlbd27.jpg"},
  {"name": "filmfriend", "logo_url": "/zXu75uIAMgdblYvFFPzoIHmQhxa.jpg"},
  {"name": "CONTAR", "logo_url": "/zbpPadcBhRpVREGiyWOCM6ioBaf.jpg"},
  {"name": "Libreflix", "logo_url": "/3MsEoFiBy9eaUdKo5Cx1iR9BPQy.jpg"},
  {"name": "Spuul", "logo_url": "/iAHIvSg9jlXIvOR1NONjN5CsU3l.jpg"},
  {"name": "IFFR Unleashed", "logo_url": "/fpHXCUD89WRC21wJjz3DDCgyudw.jpg"},
  {"name": "IPLA", "logo_url": "/7jnt49RwicQ4Vs2v1MgfERfVuDS.jpg"},
  {"name": "Tenk", "logo_url": "/7up8PVB6RaL0fDs5XFzjJlq9mg2.jpg"},
  {"name": "QFT Player", "logo_url": "/fFIAM3bHNoyjeQ8q160NWUD3S94.jpg"},
  {"name": "Telia Play", "logo_url": "/7QzLA6rsML2rKxhuagWlDl7Zxlm.jpg"},
  {"name": "tvzavr", "logo_url": "/wygGzgXDEiSvF6TEEZcayaLWqTC.jpg"},
  {"name": "More TV", "logo_url": "/yyMxS08v80D96O7NtL9supa5sn7.jpg"},
  {"name": "Cinépolis KLIC", "logo_url": "/nAJhvliaHqNjZRoGckPJ8KNaofq.jpg"},
  {"name": "Filmoteket", "logo_url": "/rTLyVzy9lyPpujUQ026CiRDqQrF.jpg"},
  {"name": "Lionsgate Play", "logo_url": "/4P963etfZGzeJqqd6E8fj2OIUht.jpg"},
  {"name": "KPN", "logo_url": "/nzjqsR28PVVp5GJAlHtbBArNKqN.jpg"},
  {"name": "Filme Filme", "logo_url": "/9hYo6NK3IHDAIo4HpfvVUxtGlft.jpg"},
  {"name": "Premier", "logo_url": "/r7cgAhlSLI4r80P1Si9P0jOevrr.jpg"},
  {"name": "RTL Play", "logo_url": "/3KaY2AQFkPHlc6QgGQmRbYxnsQa.jpg"},
  {"name": "KinoPop", "logo_url": "/prbT74SL6obBAywv50qi5gnoBj5.jpg"},
  {"name": "Oi Play", "logo_url": "/FFBHmEWA05CsqsKO272fNMWBvU.jpg"},
  {"name": "Klik Film", "logo_url": "/iqq9pxAgCVM4jD9C21kpWj8ZBTG.jpg"},
  {"name": "TvIgle", "logo_url": "/3EJQtj4m83kRh7bV9BF6KWmPiKp.jpg"},
  {"name": "Strim", "logo_url": "/3ZigBD8WTEPcEHAvMWiJGUsv5u4.jpg"},
  {"name": "Nova Play", "logo_url": "/ApcuSS6VOCV7slF4GBRARfy31bg.jpg"},
  {"name": "IFC Amazon Channel", "logo_url": "/tw81Wj2gaVL30O7CvzfsqTLMxQg.jpg"},
  {"name": "MGM Amazon Channel", "logo_url": "/8U0SUpB5Jq6dW1N5cSo8EmJJtjL.jpg"},
  {"name": "TELETOON+ Amazon Channel", "logo_url": "/4KZlGfHQEza2dBf3HVk4oWmh611.jpg"},
  {"name": "Now TV Cinema", "logo_url": "/6sJLsWk4TZHz1EffmTRSkaDehkh.jpg"},
  {"name": "STV Player", "logo_url": "/aG4lXRDfHl3qgQCgpWI6KEJV0i7.jpg"},
  {"name": "Virgin TV Go", "logo_url": "/m8rpPax1tjN9VlxNLRM5Ok4pTTI.jpg"},
  {"name": "Eros Now Amazon Channel", "logo_url": "/6xQrNQoTmXWhaJj4O8u2FRsXBXs.jpg"},
  {"name": "Arrow Video Amazon Channel", "logo_url": "/u4OSH6NN7bdHRJJVLwozPa0jDUj.jpg"},
  {"name": "Pokémon Amazon Channel", "logo_url": "/l4h4b2esfTb7evLRiLrMjk6rsHj.jpg"},
  {"name": "Shout! Factory Amazon Channel", "logo_url": "/ivRp15loTWoVy3eEHALoYEyXI8J.jpg"},
  {"name": "MotorTrend Amazon Channel", "logo_url": "/dVOlUPVN7x4wYaGtujczWEDrxOU.jpg"},
  {"name": "FilmBox Live Amazon Channel", "logo_url": "/xJ3B83btVmp4wyRGRRRgWCibTLI.jpg"},
  {"name": "CuriosityStream Amazon Channel", "logo_url": "/tLbUhvK1RcA5I4JHndxf5dprkE7.jpg"},
  {"name": "DocuBay Amazon Channel", "logo_url": "/91oRDZySWtw60ei006uQ45gmjXD.jpg"},
  {"name": "Super Channel Amazon Channel", "logo_url": "/5W6vTKE684EhdITeMUjdcTIBGdh.jpg"},
  {"name": "StackTV Amazon Channel", "logo_url": "/djTJ7pAkIhmPaN3eTA6wTUrphNG.jpg"},
  {"name": "OUTtv Amazon Channel", "logo_url": "/d0KmcInHpiF44ahOLrXCQATEFmD.jpg"},
  {"name": "Love Nature Amazon Channel", "logo_url": "/bSy9zFiZtbKBHIyOu0H2Fs5cJmx.jpg"},
  {"name": "Smithsonian Channel Amazon Channel", "logo_url": "/bEgGGEDgBczZcZRJ6x1MtTym2LV.jpg"},
  {"name": "BBC Earth Amazon Channel", "logo_url": "/2WEzLLWbvMLHSNkAjYrIeDOmLJM.jpg"},
  {"name": "UMC Amazon Channel", "logo_url": "/r9JMtkJn6eE0i8Vi9ONLVci7Hnv.jpg"},
  {"name": "VI movies and tv", "logo_url": "/1tCAM3WVOl0xknuwVc0v1LBWZNV.jpg"},
  {"name": "W4free", "logo_url": "/sSMxh3tIOAi3QBoSXdagDxb3OKp.jpg"},
  {"name": "Paus", "logo_url": "/anCFybN8muqpzduWQUml23ZmjBl.jpg"},
  {"name": "Star Plus", "logo_url": "/cv5S44vHpNoGj7wby6390AyhEkH.jpg"},
  {"name": "DRTV", "logo_url": "/dnD5cUeAPruAcYwwKKxYbMcfccq.jpg"},
  {"name": "Dansk Filmskat", "logo_url": "/gV2kLLKcZOBt2HUEB51bX0AqC11.jpg"},
  {"name": "UPC TV", "logo_url": "/l5SFTF3l9l1KSgZJM7foAppUenz.jpg"},
  {"name": "WeTV", "logo_url": "/r3tmJFjecQGAfHjWOafhr1pux6b.jpg"},
  {"name": "KKTV", "logo_url": "/r9rEesXJjzY8sH3NLqNdkPdZ714.jpg"},
  {"name": "LINE TV", "logo_url": "/j35eayHG7RC9Ll0ehtbOuek0TZL.jpg"},
  {"name": "Otta", "logo_url": "/z7IYTxUHSrk1VPYgsc5RDe1E3tA.jpg"},
  {"name": "Voyo", "logo_url": "/iTzW2q9e1Kk10l2s934SDgPSJNm.jpg"},
  {"name": "Edisonline", "logo_url": "/hsuv1uc7SUr9YjCXRlLQNELt4UU.jpg"},
  {"name": "OSN", "logo_url": "/kC6JTo59Gj6I4vJPyBAYGh0sKAE.jpg"},
  {"name": "STARZPLAY", "logo_url": "/pDroY6RxYdVw63eAepag4b116Ub.jpg"},
  {"name": "HRTi", "logo_url": "/yHqa57jOQqhBarOh9lw2noJno00.jpg"},
  {"name": "Pickbox NOW", "logo_url": "/p6hKVngF1iWciIyxYmrl3UUitg7.jpg"},
  {"name": "CineMember", "logo_url": "/6vchFMhgi7jhyXyKBoutzwMmM5b.jpg"},
  {"name": "Nexo Plus", "logo_url": "/jyiwuXdyRrh2R5UwY4MhOF4Xbfy.jpg"},
  {"name": "STUDIOCANAL PRESENTS Apple TV Channel", "logo_url": "/48aHN85OncgXjtl6DOVOJ6Wni4g.jpg"},
  {"name": "Filmlegenden Amazon Channel", "logo_url": "/iM7Ed1d7mYT3DQWSqLoW3tIN4uS.jpg"},
  {"name": "Cinema of Hearts Amazon Channel", "logo_url": "/coWnECpOyKLWMTSlXkfT2PhLs9E.jpg"},
  {"name": "Bloody Movies Amazon Channel", "logo_url": "/jG5tKt6JoHsYRoUq6Wg8i9QuHt2.jpg"},
  {"name": "Film Total Amazon Channel", "logo_url": "/3fhcb4cThixkfNpOudnpLcosTCA.jpg"},
  {"name": "Looke Amazon Channel", "logo_url": "/xht0G3uRLcTgjxRz3cDHuf8KKUw.jpg"},
  {"name": "FlixOlé Amazon Channel", "logo_url": "/2GQVxfaiWA4n93I7sJDJf1b6NqS.jpg"},
  {"name": "OCS Amazon Channel ", "logo_url": "/oYsPBkqP7QjliFT758DrR9i2mpl.jpg"},
  {"name": "Home of Horror Amazon Channel", "logo_url": "/7Zy6GqC8lQEvfBlWC1bJcaJzPRd.jpg"},
  {"name": "Arthouse CNMA Amazon Channel", "logo_url": "/tnQ5PGryncrVxE9bK6nxif82wtd.jpg"},
  {"name": "TVCortos Amazon Channel", "logo_url": "/32R4lsqOPclNhb3qV613J8T8mdL.jpg"},
  {"name": "Pongalo Amazon Channel  ", "logo_url": "/t7I2Tx6Q7Nz96KexIPjkzUJOCbt.jpg"},
  {"name": "Play Suisse", "logo_url": "/c1Nj8YGitlXbvvv0taHLkBeyTaK.jpg"},
  {"name": "Turk On Video Amazon Channel", "logo_url": "/9aLd9gAK6xHtWK2tXDYq1fOqzJA.jpg"},
  {"name": "meJane", "logo_url": "/651I5dLHpxDUePZcNkYTDcARTeE.jpg"},
  {"name": "iBAKATV", "logo_url": "/k1LBSLQ2d6B95VpMoBASkM7EdZ2.jpg"},
  {"name": "IROKOTV", "logo_url": "/4bsFc5AgxDNdDeS1NIjVRviARhB.jpg"},
  {"name": "Hollywood Suite Amazon Channel", "logo_url": "/29VK28jsSjFWHdXl1lxPb2SGmAk.jpg"},
  {"name": "Moviedome Plus Amazon Channel", "logo_url": "/9NYihXY9biyXFJSZQq5HIGu1n8B.jpg"},
  {"name": "Aniverse Amazon Channel", "logo_url": "/a2wCZ9ultzAbjAaG6JMiCFJoy9s.jpg"},
  {"name": "Superfresh Amazon Channel", "logo_url": "/9T5H28SXBkzmnZYy2iRfI7GzwLL.jpg"},
  {"name": "Comedy Central Plus Amazon Channel", "logo_url": "/eMoqH13BYzIC1wDlhONhHfDM3DN.jpg"},
  {"name": "BluTV Amazon Channel", "logo_url": "/AvUM6aAqYh7JxUokb5T5ERn3KMs.jpg"},
  {"name": "GRJNGO Amazon Channel", "logo_url": "/cYzZyot2IuGUYUXLvw6BMBT6v9p.jpg"},
  {"name": "MTV Plus Amazon Channel", "logo_url": "/g06QZd5k4bjDEcZ3J97jb3rNlfB.jpg"},
  {"name": "RTL Passion Amazon Channel", "logo_url": "/gHBdFYhxdeLHl8jimTDwA1bFv01.jpg"},
  {"name": "Silverline Amazon Channel", "logo_url": "/xXN970tHrMfL7BQUTVSu96IY9gs.jpg"},
  {"name": "Sony AXN Amazon Channel", "logo_url": "/7gkA2RRoHTdGf8KXBKkMbevqN1p.jpg"},
  {"name": "Acontra Plus", "logo_url": "/8vES2nJNwOF57muvqLByDLm6snp.jpg"},
  {"name": "AVA VOBB", "logo_url": "/nJlTOUfDEUD6fQLopWBnUNzo2NA.jpg"},
  {"name": "AVA HBZ", "logo_url": "/p1mlqlIOtPPZf7lckxX3XLAm2ia.jpg"},
  {"name": "AVA CSAL", "logo_url": "/aqyyZxc2tQ1gEORXNF2d55GvD5P.jpg"},
  {"name": "AVA BGB", "logo_url": "/zJtYNzq9lpMrsqhg6t2y9AWs9dL.jpg"},
  {"name": "Infinity Selection Amazon Channel", "logo_url": "/q77DpszIupURiKGDeFdSHo7TsTF.jpg"},
  {"name": "CG Collection Amazon channel", "logo_url": "/9bRB5nKmQPZYGQkUtLRhw1w0IcD.jpg"},
  {"name": "iWonder Full Amazon channel", "logo_url": "/iZzlUo191ynXNpPNdcY8vvlYSf6.jpg"},
  {"name": "Full Action Amazon Channel", "logo_url": "/xdUXTl4ontqdKv9ZpSBZKiOTyWm.jpg"},
  {"name": "Cine Comico Amazon Channel", "logo_url": "/gQoeIu88CiTF54vYRGwTONHEAtO.jpg"},
  {"name": "Universcine Amazon Channel", "logo_url": "/uPhMKOrup9jtJHIci8FXo3D6y5x.jpg"},
  {"name": "Action Max Amazon Channel", "logo_url": "/1PhTUiq01Z82nvuJlDNYtBnRRN0.jpg"},
  {"name": "Filmo Amazon Channel", "logo_url": "/4clLD3X9nTapdRey1FgcSUPYRy3.jpg"},
  {"name": "Insomnia Amazon Channel", "logo_url": "/nJ43DUei5yK9wWG3g9YpYT4UfN4.jpg"},
  {"name": "Shadowz Amazon Channel", "logo_url": "/vBbNBDZnpnhgHe5ZO9CVur4DmkG.jpg"},
  {"name": "INA  madelen Amazon Channel", "logo_url": "/zg1uFDzIchkwrwOW6dq7LFxB4n6.jpg"},
  {"name": "Benshi Amazon Channel", "logo_url": "/yXSOt0AKxG28wXhnBaogQGwF7Rq.jpg"},
  {"name": "Planet Horror Amazon Channel", "logo_url": "/yISpVXhf6axqiHh6lBvJ8RRrZ8v.jpg"},
  {"name": "Dizi Amazon Channel", "logo_url": "/tM1HabyA45cnckBEhLS7hAVga5g.jpg"},
  {"name": "Acontra Plus Amazon Channel", "logo_url": "/tGvAD4O9obFP3DfOrDn8NaRQ6eT.jpg"},
  {"name": "Historia y Actualidad Amazon Channel", "logo_url": "/aJECXkHekrkuRZ7ABF5YR9DVDd8.jpg"},
  {"name": "Icon Film Amazon Channel", "logo_url": "/jTtwvCgQxD8W7vljH5pH28PfYQD.jpg"},
  {"name": "Curzon Amazon Channel", "logo_url": "/kSONYxM7a0mESEjCaqaozVTdByB.jpg"},
  {"name": "Hallmark TV Amazon Channel", "logo_url": "/x2cjJAyU8CLwyYHaIxzzEjXJFU8.jpg"},
  {"name": "Studiocanal Presents Amazon Channel", "logo_url": "/mLif0L2p0M2AK4pbqHOBqLwB3bP.jpg"},
  {"name": "TOD", "logo_url": "/bFxDjHDXP02u1dLPZfTsTC1L6EA.jpg"},
  {"name": "TF1", "logo_url": "/tctevYL8l18wJ2PnLxRx5U3avny.jpg"},
  {"name": "filmingo", "logo_url": "/704tOH9qi7y7DDl1CmzDCAI0dcQ.jpg"},
  {"name": "Sooner Amazon Channel", "logo_url": "/6WQqJyaFd0fmLLE6WIjFQyEyAFj.jpg"},
  {"name": "Yorck on Demand", "logo_url": "/zHjoZOQ2DOlu2HCZH18bzQYGc3t.jpg"},
  {"name": "SkyShowtime", "logo_url": "/gQbqEYd0C9uprYxEUqTM589qn8g.jpg"},
  {"name": "Love and Passion Amazon Channel", "logo_url": "/rYszOzWFzq7xPffwJ7XN5rJRI7u.jpg"},
  {"name": "Lionsgate Plus", "logo_url": "/cGkSLaXKxxcJY38qSCwdKxxSM1x.jpg"},
  {"name": "Studiocanal Presents MOVIECULT Amazon Channel", "logo_url": "/3VYyCBiG2WTCZ2tGzTkWvoXTeqi.jpg"},
  {"name": "Studiocanal Presents ALLSTARS Amazon Channel", "logo_url": "/tHX1KI8VdAzKWMxSgPKqKfHcxFA.jpg"},
  {"name": "Behind the Tree", "logo_url": "/fxYgfnsrUWa7e9aNr3j3RERpm66.jpg"},
  {"name": "Tivify", "logo_url": "/8VhN4PeHU7yfMNoWK0DnwdS1HlS.jpg"},
  {"name": "Magenta TV", "logo_url": "/f195E2gXoZBp7QjGNL4XICXxiDk.jpg"},
  {"name": "Telenet", "logo_url": "/kwftIxtjuCAROIcdd53UEjzSmca.jpg"},
  {"name": "Filmow", "logo_url": "/oHr80zlf3DWxF6imu6QKqcz275.jpg"},
  {"name": "UAM TV", "logo_url": "/9I6PdkTdLItbFdHRZQarD55vcVC.jpg"},
  {"name": "Pass Warner Amazon Channel", "logo_url": "/6XLxMoxqVCZRTkAavMwnsbpx3XU.jpg"},
  {"name": "BrutX Amazon Channel", "logo_url": "/2HsZxiwKi9Ym91r6WY9ojORvq79.jpg"},
  {"name": "Animation Digital Network Amazon Channel", "logo_url": "/9jmtFeqCWvTSYpMMUPNhk8JNJWS.jpg"},
  {"name": "Universal+ Amazon Channel", "logo_url": "/4ABGBjAMtRrYUrr0w3QfanuiaoB.jpg"},
  {"name": "alleskino Amazon Channel", "logo_url": "/s7ZjSKXOtjKBb7hAzBm3b0iY0SG.jpg"},
  {"name": "RTL Crime Amazon Channel", "logo_url": "/5Qe65kN95Z0CytOaG3I8bytdW7A.jpg"},
  {"name": "CineAutore Amazon Channel", "logo_url": "/vM8Oe43bljZqRenO2xSIRHT7GMN.jpg"},
  {"name": "Anime Generation Amazon Channel", "logo_url": "/xcXI50PQIBsRDAP6eEpv09Vz8SG.jpg"},
  {"name": "Raro Video Amazon Channel", "logo_url": "/vXXpcM5yoD8GBWi6U50dOiKgukH.jpg"},
  {"name": "MIDNIGHT FACTORY Amazon Channel", "logo_url": "/sL8ViH6zWOe07xHoiwxUwCXy890.jpg"},
  {"name": "Amazon miniTV", "logo_url": "/oRfWFOG7XbNO1ZSICEnRzmUt7bT.jpg"},
  {"name": "ARD Plus", "logo_url": "/qTybjmHLNcXZExZLBnm4muVCDzP.jpg"},
  {"name": "TV+", "logo_url": "/c8ryDZroFQtuyRVPBwXq5PLpTWV.jpg"},
  {"name": "Apollo", "logo_url": "/5FeLH5VGqpE4K48huk2T5h2xnY5.jpg"},
  {"name": "Reserva Imovision", "logo_url": "/cV6ShEHGS1Bs83xszJlorJVRU47.jpg"},
  {"name": "Kinobox", "logo_url": "/6HNeed3CwLOsUrVEUG6yIIhOFoc.jpg"},
  {"name": "Prima Plus", "logo_url": "/vrefjVylvD4RkEjQguuXebCp9UQ.jpg"},
  {"name": "Filmtastic bei Canal+", "logo_url": "/kP741xlbpymqjELaTcPiAet3SnV.jpg"},
  {"name": "Lepsi TV", "logo_url": "/489t5n9o1KhH7voGNQkrXT7vBKV.jpg"},
  {"name": "TV4 Play", "logo_url": "/5lKPs5yrjeKHFrXh9RIvzyJJzSd.jpg"},
  {"name": "Allente", "logo_url": "/a6z85DKGuPOyTg03TlJ6ShzzFO6.jpg"},
  {"name": "Molotov TV", "logo_url": "/8qSG9LtUhBQIWy2Fr6fzeW7gBdd.jpg"},
  {"name": "Filmicca", "logo_url": "/ptNJnBKMVLv3XxmLU9UMVSDop80.jpg"},
  {"name": "NPO Plus", "logo_url": "/cYyxWlMG0o1dKRvOdpMrVIFrY4F.jpg"},
  {"name": "Brollie", "logo_url": "/zlOChFXWhfIqK2QCf6KPxu0nH2D.jpg"},
  {"name": "ZDF Select Amazon Channel ", "logo_url": "/mmDWKHiDLmHDo2OWnduQuI1T4bh.jpg"},
  {"name": "Videoload", "logo_url": "/dKh2TJ9lTWV0UIcDQGMnMyQ8AIN.jpg"},
  {"name": "TELE 5", "logo_url": "/a1zZJiOid4gG27xJtkcDaSVrQdb.jpg"},
  {"name": "MTV Katsomo", "logo_url": "/wRMEDOZ8iAEEHXIY2VyckvT0K8I.jpg"},
  {"name": "ARD Plus Apple TV channel", "logo_url": "/9jxvmD363A04nuMw9jgyLzeDB3J.jpg"},
  {"name": "Arthaus+ Apple TV channel", "logo_url": "/as1NArQkfAnptYQP3VS03GOJPdm.jpg"},
  {"name": "BFI Player Apple TV Channel", "logo_url": "/kohVS01u3sP5IkqsSaAKdBit6aP.jpg"},
  {"name": "MGM Apple TV Channel", "logo_url": "/iVZ6b9YR0O8ugGrbqIXclBlJra3.jpg"},
  {"name": "Love Nature Apple TV Channel", "logo_url": "/dy9XKHyf16dSB1Lg7KIrtUwDxIX.jpg"},
  {"name": "Lionsgate Play Apple TV Channel", "logo_url": "/p8i1zUcZYQ7qU6RrGvGbDItvcx4.jpg"},
  {"name": "Lifetime Play Apple TV Channel", "logo_url": "/hwX55ugBE1qtM62egayb1CHvNEh.jpg"},
  {"name": "Lionsgate Play Amazon Channel", "logo_url": "/yVbjPWEbsStanRAxwKMDARVOvsn.jpg"},
  {"name": "Lifetime Play Amazon Channel", "logo_url": "/zNYEUs0Z5t7jJqnLTtla8v6AoS4.jpg"},
  {"name": "Wedo TV", "logo_url": "/ptSF973yIl90KwhEmNWDvN1nhan.jpg"},
  {"name": "KiKA", "logo_url": "/5JkLub6H7HsWyLHbitnuXTzXmlJ.jpg"},
];

export default streamingServiceIconsMy;
