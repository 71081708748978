import React, { useState, useEffect, useRef, useCallback } from "react";
import MovieDetailPopup from "./MovieDetailPopup";
import MovieShelf from "./MovieShelf";
import Loading from "./Loading";
import { ReactComponent as DropDown } from "../assets/chevron.down.svg";
import { ReactComponent as CheckmarkSmall } from "../assets/check-green.svg";
import { useNavigate, useParams, useLocation } from "react-router-dom";

import "./style.css";
import "./style2.css";
import "./style3.css";

import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";

const ExploreMovies = () => {
  let apiBaseUrl = "";
  // console.log(`ENV: ${process.env.REACT_APP_NODE_ENV}`);
  if (process.env.REACT_APP_NODE_ENV === "development") {
    // Use the development base URL
    apiBaseUrl = "http://localhost:3001";
  } else {
    // Use the production base URL (empty string for relative URL) e
    apiBaseUrl = "";
  }
  const location = useLocation();
  const navigate = useNavigate();
  const { movieId } = useParams(); // Assuming your route parameter is named `movieId`
  const movieRefs = useRef({}); // Object to hold refs for each movie

  const [contentType, setContentType] = useState("movies"); // Initial state is 'movies'

  // Function to generate a ref for a given movie ID
  const generateRef = useCallback((movieId) => {
    if (!movieRefs.current[movieId]) {
      movieRefs.current[movieId] = React.createRef();
    }
    return movieRefs.current[movieId];
  }, []);

  // State to store movies
  const [movies, setMovies] = useState([]);
  const {
    user,
    getAccessTokenSilently,
    isAuthenticated,
    loginWithRedirect,
    logout,
    isLoading,
  } = useAuth0();
  const [seenMovies, setSeenMovies] = useState(new Set());
  const [watchlistMovies, setWatchlistMovies] = useState(new Set());
  const [userScores, setUserScores] = useState({});
  const [selectedMovie, setSelectedMovie] = useState(null);

  const initialMovieRef = useRef(null);

  const [isMoviesLoading, setIsMoviesLoading] = useState(true); // State for movie loading
  // const [loading, setLoading] = useState(true);

  const topRef = useRef(null); // Add this at the start of your component

  const [showYearDropdown, setShowYearDropdown] = useState(false);
  const [showStreamingDropdown, setShowStreamingDropdown] = useState(false);
  const toggleYearDropdown = () => setShowYearDropdown((prev) => !prev);
  const [selectedYears, setSelectedYears] = useState(new Set());
  const [selectedStreamingServices, setSelectedStreamingServices] = useState(
    new Set()
  );
  const yearDropdownRef = useRef(null);
  const streamingDropdownRef = useRef(null);
  const seenDropdownRef = useRef(null);
  const [selectedGenres, setSelectedGenres] = useState(new Set());
  // const [showRated, setShowRated] = useState('all'); // 'all', 'rated', or 'unrated'
  const [showRated, setShowRated] = useState("all"); // Now 'all', 'rated', 'unrated', 'seen', or 'unseen'
  const [showRatedUnratedDropdown, setShowRatedUnratedDropdown] =
    useState(false);
  const toggleRatedUnratedDropdown = () =>
    setShowRatedUnratedDropdown((prev) => !prev);
  const [showGenreDropdown, setShowGenreDropdown] = useState(false);
  const genreDropdownRef = useRef(null);
  const toggleGenreDropdown = () => setShowGenreDropdown((prev) => !prev);
  const [searchQuery, setSearchQuery] = useState("");
  const divRef = useRef(null);

  const [showLangDropdown, setShowLangDropdown] = useState(false);
  const toggleLangDropdown = () => setShowLangDropdown((prev) => !prev);
  const [selectedLangs, setSelectedLangs] = useState(new Set());
  const langDropdownRef = useRef(null);

  const [filtersApplied, setFiltersApplied] = useState(true);
  const [userMadeChanges, setUserMadeChanges] = useState(false);

  const [clusterShelves, setClusterShelves] = useState([]);

  // const ratedMoviesCount = movies.filter(movie => userScores.hasOwnProperty(movie.Const)).length;
  // const unratedMoviesCount = movies.filter(movie => !userScores.hasOwnProperty(movie.Const)).length;
  // Assuming `seenMovies` is a Set of movie Consts for seen movies
  const ratedMoviesCount = Object.keys(userScores).length;
  const unratedMoviesCount = movies.length - ratedMoviesCount;
  const seenMoviesCount = movies.filter((movie) =>
    seenMovies.has(movie.Const)
  ).length;
  const unseenMoviesCount = movies.length - seenMoviesCount;

  useEffect(() => {
    if (movieId) {
      // Fetch the movie by ID or find it in your current state
      // For demonstration, assuming you have a method to find a movie by ID
      const movieToShow = movies.find((movie) => movie._id === movieId);
      setSelectedMovie(movieToShow);
    }
  }, [movieId, movies]);

  // UseEffect to scroll to the selected movie when it changes
  // useEffect(() => {
  //   if (selectedMovie && movieRefs.current[selectedMovie.Const] && movieRefs.current[selectedMovie.Const].current) {
  //     movieRefs.current[selectedMovie.Const].current.scrollIntoView({
  //       behavior: 'smooth',
  //       block: 'nearest',
  //     });
  //   }
  // }, [selectedMovie]);

  const createSlug = (title) => {
    return title
      .toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]/g, "");
  };

  const openMoviePopup = (movie) => {
    navigate(`/explore/${movie._id}`);
    const elementTop = document.querySelector(".h-100");
    if (elementTop) {
      // elementTop.scrollIntoView({ behavior: 'smooth', block: 'start' });
      // elementTop.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    setSelectedMovie(movie);
  };
  
const closePopup = () => {
  navigate(-1);
  setSelectedMovie(null);
};

useEffect(() => {
  const isBaseExplorePage = () => {
    const pathSegments = location.pathname.split("/").filter(Boolean);
    return pathSegments.length === 1 && pathSegments[0] === "explore";
  };

  if (isBaseExplorePage()) {
    setSelectedMovie(null);
  }
}, [location, setSelectedMovie]);

const handleSimilarMovieSelect = (similarMovie) => {
  const slug = createSlug(similarMovie.Title);
  navigate(`/explore/${similarMovie.TMDBID}-${slug}`);
  setSelectedMovie(similarMovie);
};

const handleMovieSelect = (movie) => {
  const slug = createSlug(movie.Title);
  navigate(`/explore/${movie.TMDBID}-${slug}`);
  setSelectedMovie(movie);
};

  // Toggle Streaming Dropdown
  const toggleStreamingDropdown = () => {
    setShowStreamingDropdown((prev) => {
      if (prev) {
        // If the dropdown is currently shown (prev is true), it will now be closed
      } else {
        // If the dropdown is currently hidden (prev is false), it will now be opened
        // Close other dropdowns when opening this one
        setShowYearDropdown(false);
        setShowGenreDropdown(false);
        setShowRatedUnratedDropdown(false);
        setShowLangDropdown(false);
      }
      return !prev;
    });
  };

  // Initial fetch of user filters
  useEffect(() => {
    const fetchFilters = async () => {
      if (isAuthenticated && user) {
        try {
          const response = await axios.get(`${apiBaseUrl}/api/user/filters`, {
            params: { userId: user.sub },
          });
          const {
            streamingServices,
            selectedYears,
            selectedGenres,
            showRated,
            selectedLangs,
          } = response.data;
          setSelectedStreamingServices(new Set(streamingServices || []));
          setSelectedYears(new Set(selectedYears || []));
          setSelectedLangs(new Set(selectedLangs || []));
          setSelectedGenres(new Set(selectedGenres || []));
          setShowRated(showRated || "all");
        } catch (error) {
          console.error("Error loading filters:", error);
        }
      }
    };

    fetchFilters();
  }, [user, isAuthenticated, getAccessTokenSilently]); // Removed from dependencies to prevent re-fetching

  // Save filter state whenever they change
  useEffect(() => {
    if (userMadeChanges) {
      updateFiltersInDb();
      setUserMadeChanges(false); // Reset the flag after updating
    }
  }, [
    selectedYears,
    selectedLangs,
    selectedStreamingServices,
    selectedGenres,
    showRated,
    userMadeChanges,
  ]); // Include userMadeChanges in the dependency array

  const updateFiltersInDb = async () => {
    if (isAuthenticated && user && userMadeChanges) {
      // AThis check might be redundant now
      try {
        await axios.post(`${apiBaseUrl}/api/user/updateFilters`, {
          userId: user.sub,
          streamingServices: Array.from(selectedStreamingServices),
          selectedYears: Array.from(selectedYears),
          selectedLangs: Array.from(selectedLangs),
          selectedGenres: Array.from(selectedGenres),
          showRated: showRated,
        });
      } catch (error) {
        console.error("Error updating filters:", error);
      }
    }
  };

  const clearAllFilters = () => {
    setFiltersApplied(!filtersApplied);
  };

  const serviceCounts = {
    Buy: 429,
    Max: 167,
    "Disney+": 83,
    Paramount: 70,
    Netflix: 95,
    Prime: 103,
    Hulu: 52,
    Peacock: 18,
    Freevee: 57,
    Pluto: 26,
    AMC: 46,
    STARZ: 28,
    Cinemax: 21,
    MGM: 26,
    FXNOW: 23,
    Apple: 16,
    TBS: 10,
    The: 9,
    Bravo: 1,
    ABC: 4,
    Tribeca: 14,
    Tubi: 45,
    MUBI: 7,
    Magnolia: 9,
    Plex: 8,
    Crackle: 2,
    Crunchyroll: 6,
    Freeform: 1,
    Shout: 2,
    Sundance: 1,
    Dox: 4,
  };

  const serviceUrls = {
    Buy: "https://www.amazon.com",
    Max: "https://www.hbomax.com/",
    "Disney+": "https://www.disneyplus.com/",
    Paramount: "https://www.paramountplus.com/",
    Netflix: "https://www.netflix.com/",
    Prime: "https://www.primevideo.com/",
    Hulu: "https://www.hulu.com/",
    Peacock: "https://www.peacocktv.com/",
    Freevee: "https://www.amazon.com/adlp/freevee",
    Pluto: "https://pluto.tv/",
    AMC: "https://www.amc.com/",
    STARZ: "https://www.starz.com/us/en/",
    Cinemax: "https://www.cinemax.com/",
    MGM: "https://www.mgm.com/",
    FXNOW: "https://www.fxnetworks.com/fxnow",
    Apple: "https://tv.apple.com/",
    TBS: "https://www.tbs.com/",
    The: "https://www.theplatform.com/",
    Bravo: "https://www.bravotv.com/",
    ABC: "https://abc.com/",
    Tribeca: "https://www.tribecafilm.com/",
    Tubi: "https://tubitv.com/",
    MUBI: "https://mubi.com/",
    Magnolia: "https://www.magnoliaselects.com/",
    Plex: "https://www.plex.tv/",
    Crackle: "https://www.crackle.com/",
    Crunchyroll: "https://www.crunchyroll.com/",
    Freeform: "https://www.freeform.com/",
    Shout: "https://www.shoutfactorytv.com/",
    Sundance: "https://www.sundance.org/",
    Dox: "https://www.dox.com/",
  };

  const seenCounts = {
    Unseen: unseenMoviesCount,
    Rated: ratedMoviesCount,
    Unrated: unratedMoviesCount,
    Seen: seenMoviesCount,
  };

  const seenList = Object.entries(seenCounts)
    .sort((a, b) => b[1] - a[1])
    .map((service) => service[0]);

  // const serviceCounts = {
  //   "Buy": 339,
  //   "Max": 118,
  //   "Disney": 75,
  //   "Netflix": 69,
  //   "Prime": 65,
  //   "Paramount": 48,
  //   "AMC": 34,
  //   "Freevee": 32,
  //   "MGM": 27,
  //   "Hulu": 24,
  //   "Peacock": 21,
  //   "Cinemax": 21,
  //   "STARZ": 19,
  //   "Pluto": 19,
  //   "Showtime": 19,
  //   "FXNOW": 18,
  //   "Roku": 6,
  //   "Bravo": 5,
  //   "Tribeca": 5,
  //   "Plex": 5,
  //   "Tubi": 5,
  //   "ABC": 4,
  //   "Magnolia": 4,
  //   "MUBI": 4,
  //   "Crackle": 3,
  //   "Freeform": 3,
  //   "Crunchyroll": 2,
  //   "TBS": 2,
  //   "Sundance": 1,
  //   "Apple": 1,
  //   "Shout": 1,
  //   "Dox": 1
  // };

  const streamingServices = Object.entries(serviceCounts)
    .sort((a, b) => b[1] - a[1])
    .map((service) => service[0]);

  const genreCount = {
    Action: 226,
    Drama: 214,
    Comedy: 154,
    Animation: 103,
    Crime: 100,
    Biography: 91,
    Adventure: 66,
    Horror: 31,
    Mystery: 6,
    Documentary: 3,
    Fantasy: 3,
    "Film-Noir": 1,
    Western: 1,
    Family: 1,
  };

  // const genreCount = {
  //   "Action": 226,
  //   "Drama": 214,
  //   "Comedy": 154,
  //   "Animation": 103,
  //   "Crime": 100,
  //   "Biography": 91,
  //   "Adventure": 66,
  //   "Horror": 31,
  //   "Mystery": 6,
  //   "Fantasy": 3,
  //   "Documentary": 3,
  //   "Western": 1,
  //   "Film-Noir": 1,
  //   "Family": 1
  // };

  const genreList = Object.entries(genreCount)
    .sort((a, b) => b[1] - a[1])
    .map((service) => service[0]);

  const langCount = {
    English: 937,
    French: 168,
    Spanish: 153,
    German: 102,
    Russian: 97,
    Italian: 79,
    Japanese: 60,
    Latin: 49,
    Arabic: 37,
    Mandarin: 25,
    Ukrainian: 25,
    Cantonese: 22,
    Korean: 19,
    Swedish: 19,
    Hindi: 15,
    Hebrew: 14,
    Greek: 12,
    Portuguese: 11,
    "American Sign Language": 11,
    Persian: 10,
    Turkish: 10,
    Chinese: 10,
    Polish: 9,
    Hungarian: 8,
    Vietnamese: 7,
    Thai: 7,
    Norwegian: 7,
    Danish: 6,
    Urdu: 6,
    Czech: 6,
    Xhosa: 6,
    Romanian: 6,
    Yiddish: 6,
    Swahili: 6,
    "Sign Languages": 4,
    Dutch: 4,
    Afrikaans: 4,
    Indonesian: 4,
    "Irish Gaelic": 4,
    Shanghainese: 3,
    Somali: 3,
    "North American Indian": 3,
    Hawaiian: 3,
    Sindarin: 3,
    "Old English": 3,
    Zulu: 3,
    Serbian: 3,
    Tibetan: 2,
    Esperanto: 2,
    Klingon: 2,
    Sotho: 2,
    Pawnee: 2,
    "Greek, Ancient (to 1453)": 2,
    Armenian: 2,
    Tamil: 2,
    Nepali: 2,
    Kurdish: 2,
    Telugu: 2,
    Tagalog: 2,
    Gaelic: 2,
    Malayalam: 2,
    Kannada: 2,
    Bulgarian: 2,
    Bengali: 2,
    Quechua: 2,
    Punjabi: 2,
    Pashtu: 2,
    Flemish: 1,
    "Kalmyk-Oirat": 1,
    Lao: 1,
    Hokkien: 1,
    Albanian: 1,
    Sioux: 1,
    Yoruba: 1,
    Mende: 1,
    Nama: 1,
    Haryanvi: 1,
    Akan: 1,
    "Tok Pisin": 1,
    Greenlandic: 1,
    Mohawk: 1,
    None: 1,
    Amharic: 1,
    Kikuyu: 1,
    Latvian: 1,
    Hmong: 1,
    Icelandic: 1,
    Maya: 1,
    Oriya: 1,
    Romany: 1,
    "British Sign Language": 1,
    Georgian: 1,
    Saami: 1,
    Malay: 1,
    Assamese: 1,
    Mongolian: 1,
    Sicilian: 1,
    Maori: 1,
    Nyanja: 1,
    Rajasthani: 1,
    Marathi: 1,
    "Brazilian Sign Language": 1,
    Mapudungun: 1,
    Estonian: 1,
    Finnish: 1,
    "Central Khmer": 1,
    Mixtec: 1,
    Inuktitut: 1,
    Filipino: 1,
    Corsican: 1,
    "Norse, Old": 1,
    "Japanese Sign Language": 1,
    Scots: 1,
    Quenya: 1,
    "Berber languages": 1,
    Navajo: 1,
    Kinyarwanda: 1,
    Catalan: 1,
  };

  const langList = Object.entries(langCount)
    .sort((a, b) => b[1] - a[1])
    .map((service) => service[0]);

  const decades = [
    "2020s",
    "2010s",
    "2000s",
    "1990s",
    "1980s",
    "1970s",
    "1960s",
    "1950s",
    "1940s",
    "1930s",
  ];

  const decadeCounts = {
    "2020s": 56,
    "2010s": 336,
    "2000s": 260,
    "1990s": 135,
    "1980s": 83,
    "1970s": 45,
    "1960s": 36,
    "1950s": 27,
    "1940s": 14,
    "1930s": 7,
  };

  const toggleYearFilter = (year) => {
    if (year === "All") {
      setSelectedYears(new Set(decades));
    } else if (year === "Clear") {
      setSelectedYears(new Set());
    } else {
      setSelectedYears((prevYears) => {
        const newYears = new Set(prevYears);
        if (newYears.has(year)) {
          newYears.delete(year);
        } else {
          newYears.add(year);
        }
        return newYears;
      });
    }
    setUserMadeChanges(true);
  };

  const toggleLangFilter = (lang) => {
    if (lang === "All") {
      setSelectedLangs(new Set(langList.map((l) => l.toLowerCase())));
    } else if (lang === "Clear") {
      setSelectedLangs(new Set());
    } else {
      setSelectedLangs((prevLangs) => {
        const newLangs = new Set(prevLangs);
        if (newLangs.has(lang)) {
          newLangs.delete(lang);
        } else {
          newLangs.add(lang);
        }
        return newLangs;
      });
    }
    setUserMadeChanges(true);
  };

  const toggleStreamingServiceFilter = (service) => {
    if (service === "All") {
      setSelectedStreamingServices(
        new Set(streamingServices.map((s) => s.toLowerCase()))
      );
    } else if (service === "Clear") {
      setSelectedStreamingServices(new Set());
    } else {
      setSelectedStreamingServices((prevServices) => {
        const newServices = new Set(prevServices);
        if (newServices.has(service.toLowerCase())) {
          newServices.delete(service.toLowerCase());
        } else {
          newServices.add(service.toLowerCase());
        }
        return newServices;
      });
    }
    setUserMadeChanges(true);
  };

  // Toggle Genre Filter with 'All' and 'Clear' functionality
  const toggleGenreFilter = (genre) => {
    if (genre === "All") {
      setSelectedGenres(new Set(genreList.map((g) => g.toLowerCase())));
    } else if (genre === "Clear") {
      setSelectedGenres(new Set());
    } else {
      setSelectedGenres((prevGenres) => {
        const newGenres = new Set(prevGenres);
        if (newGenres.has(genre.toLowerCase())) {
          newGenres.delete(genre.toLowerCase());
        } else {
          newGenres.add(genre.toLowerCase());
        }
        return newGenres;
      });
    }
    setUserMadeChanges(true);
  };

  const toggleRatedUnrated = (option) => {
    if (option === "Clear") {
      setShowRated("all");
    } else {
      setShowRated(option.toLowerCase()); // Ensuring consistency in state values
    }
    setUserMadeChanges(true);
  };

  // const handleSimilarMovieSelect = (similarMovieId) => {
  //   const newSelectedMovie = movies.find(movie => movie._id === similarMovieId);
  //   if (newSelectedMovie) {
  //     setSelectedMovie(newSelectedMovie);
  //   }

  //   const elementTop = document.querySelector('.h-100');
  //   if (elementTop) {
  //     // elementTop.scrollIntoView({ behavior: 'smooth', block: 'start' });
  //     elementTop.scrollIntoView({ behavior: 'smooth', block: 'start' });
  //   }
  // };

  const decodeDecade = (decade) => {
    const startYear = parseInt(decade.substring(0, 4), 10);
    const endYear = startYear + 9;
    return [startYear, endYear];
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        yearDropdownRef.current &&
        !yearDropdownRef.current.contains(event.target)
      ) {
        setShowYearDropdown(false);
      }
      if (
        langDropdownRef.current &&
        !langDropdownRef.current.contains(event.target)
      ) {
        setShowLangDropdown(false);
      }
      if (
        streamingDropdownRef.current &&
        !streamingDropdownRef.current.contains(event.target)
      ) {
        setShowStreamingDropdown(false);
      }
      if (
        seenDropdownRef.current &&
        !seenDropdownRef.current.contains(event.target)
      ) {
        setShowRatedUnratedDropdown(false);
      }
      if (
        genreDropdownRef.current &&
        !genreDropdownRef.current.contains(event.target)
      ) {
        setShowGenreDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const buttonRef = useRef(null);
  const dropdownMenuRef = useRef(null);

  const isYearFilterActive = () => {
    return selectedYears.size > 0;
  };

  const isLangFilterActive = () => {
    return selectedLangs.size > 0;
  };

  const isStreamingFilterActive = () => {
    return selectedStreamingServices.size > 0;
  };

  const isGenreFilterActive = () => {
    return selectedGenres.size > 0;
  };

  const isRatedUnratedFilterActive = () => {
    return showRated !== "all"; // Assuming 'all' means no filter is applied
  };

  // const streamingServices = ["netflix", "max", "prime", "paramount", "hulu"];

  const allMovies = [
    // Assuming this array is populated with movie objects
    // Each movie object has properties like 'releaseDate', 'rating', 'genre', etc.
  ];

  // Correcting filterCommonCriteria to use movie.Const and ensuring streaming service check is correct
  const filterCommonCriteria = (movie) => {
    // Movie Curator score must be 80 or higher
    // if (movie.CuratorScore < 70) {
    //   return false;
    // }
    return true;
  };

  // This remains correct for checking unrated movies
  const filterUnratedMovies = (movie) => {
    const hasUserScore = userScores.hasOwnProperty(movie.Const);
    return !hasUserScore;
  };

  const adjustDropdownWidth = () => {
    if (divRef.current && dropdownMenuRef.current) {
      const divWidth = divRef.current.offsetWidth;
      const dropdownMenus = document.querySelectorAll(".dropdown-menu");

      dropdownMenus.forEach((menu) => {
        menu.style.width = `${divWidth}px`;
        menu.style.left = `${divRef.current.getBoundingClientRect().left}px`;
        menu.style.backgroundColor = "red";
      });
    }
  };

  // Utility function to capitalize the first letter of a string
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  // Assuming selectedLangs is a Set of selected language texts, all in lowercase
  const filteredMovies = filtersApplied
    ? movies.filter((movie) => {
        const matchesSearch =
          searchQuery === "" ||
          movie.Title.toLowerCase().includes(searchQuery.toLowerCase()) ||
          movie.Genre.toLowerCase().includes(searchQuery.toLowerCase()) ||
          movie.spokenLanguages?.spokenLanguages?.some((lang) =>
            lang.text.includes(searchQuery)
          ) ||
          movie.Streaming.toLowerCase().includes(searchQuery.toLowerCase()) ||
          movie.Year.toString().includes(searchQuery);

        const matchesYearFilter =
          selectedYears.size === 0 ||
          [...selectedYears].some((decade) => {
            const [startYear, endYear] = decodeDecade(decade);
            return movie.Year >= startYear && movie.Year <= endYear;
          });

        const matchesStreamingFilter =
          selectedStreamingServices.size === 0 ||
          selectedStreamingServices.has(movie.Streaming.toLowerCase());

        // Correct implementation for language filter
        const matchesLangFilter =
          selectedLangs.size === 0 ||
          movie.spokenLanguages?.spokenLanguages?.some((lang) => {
            const match = selectedLangs.has(lang.text);
            return match;
          });

        const matchesGenreFilter =
          selectedGenres.size === 0 ||
          selectedGenres.has(movie.Genre.split(", ")[0].toLowerCase());

        const isMovieSeen = seenMovies.has(movie.Const);
        const matchesRatedFilter =
          showRated === "all" ||
          (showRated === "rated" && userScores.hasOwnProperty(movie.Const)) ||
          (showRated === "unrated" &&
            !userScores.hasOwnProperty(movie.Const)) ||
          (showRated === "seen" && isMovieSeen) ||
          (showRated === "unseen" && !isMovieSeen);

        const matchesCuratorScore = movie.CuratorScore >= 70;

        return (
          matchesSearch &&
          matchesCuratorScore &&
          matchesYearFilter &&
          matchesLangFilter &&
          matchesStreamingFilter &&
          matchesGenreFilter &&
          matchesRatedFilter
        );
      })
    : movies;

  const seenFilteredMovies = filtersApplied
    ? movies.filter((movie) => {
        const matchesSearch =
          searchQuery === "" ||
          movie.Title.toLowerCase().includes(searchQuery.toLowerCase()) ||
          movie.Genre.toLowerCase().includes(searchQuery.toLowerCase()) ||
          movie.spokenLanguages?.spokenLanguages?.some((lang) =>
            lang.text.includes(searchQuery)
          ) ||
          movie.Streaming.toLowerCase().includes(searchQuery.toLowerCase()) ||
          movie.Year.toString().includes(searchQuery);

        const matchesYearFilter =
          selectedYears.size === 0 ||
          [...selectedYears].some((decade) => {
            const [startYear, endYear] = decodeDecade(decade);
            return movie.Year >= startYear && movie.Year <= endYear;
          });

        const matchesStreamingFilter =
          selectedStreamingServices.size === 0 ||
          selectedStreamingServices.has(movie.Streaming.toLowerCase());

        // Correct implementation for language filter
        const matchesLangFilter =
          selectedLangs.size === 0 ||
          movie.spokenLanguages?.spokenLanguages?.some((lang) => {
            const match = selectedLangs.has(lang.text);
            return match;
          });

        const matchesGenreFilter =
          selectedGenres.size === 0 ||
          selectedGenres.has(movie.Genre.split(", ")[0].toLowerCase());

        const isMovieSeen = seenMovies.has(movie.Const);

        const matchesCuratorScore = movie.CuratorScore >= 70;

        return (
          matchesSearch &&
          matchesYearFilter &&
          matchesLangFilter &&
          matchesStreamingFilter &&
          matchesGenreFilter &&
          isMovieSeen
        );
      })
    : movies;

  // Log the selected languages and how many total movies have each language
  selectedLangs.forEach((selectedLang) => {
    const count = filteredMovies.filter((movie) =>
      movie.spokenLanguages?.spokenLanguages?.some(
        (lang) => lang.text.toLowerCase() === selectedLang
      )
    ).length;
    // console.log(`Language Selected: ${selectedLang}, Total Movies: ${count}`);
  });

  const [setFilteredMovies] = useState([]);

  useEffect(() => {
    // Call adjustDropdownWidth when dropdown is open and divRef is available
    if (isDropdownOpen && divRef.current) {
      adjustDropdownWidth();
    }
  }, [isDropdownOpen]);

  useEffect(() => {
    // const fetchMovies = async () => {
    //   try {
    //     // Fetch general list of movies
    //     const moviesResponse = await axios.get(`${apiBaseUrl}/api/movies`);
    //     let fetchedMovies = moviesResponse.data;
    //     fetchedMovies.sort((a, b) => b.CuratorScore - a.CuratorScore);
    //     setMovies(fetchedMovies);
    //   } catch (error) {
    //     console.error('Error fetching movies data:', error);
    //   }
    // };

    const fetchContent = async () => {
      try {
        const response = await axios.get(
          `${apiBaseUrl}/api/content?type=${contentType}`
        );
        const fetchedContent = response.data;
        fetchedContent.sort((a, b) => b.CuratorScore - a.CuratorScore);
        setMovies(fetchedContent);
        setIsMoviesLoading(false);
      } catch (error) {
        console.error("Error fetching content data:", error);
        setIsMoviesLoading(false);
      }
    };

    const fetchUserSpecificData = async () => {
      if (!isAuthenticated || !user || !user.sub) {
        return;
      }
      try {
        // const accessToken = await getAccessTokenSilently();
        // Proceed with fetching seen movies and user scores using the obtained access token
        const seenMoviesResponse = await axios.get(
          `${apiBaseUrl}/api/user/seenMovies`,
          {
            headers: {
              // 'Authorization': `Bearer ${accessToken}`,
              "user-id": user.sub,
            },
          }
        );

        const watchlistMoviesResponse = await axios.get(
          `${apiBaseUrl}/api/user/watchlistMovies`,
          {
            headers: {
              // 'Authorization': `Bearer ${accessToken}`,
              "user-id": user.sub,
            },
          }
        );

        const processedWatchlistMovies = new Set();
        // Process seenMovies and userScores from the response
        const processedSeenMovies = new Set();
        const processedUserScores = {};

        seenMoviesResponse.data.forEach((movie) => {
          processedSeenMovies.add(movie.Const);
          if (movie.userScore) {
            processedUserScores[movie.Const] = movie.userScore;
          }
        });

        watchlistMoviesResponse.data.forEach((movie) => {
          processedWatchlistMovies.add(movie.Const);
        });

        setWatchlistMovies(processedWatchlistMovies);

        setSeenMovies(processedSeenMovies);
        setUserScores(processedUserScores);
      } catch (error) {
        console.error("Error fetching user specific data:", error);
        // Check for token-related errors
        if (
          error.error === "login_required" ||
          error.error === "consent_required" ||
          error.error === "invalid_token"
        ) {
          // This is a simplistic approach. Depending on your UX, you may choose to show an error message,
          // redirect to a login page, or use a modal to inform the user.
          console.log("Session expired. Redirecting to login...");
          loginWithRedirect();
        }
      }
    };

    // Fetch the general list of movies
    // fetchMovies();
    fetchContent();

    // Fetch user-specific data if the user is authenticated
    fetchUserSpecificData();
  }, [
    user,
    isAuthenticated,
    getAccessTokenSilently,
    loginWithRedirect,
    contentType,
  ]);

  const handleScoreChange = async (constId, newScore) => {
    try {
      const parsedScore = parseInt(newScore, 10);
      if (isNaN(parsedScore)) {
        console.error("Error: The score is not a valid number.");
        alert("Please enter a valid number for the score.");
        return;
      }

      console.log(`Submitting score: ${parsedScore} for movie ID: ${constId}`); // Log for debugging

      const token = await getAccessTokenSilently();
      const response = await axios.post(
        `${apiBaseUrl}/api/user/rateMovie`,
        {
          userId: user.sub,
          Const: constId,
          score: parsedScore,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setUserScores((prevUserScores) => ({
          ...prevUserScores,
          [constId]: parsedScore,
        }));

        if (!seenMovies.has(constId)) {
          setSeenMovies(
            (prevSeenMovies) => new Set([...prevSeenMovies, constId])
          );
        }
      }
    } catch (error) {
      console.error(
        "Error rating movie:",
        error.response ? error.response.data : error.message
      );
    }
  };


  useEffect(() => {
    // Assuming the fetch to /movie_clusters.json is done here and is successful
    fetch("/movie_clusters.json")
      .then((response) => response.json())
      .then((clusterData) => {
        // Assume movies, seenMovies, and userScores are already set at this point

        const shelves = clusterData.map((cluster) => {
          const clusterMovies = movies.filter((movie) =>
            cluster.Const.includes(movie.Const)
          );
          const ratedMovies = clusterMovies.filter((movie) =>
            userScores.hasOwnProperty(movie.Const)
          );
          const ratedMoviesCount = ratedMovies.length;
          const averageScore =
            ratedMovies.reduce(
              (acc, movie) => acc + (userScores[movie.Const] || 0),
              0
            ) / ratedMoviesCount || 0;

          return {
            key: cluster.listId,
            title: cluster.title,
            subtitle: `Rated Movies: ${ratedMoviesCount}/${
              clusterMovies.length
            }, Avg Score: ${averageScore.toFixed(1)}`,
            movies: clusterMovies, // Keep the original list to apply filters later
            scoreType: "curator",
            // Additional metrics for sorting
            ratedMoviesCount,
            averageScore,
          };
        });

        shelves.sort((a, b) => {
          if (b.ratedMoviesCount !== a.ratedMoviesCount) {
            return b.ratedMoviesCount - a.ratedMoviesCount;
          }
          return b.averageScore - a.averageScore;
        });

        setClusterShelves(shelves);
      })
      .catch((error) => console.error("Failed to load movie clusters:", error));
  }, [movies, userScores, seenMovies]); // Rerun if any of these dependencies change

  const handleAddAndRateMovie = async (movieConst, movieId) => {
    if (!isAuthenticated) {
      alert("Please log in to rate movies."); // Replace this with a UI-based prompt when you're ready.
      return; // Exit the function early.
    }

    // Continue with your logic for adding and rating a movie.
    const newScore = prompt("Enter your score (1-99):");
    if (newScore && newScore >= 1 && newScore <= 99) {
      if (!seenMovies.has(movieConst)) {
        await addMovieToSeenList(movieId);
      }
      await handleScoreChange(movieConst, newScore);
    }
  };

  const addMovieToSeenList = async (movieId) => {
    try {
      const token = await getAccessTokenSilently();
      const movie = movies.find((m) => m._id === movieId);
      if (!movie) {
        console.error("Movie not found");
        return;
      }

      if (seenMovies.has(movie.Const)) {
        // Remove movie from seen list
        await removeMovieFromSeenList(movie.Const);
      } else {
        await axios.post(
          `${apiBaseUrl}/api/user/addMovie`,
          {
            userId: user.sub,
            name: user.name,
            movie: { Const: movie.Const, Title: movie.Title },
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // Update the seenMovies state to include the new movie
        setSeenMovies(new Set([...seenMovies, movie.Const]));
      }
    } catch (error) {
      console.error("Error adding movie to seen list:", error);
    }
  };

  const removeMovieFromSeenList = async (movieConst) => {
    try {
      const token = await getAccessTokenSilently();
      await axios.post(
        `${apiBaseUrl}/api/user/removeMovie`,
        {
          userId: user.sub,
          movieConst: movieConst, // Send the Const value
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Update local seenMovies state
      const newSeenMovies = new Set(seenMovies);
      newSeenMovies.delete(movieConst);
      setSeenMovies(newSeenMovies);
    } catch (error) {
      console.error("Error removing movie from seen list:", error);
    }
  };

  //const averageScore = movies.reduce((acc, movie) => acc + (movie.CuratorScore || 0), 0) / movies.length;

  // Corrected filter for Your Favorites (Rated) using the provided filter logic
  // Your Favorites (Rated)
  // First, extract Const values from user.seenMovies
  const seenMovieConsts =
    user && user.seenMovies ? user.seenMovies.map((movie) => movie.Const) : [];

  // Then, use these Const values for filtering
  // Adjusted filtering for Your Favorites (Rated)
  // const yourFavoritesRated = seenFilteredMovies.filter(movie =>
  //   seenMovies.has(movie.Const) && userScores.hasOwnProperty(movie.Const))
  //   .sort((a, b) => userScores[b.Const] - userScores[a.Const]);

  const yourFavoritesRated = seenFilteredMovies
    .filter((movie) => seenMovies.has(movie.Const))
    .sort((a, b) => {
      // Check if userScores for both movies exist
      const scoreA = userScores.hasOwnProperty(a.Const)
        ? userScores[a.Const]
        : a.CuratorScore;
      const scoreB = userScores.hasOwnProperty(b.Const)
        ? userScores[b.Const]
        : b.CuratorScore;

      // Sort by the determined score, descending
      return scoreB - scoreA;
    });

  // Top Curator Picks
  const topCuratorPicks = filteredMovies
    .filter(filterCommonCriteria)
    .sort((a, b) => b.CuratorScore - a.CuratorScore);

  // Top IMDb Movies
  const topImdbMovies = filteredMovies
    .filter(filterCommonCriteria)
    .sort((a, b) => b.User - a.User);

  // Top Metacritic Movies
  const topMetacriticMovies = filteredMovies
    .filter(filterCommonCriteria)
    .sort((a, b) => b.Critic - a.Critic);

  // New Releases (2020’s)
  const newReleases2020s = filteredMovies
    .filter((movie) => movie.Year >= 2020 && filterCommonCriteria(movie))
    .sort((a, b) => b.CuratorScore - a.CuratorScore);

  // New Oscar Winners
  const newOscarWinners = filteredMovies
    .filter((movie) => movie["Won Best Picture"] && filterCommonCriteria(movie))
    .sort((a, b) => b.Year - a.Year);

  // New Oscar Nominees
  const newOscarNominees = filteredMovies
    .filter(
      (movie) =>
        movie["Nominated for Best Picture"] && filterCommonCriteria(movie)
    )
    .sort((a, b) => b.Year - a.Year);

  const diamond = filteredMovies
    .filter(
      (movie) =>
        movie["Nominated for Best Picture"] && filterCommonCriteria(movie)
    )
    .sort((a, b) => b.Year - a.Year);

  // Compiling Group 1 shelves with titles and subtitles
  const group1Shelves = [
    // ...(isAuthenticated ? [{ key: 'yourFavoritesRated', title: 'Your Favorites', subtitle: 'Sorted by Your Score', movies: yourFavoritesRated }] : []),
    ...clusterShelves,
    // { key: 'topImdbMovies', title: 'Top IMDb Movies', subtitle: 'Sorted by IMDb Rating', movies: topImdbMovies, scoreType: 'imdb', },
    // { key: 'topMetacriticMovies', title: 'Top Metacritic Movies', subtitle: 'Sorted by Metacritic Score', movies: topMetacriticMovies, scoreType: 'metacritic', },
    // { key: 'newReleases2020s', title: 'Top New Movies', subtitle: '2020 - Present', movies: newReleases2020s },
    // { key: 'newOscarWinners', title: 'Oscar Winners', subtitle: 'Sorted by Year', movies: newOscarWinners },
    // { key: 'newOscarNominees', title: 'Oscar Nominees', subtitle: 'Sorted by Year', movies: newOscarNominees },
  ];

  // Top Drama
  const topDrama = filteredMovies
    .filter(
      (movie) =>
        movie.Genre.toLowerCase().includes("drama") &&
        filterCommonCriteria(movie)
    )
    .sort((a, b) => b.CuratorScore - a.CuratorScore);

  // Top Comedy
  const topComedy = filteredMovies
    .filter(
      (movie) =>
        movie.Genre.toLowerCase().includes("comedy") &&
        filterCommonCriteria(movie)
    )
    .sort((a, b) => b.CuratorScore - a.CuratorScore);

  // Top Animated
  const topAnimated = filteredMovies
    .filter(
      (movie) =>
        movie.Genre.toLowerCase().includes("animation") &&
        filterCommonCriteria(movie)
    )
    .sort((a, b) => b.CuratorScore - a.CuratorScore);

  // Most Popular (assuming 'Popularity' or a similar metric is available in your data)
  // If not, you can sort by Curator Score as a proxy for popularity here
  const mostPopular = filteredMovies
    .filter(filterCommonCriteria)
    .sort((a, b) => b.Rarity - a.Rarity);

  // Hidden Gems: High Curator Score (>80) and low Rarity (<200k)
  const hiddenGems = filteredMovies
    .filter(
      (movie) =>
        movie.CuratorScore > 80 &&
        movie.Rarity < 200000 &&
        filterCommonCriteria(movie)
    )
    .sort((a, b) => b.CuratorScore - a.CuratorScore);

  const ratedHiddenGemsCount = hiddenGems.filter((movie) =>
    userScores.hasOwnProperty(movie.Const)
  ).length;

  // Compiling Group 2 shelves
  const group2Shelves = [
    // { key: 'hiddenGems', title: 'Hidden Gems', movies: hiddenGems },
    // { key: 'mostPopular', title: 'Most Popular', subtitle: 'Sorted by Most IMDb Ratings', movies: mostPopular, scoreType: 'rating', },
    // { key: 'topDrama', title: 'Drama', movies: topDrama },
    // { key: 'topComedy', title: 'Comedy', movies: topComedy },
    // { key: 'topAnimated', title: 'Animation', movies: topAnimated },
  ];

  // Assuming filteredMovies is an array of movie objects already filtered by common criteria
  // Assuming filteredMovies is already filtered by common criteria and userScores contains rated movies' Consts
  const countRatedMovies = (shelf) =>
    shelf.filter((movie) => userScores.hasOwnProperty(movie.Const)).length;

  // Filter out movies rated by the user from each shelf
  const filterUnratedMovies3 = (shelf) =>
    shelf.filter((movie) => !userScores.hasOwnProperty(movie.Const));

  const yourWatchlist = movies
    .filter((movie) => watchlistMovies.has(movie.Const))
    .sort((a, b) => {
      // Check if userScores for both movies exist
      const scoreA = userScores.hasOwnProperty(a.Const)
        ? userScores[a.Const]
        : a.CuratorScore;
      const scoreB = userScores.hasOwnProperty(b.Const)
        ? userScores[b.Const]
        : b.CuratorScore;

      // Sort by the determined score, descending
      return scoreB - scoreA;
    });

  // Update curatorMedalShelves with the new filtered shelves
  const curatorMedalShelves = [
    {
      key: "topCuratorPicks",
      title: contentType === "movies" ? "Top Movies" : "Top TV Series",
      movies: topCuratorPicks,
      scoreType: "user",
    },
    // IMPORTANT: keep "My Watchlist" or change later logic
    {
      key: "yourWatchlist",
      title: "My Watchlist",
      subtitle: "none",
      movies: yourWatchlist,
    },
    ...(isAuthenticated
      ? [
          {
            key: "yourFavoritesRated",
            title: "Seen Movies",
            subtitle: "none",
            movies: yourFavoritesRated,
          },
        ]
      : []),
  ];

  // This structure can now be used to render shelves in your UI, each shelf with its movies sorted by Rank within their category.
  // Assuming group1Shelves and group2Shelves are your shelf groups
  // const allShelves = [...group1Shelves, ...group2Shelves];
  const allShelves = [...clusterShelves];
  const curatorShelf = [...curatorMedalShelves];

  // Filter shelves to only include those with 3 or more movies
  // const shelvesToShow = allShelves.filter(({ movies }) => movies.length >= 3);

  const calculateShelfDetails = (movies) => {
    const totalMovies = movies.length;
    // Adjust to count seen movies instead of rated movies
    const seenMoviesCount = movies.filter((movie) =>
      seenMovies.has(movie.Const)
    ).length;
    const seenPercentage = (seenMoviesCount / totalMovies) * 100; // Calculate the percentage of seen movies

    // Assuming you still want to calculate the average score for seen and rated movies
    const seenAndRatedMovies = movies.filter(
      (movie) =>
        seenMovies.has(movie.Const) && userScores.hasOwnProperty(movie.Const)
    );
    const averageScore =
      seenAndRatedMovies.reduce(
        (acc, movie) => acc + userScores[movie.Const],
        0
      ) / seenAndRatedMovies.length || 0;

    return {
      seenPercentage,
      seenMoviesCount,
      totalMovies,
      averageScore, // Average score among seen and rated movies
    };
  };

  const calculateCuratorShelfDetails = () => {
    const totalCuratorMovies = movies.length; // Assuming 'movies' contains all movies in the curator's selection
    const seenMoviesCount = movies.filter((movie) =>
      seenMovies.has(movie.Const)
    ).length;
    const seenPercentage = (seenMoviesCount / totalCuratorMovies) * 100;

    // Calculation for average score remains the same if you want to include it
    const seenAndRatedMovies = movies.filter(
      (movie) =>
        seenMovies.has(movie.Const) && userScores.hasOwnProperty(movie.Const)
    );
    const averageScore =
      seenAndRatedMovies.reduce(
        (acc, movie) => acc + userScores[movie.Const],
        0
      ) / seenAndRatedMovies.length || 0;

    // Return calculated details
    return {
      seenPercentage,
      seenMoviesCount,
      totalMovies: totalCuratorMovies,
      averageScore,
    };
  };

  const shelvesWithDetails = allShelves.map((shelf) => {
    const { seenPercentage, seenMoviesCount, totalMovies, averageScore } =
      calculateShelfDetails(shelf.movies);
    const subtitle = isAuthenticated
      ? `Seen ${seenMoviesCount} / ${totalMovies} (${seenPercentage.toFixed(
          0
        )}%)`
      : "";

    return {
      ...shelf,
      seenPercentage, // This might be used for sorting purposes
      seenMoviesCount,
      subtitle, // Updated subtitle to reflect seen movies
    };
  });

  const curatorShelvesWithDetails = curatorShelf.map((shelf) => {
    const { seenPercentage, seenMoviesCount, totalMovies, averageScore } =
      calculateCuratorShelfDetails(shelf.movies);
    // const subtitle = isAuthenticated ? `Rated ${((1000-unratedMoviesCount)/10).toFixed(0)}% (${1000-unratedMoviesCount} / 1000)` : "";
    const subtitle = isAuthenticated
      ? `Seen ${seenMoviesCount} / ${totalMovies} (${seenPercentage.toFixed(
          0
        )}%)`
      : "";

    return {
      ...shelf,
      seenPercentage, // This is for sorting purposes
      seenMoviesCount,
      subtitle, // Updated subtitle
    };
  });

  // Filtering shelves after calculating details
  const filteredShelvesToShow = shelvesWithDetails.filter(
    (shelf) => shelf.movies.length >= 3
  );

  // Sort the shelves based on the rated percentage from highest to lowest
  const sortedShelvesCurator = curatorShelvesWithDetails.sort(
    (a, b) => b.seenMoviesCount - a.seenMoviesCount
  );
  const sortedShelves = filteredShelvesToShow.sort(
    (a, b) => b.seenMoviesCount - a.seenMoviesCount
  );

  // if (isMoviesLoading) {
  //   return <Loading />;
  // }

  return (
    <main className="top3-container-display" ref={topRef}>
      <div className="loading-inner">
        {selectedMovie && (
          <MovieDetailPopup
            movie={selectedMovie}
            closePopup={closePopup}
            onSimilarMovieSelect={handleSimilarMovieSelect}
            seenMovies={seenMovies}
            userScores={userScores}
            movies={movies}
            updateUserScore={handleAddAndRateMovie}
            watchlistMovies={watchlistMovies}
            userId={user?.sub}
            seenMoviesProp={seenMovies}
            watchlistProp={watchlistMovies}
          />
        )}
        <div className="landing-page-with-nav-header">
          <div className="canvas landing__main">
            {user && user.given_name ? (
              // <h2 className="explore-landing__title typography-large-title-emphasized">{user.given_name}'s&nbsp;</h2>
              <h2 className="explore-landing__title typography-large-title-emphasized">
                Explore
              </h2>
            ) : (
              // <h2 className="explore-landing__title typography-large-title-emphasized">Your&nbsp;Curator&nbsp;Watchlist</h2>
              <h2 className="explore-landing__title typography-large-title-emphasized">
                Explore
              </h2>
            )}

            {/* <div className={`filter-container filter-container-explore ${showYearDropdown || showStreamingDropdown || showLangDropdown || showGenreDropdown || showRatedUnratedDropdown ? 'filter-container-expanded' : ''}`}>
            <div className="dropdown">
              <button 
                onClick={() => setContentType(contentType === 'movies' ? 'tvshows' : 'movies')}
                className={`dropdown-button clear-all toggle-movies4`}
                >
                <h2 className="explore-landing__title typography-large-title-emphasized toggle-moviesB4" style={{paddingTop: "0px"}}>
                {contentType === 'movies' ? 'Show TV Shows' : 'Show Movies'}
                </h2>
              </button>
            </div>
          </div> */}

            {/* <div className={`filter-container filter-container-explore ${showYearDropdown || showStreamingDropdown || showLangDropdown || showGenreDropdown || showRatedUnratedDropdown ? 'filter-container-expanded' : ''}`}>
              <span class="switcher switcher-1">
                <input type="checkbox" id="switcher-1"></input>
                <label for="switcher-1"></label>
              </span>
              <span class="switcher switcher-2">
                <input type="checkbox" id="switcher-2"></input>
                <label for="switcher-2"></label>
              </span>
            </div> */}

            {/* Year Dropdown Menu */}
            {showYearDropdown && (
              <div
                ref={yearDropdownRef}
                className={`dropdown-menu explore-dropdown-menu ${
                  showYearDropdown ? "visible" : ""
                }`}
              >
                <button
                  onClick={() => toggleYearFilter("All")}
                  className="dropdown-item"
                >
                  Select All Years
                </button>
                <button
                  onClick={() => toggleYearFilter("Clear")}
                  className="dropdown-item"
                >
                  Clear
                </button>
                <hr className="hr-line" />
                {decades.map((decade) => (
                  <button
                    onClick={() => toggleYearFilter(decade)}
                    className="dropdown-item"
                    key={decade}
                  >
                    {selectedYears.has(decade) && (
                      <CheckmarkSmall className="checkmark-icon" />
                    )}
                    <span className="dropdown-text">{`${decade} (${decadeCounts[decade]})`}</span>
                  </button>
                ))}
              </div>
            )}

            {/* Lang Dropdown Menu */}
            {showLangDropdown && (
              <div
                ref={langDropdownRef}
                className={`dropdown-menu explore-dropdown-menu ${
                  showLangDropdown ? "visible" : ""
                }`}
              >
                <button
                  onClick={() => toggleLangFilter("All")}
                  className="dropdown-item"
                >
                  Select All Languages
                </button>
                <button
                  onClick={() => toggleLangFilter("Clear")}
                  className="dropdown-item"
                >
                  Clear
                </button>
                <hr className="hr-line" />
                {langList.map((lang) => (
                  <button
                    onClick={() => toggleLangFilter(lang)}
                    className="dropdown-item"
                    key={lang}
                  >
                    {selectedLangs.has(lang) && (
                      <CheckmarkSmall className="checkmark-icon" />
                    )}
                    <span className="dropdown-text">{`${lang} (${langCount[lang]})`}</span>
                  </button>
                ))}
              </div>
            )}

            {/* Streaming Dropdown Menu */}
            {showStreamingDropdown && (
              <div
                ref={streamingDropdownRef}
                className={`dropdown-menu explore-dropdown-menu ${
                  showStreamingDropdown ? "visible" : ""
                }`}
              >
                <button
                  onClick={() => toggleStreamingServiceFilter("All")}
                  className="dropdown-item"
                >
                  Select All Watch Options
                </button>
                <button
                  onClick={() => toggleStreamingServiceFilter("Clear")}
                  className="dropdown-item"
                >
                  Clear
                </button>
                <hr className="hr-line" />
                {streamingServices.map((service) => (
                  <button
                    onClick={() => toggleStreamingServiceFilter(service)}
                    className="dropdown-item"
                    key={service}
                  >
                    {selectedStreamingServices.has(service.toLowerCase()) && (
                      <CheckmarkSmall className="checkmark-icon" />
                    )}
                    <span className="dropdown-text">{`${capitalizeFirstLetter(
                      service
                    )} (${serviceCounts[service]})`}</span>
                  </button>
                ))}
              </div>
            )}

            {/* Genre Dropdown Menu */}
            {showGenreDropdown && (
              <div
                ref={genreDropdownRef}
                className={`dropdown-menu explore-dropdown-menu ${
                  showGenreDropdown ? "visible" : ""
                }`}
              >
                <button
                  onClick={() => toggleGenreFilter("All")}
                  className="dropdown-item"
                >
                  Select All Genres
                </button>
                <button
                  onClick={() => toggleGenreFilter("Clear")}
                  className="dropdown-item"
                >
                  Clear
                </button>
                <hr className="hr-line" />
                {genreList.map((genre) => (
                  <button
                    onClick={() => toggleGenreFilter(genre)}
                    className="dropdown-item"
                    key={genre}
                  >
                    {selectedGenres.has(genre.toLowerCase()) && (
                      <CheckmarkSmall className="checkmark-icon" />
                    )}
                    <span className="dropdown-text">{`${genre} (${genreCount[genre]})`}</span>
                  </button>
                ))}
              </div>
            )}

            {/* Seen/Unseen Dropdown Menu */}
            {showRatedUnratedDropdown && (
              <div
                ref={seenDropdownRef}
                className={`dropdown-menu explore-dropdown-menu ${
                  showRatedUnratedDropdown ? "visible" : ""
                }`}
              >
                <button
                  onClick={() => toggleRatedUnrated("All")}
                  className="dropdown-item"
                >
                  Select All Movies
                </button>
                <button
                  onClick={() => toggleRatedUnrated("Clear")}
                  className="dropdown-item"
                >
                  Clear
                </button>
                <hr className="hr-line" />
                {Object.keys(seenCounts).map((option) => (
                  <button
                    onClick={() => toggleRatedUnrated(option)}
                    className="dropdown-item"
                    key={option}
                  >
                    {showRated === option.toLowerCase() && (
                      <CheckmarkSmall className="checkmark-icon" />
                    )}
                    <span className="dropdown-text">{`${option} (${seenCounts[option]})`}</span>
                  </button>
                ))}
              </div>
            )}

            <div
              className={`filter-container filter-container-explore ${
                showYearDropdown ||
                showStreamingDropdown ||
                showLangDropdown ||
                showGenreDropdown ||
                showRatedUnratedDropdown
                  ? "filter-container-expanded"
                  : ""
              }`}
            >
              {/* <div className="dropdown">
                <button 
                  onClick={() => setContentType(contentType === 'movies' ? 'tvshows' : 'movies')}
                  className={`dropdown-button clear-all toggle-movies4`}
                  >
                  <h2 className="explore-landing__title typography-large-title-emphasized toggle-moviesB4" style={{paddingTop: "0px", padding: "5px"}}>
                  {contentType === 'movies' ? 'Show TV Shows' : 'Show Movies'}
                  </h2>
                </button>
              </div> */}
              {/* Clear All Filters Button */}
              <div className="dropdown">
                <button
                  onClick={clearAllFilters}
                  className={`dropdown-button clear-all`}
                >
                  <span className="button-text">
                    {filtersApplied ? "Filters On" : "Filters Off"}
                  </span>
                  {/* <DropDown className="dropdown-icon" /> */}
                </button>
              </div>

              {/* Year Filter Dropdown */}
              <div className="dropdown">
                <button
                  onClick={toggleYearDropdown}
                  className={`dropdown-button ${
                    isYearFilterActive() ? "active" : ""
                  }`}
                >
                  <span className="button-text">Year</span>
                  <DropDown className="dropdown-icon" />
                </button>
              </div>

              {/* Streaming Services Filter Dropdown */}
              <div className="dropdown">
                <button
                  onClick={toggleStreamingDropdown}
                  className={`dropdown-button ${
                    isStreamingFilterActive() ? "active" : ""
                  }`}
                >
                  <span className="button-text">Streaming</span>
                  <DropDown className="dropdown-icon" />
                </button>
              </div>

              {/* Genre Filter Dropdown */}
              <div className="dropdown">
                <button
                  onClick={toggleGenreDropdown}
                  className={`dropdown-button ${
                    isGenreFilterActive() ? "active" : ""
                  }`}
                >
                  <span className="button-text">Genre</span>
                  <DropDown className="dropdown-icon" />
                </button>
              </div>

              {/* Rated/Unrated Filter Dropdown */}
              <div className="dropdown">
                <button
                  onClick={() => toggleRatedUnratedDropdown()}
                  className={`dropdown-button ${
                    isRatedUnratedFilterActive() ? "active" : ""
                  }`}
                >
                  <span className="button-text">
                    {showRated.charAt(0).toUpperCase() + showRated.slice(1)}
                  </span>
                  <DropDown className="dropdown-icon" />
                </button>
              </div>

              {/* Lang Filter Dropdown */}
              <div className="dropdown">
                <button
                  onClick={toggleLangDropdown}
                  className={`dropdown-button ${
                    isLangFilterActive() ? "active" : ""
                  }`}
                >
                  <span className="button-text">Language</span>
                  <DropDown className="dropdown-icon" />
                </button>
              </div>
            </div>

            {isMoviesLoading && (
              <div className={`shelf-grid shelf-grid--onhover snap-shelf`}>
                <div className="shelf-grid__header">
                  {/* If similar movies exist display title div: */}
                  <div className="shelf-header--with-see-all">
                    <h2 className="typ-headline-emph">Loading</h2>
                    <span className="typ-subhead shelf-subheader clr-secondary-text">
                      Loading movies for you...
                    </span>
                  </div>
                </div>
              </div>
            )}

            {sortedShelvesCurator.map(
              ({ key, title, subtitle, movies, scoreType }, index) => {
                let displayedMovies = movies.filter((shelfMovie) =>
                  filteredMovies.some(
                    (filteredMovie) => filteredMovie.Const === shelfMovie.Const
                  )
                );

                let subtitleDisplay = subtitle; // Use 'let' for reassignable variables

                if (title === "My Watchlist") {
                  subtitleDisplay = "";
                } else if (title === "Seen Movies") {
                  subtitleDisplay = "Sorted by Your Score or Curator Score";
                }

                let displayUserScore = false;

                if (title === "Seen Movies") {
                  displayedMovies = movies;
                  displayUserScore = true;
                } else {
                  displayUserScore = false;
                }

                return (
                  <React.Fragment key={key}>
                    <MovieShelf
                      key={key}
                      title={title}
                      // subtitle={subtitleDisplay}
                      movies={displayedMovies} // Use filtered movies based on the current filter state
                      onMovieSelect={handleMovieSelect}
                      isFirstShelf={index === 0}
                      userScores={userScores}
                      scoreType={scoreType}
                      displayUserScore={true}
                      generateRef={generateRef}
                      userId={user?.sub}
                      seenMoviesProp={seenMovies}
                      watchlistProp={watchlistMovies}
                    />
                  </React.Fragment>
                );
              }
            )}

            {/* {sortedShelves.map(({ key, title, subtitle, movies, scoreType }, index) => {
              const displayedMovies = movies.filter(shelfMovie =>
                filteredMovies.some(filteredMovie => filteredMovie.Const === shelfMovie.Const)
              );

              // Skip rendering this shelf if there are no displayed movies after applying filters
              if (displayedMovies.length === 0) {
                return null;
              }

              return (
                <React.Fragment key={key}>
                  <MovieShelf
                    key={key} // React.Fragment's key is sufficient, this key is redundant
                    title={title}
                    subtitle={subtitle}
                    movies={displayedMovies} // Use filtered movies based on the current filter state
                    onMovieSelect={handleMovieSelect}
                    isFirstShelf={index === 0}
                    userScores={userScores}
                    scoreType={scoreType}
                    generateRef={generateRef}
                    userId={user?.sub}
                    seenMoviesProp={seenMovies}
                    watchlistProp={watchlistMovies}
                  />
                </React.Fragment>
              );
            })} */}
          </div>
        </div>
      </div>
    </main>
  );
};

export default ExploreMovies;
