import React, { useState, useEffect, useRef } from 'react';
import MovieDetailPopup from '../components/MovieDetailPopup';
import MovieShelf from '../components/MovieShelf';
import { ReactComponent as DropDown } from '../assets/chevron.down.svg';
import { ReactComponent as CheckmarkSmall } from '../assets/check-green.svg';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import Loading from "../components/Loading";

import "../components/style.css";
import "../components/style2.css";

import axios from "axios";
import { useAuth0 } from '@auth0/auth0-react';

const Profile2 = () => {
  let apiBaseUrl = '';
  // console.log(`ENV: ${process.env.REACT_APP_NODE_ENV}`);
  if (process.env.REACT_APP_NODE_ENV === 'development') {
      // Use the development base URL
      apiBaseUrl = 'http://localhost:3001';
  } else {
      // Use the production base URL (empty string for relative URL) e
      apiBaseUrl = '';
  }
  const location = useLocation();
  // State to store movies
  const [movies, setMovies] = useState([]);
  const { user, getAccessTokenSilently, isAuthenticated, loginWithRedirect, logout, isLoading} = useAuth0();
  const logoutWithRedirect = () =>
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      }
  });
  const [seenMovies, setSeenMovies] = useState(new Set());
  const [userScores, setUserScores] = useState({});
  const [selectedMovie, setSelectedMovie] = useState(null);
  const navigate = useNavigate();
  const { movieId } = useParams(); // Assuming your route parameter is named `movieId`

  const [isMoviesLoading, setIsMoviesLoading] = useState(true); // State for movie loading

  const topRef = useRef(null); // Add this at the start of your component

  const [showYearDropdown, setShowYearDropdown] = useState(false);
  const [showStreamingDropdown, setShowStreamingDropdown] = useState(false);
  const toggleYearDropdown = () => setShowYearDropdown(prev => !prev);
  const [selectedYears, setSelectedYears] = useState(new Set());
  const [selectedStreamingServices, setSelectedStreamingServices] = useState(new Set());
  const yearDropdownRef = useRef(null);
  const streamingDropdownRef = useRef(null);
  const seenDropdownRef = useRef(null);
  const [selectedGenres, setSelectedGenres] = useState(new Set());
  const [showRated, setShowRated] = useState('all'); // 'all', 'rated', or 'unrated'
  const [showRatedUnratedDropdown, setShowRatedUnratedDropdown] = useState(false);
  const toggleRatedUnratedDropdown = () => setShowRatedUnratedDropdown(prev => !prev);
  const [showGenreDropdown, setShowGenreDropdown] = useState(false);
  const genreDropdownRef = useRef(null);
  const toggleGenreDropdown = () => setShowGenreDropdown(prev => !prev);
  const [searchQuery, setSearchQuery] = useState('');
  const divRef = useRef(null);
  const ratedMoviesCount = Object.keys(userScores).length;
  const unratedMoviesCount = movies.length - ratedMoviesCount;

  const [showLangDropdown, setShowLangDropdown] = useState(false);
  const toggleLangDropdown = () => setShowLangDropdown(prev => !prev);
  const [selectedLangs, setSelectedLangs] = useState(new Set());
  const langDropdownRef = useRef(null);

  const [filtersApplied, setFiltersApplied] = useState(true);
  const [userMadeChanges, setUserMadeChanges] = useState(false);

  const [showImportModal, setShowImportModal] = useState(false);
  const [letterboxdUsername, setLetterboxdUsername] = useState('');

  const [isLoading2, setIsLoading2] = useState(false);
  const [summary, setSummary] = useState(null);

  const [watchlistMovies, setWatchlistMovies] = useState(new Set());
  const [contentType, setContentType] = useState('movies'); // Initial state is 'movies'


  // Toggle Streaming Dropdown
  const toggleStreamingDropdown = () => {
    setShowStreamingDropdown(prev => {
      if (prev) {
        // If the dropdown is currently shown (prev is true), it will now be closed
      } else {
        // If the dropdown is currently hidden (prev is false), it will now be opened
        // Close other dropdowns when opening this one
        setShowYearDropdown(false);
        setShowGenreDropdown(false);
        setShowRatedUnratedDropdown(false);
        setShowLangDropdown(false);
      }
      return !prev;
    });
  };  

  // Initial fetch of user filters
  useEffect(() => {
    const fetchFilters = async () => {
      if (isAuthenticated && user) {
        try {
          const response = await axios.get(`${apiBaseUrl}/api/user/filters`, {
            params: { userId: user.sub },
          });
          const { streamingServices, selectedYears, selectedGenres, showRated, selectedLangs } = response.data;
          setSelectedStreamingServices(new Set(streamingServices || []));
          setSelectedYears(new Set(selectedYears || []));
          setSelectedLangs(new Set(selectedLangs || []));
          setSelectedGenres(new Set(selectedGenres || []));
          setShowRated(showRated || 'all');
        } catch (error) {
          console.error('Error loading filters:', error);
        }
      }
    };

    fetchFilters();
  }, [user, isAuthenticated, getAccessTokenSilently]); // Removed from dependencies to prevent re-fetching

  
  // Save filter state whenever they change
  useEffect(() => {
    if (userMadeChanges) {
      updateFiltersInDb();
      setUserMadeChanges(false); // Reset the flag after updating
    }
  }, [selectedYears, selectedLangs, selectedStreamingServices, selectedGenres, showRated, userMadeChanges]); // Include userMadeChanges in the dependency array
  

  const updateFiltersInDb = async () => {
    if (isAuthenticated && user && userMadeChanges) { // This check might be redundant now
      try {
        await axios.post(`${apiBaseUrl}/api/user/updateFilters`, {
          userId: user.sub,
          streamingServices: Array.from(selectedStreamingServices),
          selectedYears: Array.from(selectedYears),
          selectedLangs: Array.from(selectedLangs),
          selectedGenres: Array.from(selectedGenres),
          showRated: showRated,
        });
      } catch (error) {
        console.error('Error updating filters:', error);
      }
    }
  };   
  
  const clearAllFilters = () => {
    setFiltersApplied(!filtersApplied);
  };

  const serviceCounts = {
    "Buy": 339,
    "Max": 118,
    "Disney": 75,
    "Netflix": 69,
    "Prime": 65,
    "Paramount": 48,
    "AMC": 34,
    "Freevee": 32,
    "MGM": 27,
    "Hulu": 24,
    "Peacock": 21,
    "Cinemax": 21,
    "STARZ": 19,
    "Pluto": 19,
    "Showtime": 19,
    "FXNOW": 18,
    "Roku": 6,
    "Bravo": 5,
    "Tribeca": 5,
    "Plex": 5,
    "Tubi": 5,
    "ABC": 4,
    "Magnolia": 4,
    "MUBI": 4,
    "Crackle": 3,
    "Freeform": 3,
    "Crunchyroll": 2,
    "TBS": 2,
    "Sundance": 1,
    "Apple": 1,
    "Shout": 1,
    "Dox": 1
  };
  // Convert serviceCounts to an array and sort by count
  const streamingServices = Object.entries(serviceCounts)
  .sort((a, b) => b[1] - a[1])
  .map(service => service[0]);

  const genreCount = {
    "Action": 226,
    "Drama": 214,
    "Comedy": 154,
    "Animation": 103,
    "Crime": 100,
    "Biography": 91,
    "Adventure": 66,
    "Horror": 31,
    "Mystery": 6,
    "Fantasy": 3,
    "Documentary": 3,
    "Western": 1,
    "Film-Noir": 1,
    "Family": 1
  };
  const langCount = {
    "English": 937,
    "French": 168,
    "Spanish": 153,
    "German": 102,
    "Russian": 97,
    "Italian": 79,
    "Japanese": 60,
    "Latin": 49,
    "Arabic": 37,
    "Mandarin": 25,
    "Ukrainian": 25,
    "Cantonese": 22,
    "Korean": 19,
    "Swedish": 19,
    "Hindi": 15,
    "Hebrew": 14,
    "Greek": 12,
    "Portuguese": 11,
    "American Sign Language": 11,
    "Persian": 10,
    "Turkish": 10,
    "Chinese": 10,
    "Polish": 9,
    "Hungarian": 8,
    "Vietnamese": 7,
    "Thai": 7,
    "Norwegian": 7,
    "Danish": 6,
    "Urdu": 6,
    "Czech": 6,
    "Xhosa": 6,
    "Romanian": 6,
    "Yiddish": 6,
    "Swahili": 6,
    "Sign Languages": 4,
    "Dutch": 4,
    "Afrikaans": 4,
    "Indonesian": 4,
    "Irish Gaelic": 4,
    "Shanghainese": 3,
    "Somali": 3,
    "North American Indian": 3,
    "Hawaiian": 3,
    "Sindarin": 3,
    "Old English": 3,
    "Zulu": 3,
    "Serbian": 3,
    "Tibetan": 2,
    "Esperanto": 2,
    "Klingon": 2,
    "Sotho": 2,
    "Pawnee": 2,
    "Greek, Ancient (to 1453)": 2,
    "Armenian": 2,
    "Tamil": 2,
    "Nepali": 2,
    "Kurdish": 2,
    "Telugu": 2,
    "Tagalog": 2,
    "Gaelic": 2,
    "Malayalam": 2,
    "Kannada": 2,
    "Bulgarian": 2,
    "Bengali": 2,
    "Quechua": 2,
    "Punjabi": 2,
    "Pashtu": 2,
    "Flemish": 1,
    "Kalmyk-Oirat": 1,
    "Lao": 1,
    "Hokkien": 1,
    "Albanian": 1,
    "Sioux": 1,
    "Yoruba": 1,
    "Mende": 1,
    "Nama": 1,
    "Haryanvi": 1,
    "Akan": 1,
    "Tok Pisin": 1,
    "Greenlandic": 1,
    "Mohawk": 1,
    "None": 1,
    "Amharic": 1,
    "Kikuyu": 1,
    "Latvian": 1,
    "Hmong": 1,
    "Icelandic": 1,
    "Maya": 1,
    "Oriya": 1,
    "Romany": 1,
    "British Sign Language": 1,
    "Georgian": 1,
    "Saami": 1,
    "Malay": 1,
    "Assamese": 1,
    "Mongolian": 1,
    "Sicilian": 1,
    "Maori": 1,
    "Nyanja": 1,
    "Rajasthani": 1,
    "Marathi": 1,
    "Brazilian Sign Language": 1,
    "Mapudungun": 1,
    "Estonian": 1,
    "Finnish": 1,
    "Central Khmer": 1,
    "Mixtec": 1,
    "Inuktitut": 1,
    "Filipino": 1,
    "Corsican": 1,
    "Norse, Old": 1,
    "Japanese Sign Language": 1,
    "Scots": 1,
    "Quenya": 1,
    "Berber languages": 1,
    "Navajo": 1,
    "Kinyarwanda": 1,
    "Catalan": 1
  };

  const langList = Object.entries(langCount)
  .sort((a, b) => b[1] - a[1])
  .map(service => service[0]);
 
  const genreList = Object.entries(genreCount)
  .sort((a, b) => b[1] - a[1])
  .map(service => service[0]);
  
  const decades = ['2020s', '2010s', '2000s', '1990s', '1980s', '1970s', '1960s', '1950s', '1940s', '1930s'];

  const decadeCounts = {
    '2020s': 56,
    '2010s': 336,
    '2000s': 260,
    '1990s': 135,
    '1980s': 83,
    '1970s': 45,
    '1960s': 36,
    '1950s': 27,
    '1940s': 14,
    '1930s': 7
  };
  
  const toggleYearFilter = (year) => {
    if (year === 'All') {
      setSelectedYears(new Set(decades));
    } else if (year === 'Clear') {
      setSelectedYears(new Set());
    } else {
      setSelectedYears((prevYears) => {
        const newYears = new Set(prevYears);
        if (newYears.has(year)) {
          newYears.delete(year);
        } else {
          newYears.add(year);
        }
        return newYears;
      });
    }
    setUserMadeChanges(true);
  };

  const toggleLangFilter = (lang) => {
    if (lang === 'All') {
      setSelectedLangs(new Set(langList.map(l => l.toLowerCase())));
    } else if (lang === 'Clear') {
      setSelectedLangs(new Set());
    } else {
      setSelectedLangs((prevLangs) => {
        const newLangs = new Set(prevLangs);
        if (newLangs.has(lang)) {
          newLangs.delete(lang);
        } else {
          newLangs.add(lang);
        }
        return newLangs;
      });
    }
    setUserMadeChanges(true);
  };
  
  const toggleStreamingServiceFilter = (service) => {
    if (service === 'All') {
      setSelectedStreamingServices(new Set(streamingServices.map(s => s.toLowerCase())));
    } else if (service === 'Clear') {
      setSelectedStreamingServices(new Set());
    } else {
      setSelectedStreamingServices((prevServices) => {
        const newServices = new Set(prevServices);
        if (newServices.has(service.toLowerCase())) {
          newServices.delete(service.toLowerCase());
        } else {
          newServices.add(service.toLowerCase());
        }
        return newServices;
      });
    }
    setUserMadeChanges(true);
  };   
  
  // Toggle Genre Filter with 'All' and 'Clear' functionality
  const toggleGenreFilter = (genre) => {
    if (genre === 'All') {
      setSelectedGenres(new Set(genreList.map(g => g.toLowerCase())));
    } else if (genre === 'Clear') {
      setSelectedGenres(new Set());
    } else {
      setSelectedGenres((prevGenres) => {
        const newGenres = new Set(prevGenres);
        if (newGenres.has(genre.toLowerCase())) {
          newGenres.delete(genre.toLowerCase());
        } else {
          newGenres.add(genre.toLowerCase());
        }
        return newGenres;
      });
    }
    setUserMadeChanges(true);
  };

  const handleMovieSelect = (movie) => {
    navigate(`/profile/${user.nickname}/${movie.Title}`);
    setSelectedMovie(movie);
  };

  const handleSimilarMovieSelect = (similarMovieId) => {
    const newSelectedMovie = movies.find(movie => movie._id === similarMovieId);
    if (newSelectedMovie) {
      setSelectedMovie(newSelectedMovie);
    }

    const elementTop = document.querySelector('.h-100');
    if (elementTop) {
      // elementTop.scrollIntoView({ behavior: 'smooth', block: 'start' });
      elementTop.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }    
  };

  const toggleRatedUnrated = (option) => {
    setShowRated(option); // option can be 'all', 'rated', or 'unrated'
    setUserMadeChanges(true);
  };  

  const decodeDecade = (decade) => {
    const startYear = parseInt(decade.substring(0, 4), 10);
    const endYear = startYear + 9;
    return [startYear, endYear];
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (yearDropdownRef.current && !yearDropdownRef.current.contains(event.target)) {
        setShowYearDropdown(false);
      }
      if (langDropdownRef.current && !langDropdownRef.current.contains(event.target)) {
        setShowLangDropdown(false);
      }
      if (streamingDropdownRef.current && !streamingDropdownRef.current.contains(event.target)) {
        setShowStreamingDropdown(false);
      }
      if (seenDropdownRef.current && !seenDropdownRef.current.contains(event.target)) {
        setShowRatedUnratedDropdown(false);
      }
      if (genreDropdownRef.current && !genreDropdownRef.current.contains(event.target)) {
        setShowGenreDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);  

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const buttonRef = useRef(null);
  const dropdownMenuRef = useRef(null);

  const isYearFilterActive = () => {
    return selectedYears.size > 0;
  };

  const isLangFilterActive = () => {
    return selectedLangs.size > 0;
  };

  const isStreamingFilterActive = () => {
    return selectedStreamingServices.size > 0;
  };
  
  const isGenreFilterActive = () => {
    return selectedGenres.size > 0;
  };
  
  const isRatedUnratedFilterActive = () => {
    return showRated !== 'all'; // Assuming 'all' means no filter is applied
  };

  // const streamingServices = ["netflix", "max", "prime", "paramount", "hulu"];

  const allMovies = [
    // Assuming this array is populated with movie objects
    // Each movie object has properties like 'releaseDate', 'rating', 'genre', etc.
  ];

  // Correcting filterCommonCriteria to use movie.Const and ensuring streaming service check is correct
  const filterCommonCriteria = (movie) => {
    // Movie Curator score must be 80 or higher
    if (movie.CuratorScore < 80) {
      return false;
    }
    return true;
  };

  // This remains correct for checking unrated movies
  const filterUnratedMovies = (movie) => {
    const hasUserScore = userScores.hasOwnProperty(movie.Const);
    return !hasUserScore;
  };

  const adjustDropdownWidth = () => {
    if (divRef.current && dropdownMenuRef.current) {
      const divWidth = divRef.current.offsetWidth;
      const dropdownMenus = document.querySelectorAll('.dropdown-menu');

      dropdownMenus.forEach(menu => {
        menu.style.width = `${divWidth}px`;
        menu.style.left = `${divRef.current.getBoundingClientRect().left}px`;
        menu.style.backgroundColor = 'red';
      });
    }
  };

  // Utility function to capitalize the first letter of a string
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  // const handleImportMovies = async () => {
  //   try {
  //     // Assuming you have the API endpoint and axios setup
  //     const response = await axios.post(`${apiBaseUrl}/api/import-ratings`, {
  //       userId: user.sub, // Auth0 user ID
  //       letterboxdUsername,
  //     });
  //     console.log(response.data);
  //     // Handle success, perhaps refresh seen movies or notify the user
  //   } catch (error) {
  //     console.error('Error importing ratings:', error);
  //     // Handle error, notify the user
  //   }
  // };

  const handleImportMovies = async () => {
    setIsLoading2(true);
    setSummary(null); // Reset summary for new imports

    try {
      const response = await axios.post(`${apiBaseUrl}/api/import-ratings`, {
        userId: user.sub, // Auth0 user ID
        letterboxdUsername,
      });
      setSummary(response.data.summary);
    } catch (error) {
      alert('Failed to import ratings. Please try again.');
    } finally {
      setIsLoading2(false);
    }
  };

  // Assuming selectedLangs is a Set of selected language texts, all in lowercase
const filteredMovies = filtersApplied ? movies.filter(movie => {
  const matchesSearch = searchQuery === '' || 
    movie.Title.toLowerCase().includes(searchQuery.toLowerCase()) ||
    movie.Genre.toLowerCase().includes(searchQuery.toLowerCase()) || 
    movie.spokenLanguages?.spokenLanguages?.some(lang => lang.text.includes(searchQuery)) ||
    movie.Streaming.toLowerCase().includes(searchQuery.toLowerCase()) ||
    movie.Year.toString().includes(searchQuery);

  const matchesYearFilter = selectedYears.size === 0 || [...selectedYears].some(decade => {
    const [startYear, endYear] = decodeDecade(decade);
    return movie.Year >= startYear && movie.Year <= endYear;
  });

  const matchesStreamingFilter = selectedStreamingServices.size === 0 || 
    selectedStreamingServices.has(movie.Streaming.toLowerCase());

  // Correct implementation for language filter
  const matchesLangFilter = selectedLangs.size === 0 || 
    movie.spokenLanguages?.spokenLanguages?.some(lang => {
      const match = selectedLangs.has(lang.text);
      return match;
    });

  const matchesGenreFilter = selectedGenres.size === 0 || selectedGenres.has(movie.Genre.split(', ')[0].toLowerCase());

  const isMovieRated = userScores.hasOwnProperty(movie.Const);
  const matchesRatedFilter = showRated === 'all' || 
    (showRated === 'rated' && isMovieRated) ||
    (showRated === 'unrated' && !isMovieRated);

  const matchesCuratorScore = movie.CuratorScore >= 80;

  return matchesSearch && matchesCuratorScore && matchesYearFilter && matchesLangFilter && matchesStreamingFilter && matchesGenreFilter;
}) : movies;

// Log the selected languages and how many total movies have each language
selectedLangs.forEach(selectedLang => {
  const count = filteredMovies.filter(movie => 
    movie.spokenLanguages?.spokenLanguages?.some(lang => lang.text.toLowerCase() === selectedLang)
  ).length;
  // console.log(`Language Selected: ${selectedLang}, Total Movies: ${count}`);
});
  
  const [setFilteredMovies] = useState([]);
  
  useEffect(() => {
    // Call adjustDropdownWidth when dropdown is open and divRef is available
    if (isDropdownOpen && divRef.current) {
      adjustDropdownWidth();
    }
  }, [isDropdownOpen]);

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const response = await axios.get(`${apiBaseUrl}/api/content?type=${contentType}`);
        const fetchedContent = response.data;
        fetchedContent.sort((a, b) => b.CuratorScore - a.CuratorScore);
        setMovies(fetchedContent);
        setIsMoviesLoading(false);
      } catch (error) {
        console.error('Error fetching content data:', error);
        setIsMoviesLoading(false);
      }
    };
  
    const fetchUserSpecificData = async () => {
      if (!isAuthenticated || !user || !user.sub) {
        return;
      }
      try {
        // const accessToken = await getAccessTokenSilently();
        // Proceed with fetching seen movies and user scores using the obtained access token
        const seenMoviesResponse = await axios.get(`${apiBaseUrl}/api/user/seenMovies`, {
          headers: {
            // 'Authorization': `Bearer ${accessToken}`,
            'user-id': user.sub,
          },
        });

        const watchlistMoviesResponse = await axios.get(`${apiBaseUrl}/api/user/watchlistMovies`, {
          headers: {
            // 'Authorization': `Bearer ${accessToken}`,
            'user-id': user.sub,
          },
        });
  
        const processedWatchlistMovies = new Set();
  
        // Process seenMovies and userScores from the response
        const processedSeenMovies = new Set();
        const processedUserScores = {};
  
        seenMoviesResponse.data.forEach(movie => {
          processedSeenMovies.add(movie.Const);
          if (movie.userScore) {
            processedUserScores[movie.Const] = movie.userScore;
          }
        });

        watchlistMoviesResponse.data.forEach(movie => {
          processedWatchlistMovies.add(movie.Const);
        });
        
        setWatchlistMovies(processedWatchlistMovies);

  
        setSeenMovies(processedSeenMovies);
        setUserScores(processedUserScores);
      } catch (error) {
        console.error('Error fetching user specific data:', error);
        // Check for token-related errors
        if (error.error === 'login_required' || error.error === 'consent_required' || error.error === 'invalid_token') {
          // This is a simplistic approach. Depending on your UX, you may choose to show an error message,
          // redirect to a login page, or use a modal to inform the user.
          console.log('Session expired. Redirecting to login...');
          loginWithRedirect();
        }
      }
    };
  
    // Fetch the general list of movies
    // fetchMovies();
    fetchContent();
  
    // Fetch user-specific data if the user is authenticated
    fetchUserSpecificData();
  }, [user, isAuthenticated, getAccessTokenSilently, loginWithRedirect, contentType]);

  // Function to handle score change and update the database
  const handleScoreChange = async (constId, newScore) => {
      try {
          const token = await getAccessTokenSilently();
          await axios.post(`${apiBaseUrl}/api/user/rateMovie`, {
              userId: user.sub,
              Const: constId,
              score: newScore
          }, {
              headers: {
                  Authorization: `Bearer ${token}`,
              },
          });

          // Update the userScores state to include the new score
          setUserScores(prevUserScores => ({ ...prevUserScores, [constId]: newScore }));

          // Check if the movie is already in seenMovies before adding it
          if (!seenMovies.has(constId)) {
              setSeenMovies(prevSeenMovies => new Set([...prevSeenMovies, constId]));
          }
      } catch (error) {
          console.error('Error rating movie:', error);
      }
  };

  const handleAddAndRateMovie = async (movieConst, movieId) => {
    if (!isAuthenticated) {
      alert("Please log in to rate movies."); // Replace this with a UI-based prompt when you're ready.
      return; // Exit the function early.
    }
  
    // Continue with your logic for adding and rating a movie.
    const newScore = prompt('Enter your score (1-99):');
    if (newScore && newScore >= 1 && newScore <= 99) {
      if (!seenMovies.has(movieConst)) {
        await addMovieToSeenList(movieId);
      }
      await handleScoreChange(movieConst, newScore);
    }
  };
  

  const addMovieToSeenList = async (movieId) => {
    try {
      const token = await getAccessTokenSilently();
      const movie = movies.find(m => m._id === movieId);
      if (!movie) {
        console.error('Movie not found');
        return;
      }
  
      if (seenMovies.has(movie.Const)) {
        // Remove movie from seen list
        await removeMovieFromSeenList(movie.Const);
      } else {
      await axios.post(`${apiBaseUrl}/api/user/addMovie`, {
        userId: user.sub,
        name: user.name,
        movie: { Const: movie.Const, Title: movie.Title }
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // Update the seenMovies state to include the new movie
      setSeenMovies(new Set([...seenMovies, movie.Const]));
      }

    } catch (error) {
      console.error('Error adding movie to seen list:', error);
    }
  };

  const removeMovieFromSeenList = async (movieConst) => {
        try {
          const token = await getAccessTokenSilently();
          await axios.post(`${apiBaseUrl}/api/user/removeMovie`, {
            userId: user.sub,
            movieConst: movieConst, // Send the Const value
          }, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
      
          // Update local seenMovies state
          const newSeenMovies = new Set(seenMovies);
          newSeenMovies.delete(movieConst);
          setSeenMovies(newSeenMovies);
        } catch (error) {
          console.error('Error removing movie from seen list:', error);
        }
    };  


  const handleResetSeenMovies = async () => {
    const confirmReset = window.confirm("Are you sure you want to reset all your seen movie data? This is irreversible.");
    if (confirmReset) {
      try {
        const response = await axios.post(`${apiBaseUrl}/api/reset-seen-movies`, { userId: user.sub });
        alert(response.data.message);
        // Update your UI accordingly
      } catch (error) {
        console.error('Error resetting seen movies:', error);
        alert('Failed to reset seen movies.');
      }
    }
  };

  //const averageScore = movies.reduce((acc, movie) => acc + (movie.CuratorScore || 0), 0) / movies.length;
  // Corrected filter for Your Favorites (Rated) using the provided filter logic
  // Your Favorites (Rated)
  // First, extract Const values from user.seenMovies
  const seenMovieConsts = user && user.seenMovies ? user.seenMovies.map(movie => movie.Const) : [];

  // Then, use these Const values for filtering
  // Adjusted filtering for Your Favorites (Rated)
  // const yourFavoritesRated = movies.filter(movie => 
  //   seenMovies.has(movie.Const) && userScores.hasOwnProperty(movie.Const))
  //   .sort((a, b) => userScores[b.Const] - userScores[a.Const]);

// useEffect(() => {
//   // Assuming `seenMovieIdentifiers` is the state holding just the identifiers of seen movies
//   calculateUserStatsFromSeenMovies([...seenMovieIdentifiers]).then(userStats => {
//     // Do something with `userStats`, like setting state to render in your component
//   });
// }, [seenMovieIdentifiers]); // Dependency array based on your state

  // const yourFavoritesRated = movies.filter(movie => 
  //   seenMovies.has(movie.Const))
  //   .sort((a, b) => userScores[b.Const] - userScores[a.Const]);

  const yourFavoritesRated = movies.filter(movie => 
    seenMovies.has(movie.Const))
    .sort((a, b) => {
        // Check if userScores for both movies exist
        const scoreA = userScores.hasOwnProperty(a.Const) ? userScores[a.Const] : a.CuratorScore;
        const scoreB = userScores.hasOwnProperty(b.Const) ? userScores[b.Const] : b.CuratorScore;

        // Sort by the determined score, descending
        return scoreB - scoreA;
  });

  const yourWatchlist = movies.filter(movie => 
    watchlistMovies.has(movie.Const))
    .sort((a, b) => {
        // Check if userScores for both movies exist
        const scoreA = userScores.hasOwnProperty(a.Const) ? userScores[a.Const] : a.CuratorScore;
        const scoreB = userScores.hasOwnProperty(b.Const) ? userScores[b.Const] : b.CuratorScore;

        // Sort by the determined score, descending
        return scoreB - scoreA;
  });

  const yourFavoritesRatedFilter = filteredMovies.filter(movie => 
    seenMovies.has(movie.Const) && userScores.hasOwnProperty(movie.Const))
    .sort((a, b) => userScores[b.Const] - userScores[a.Const]);

  // Top Curator Picks
  const topCuratorPicks = filteredMovies
  .filter(filterCommonCriteria)
  .sort((a, b) => b.CuratorScore - a.CuratorScore);

  // Compiling Group 1 shelves with titles and subtitles
  const group1Shelves = [
    ...(isAuthenticated ? [
    { key: 'yourFavoritesRated', title: 'Seen Movies', subtitle: 'Sorted by Your Score or Curator Score', movies: yourFavoritesRated },
    { key: 'yourWatchlist', title: 'Watchlist', subtitle: '', movies: yourWatchlist },
  
  ] : []),
    
    // { key: 'topImdbMovies', title: 'Top IMDb Movies', subtitle: 'Sorted by IMDb Rating', movies: topImdbMovies },
  ];

  // Compiling Group 2 shelves
  const group2Shelves = [
    ...(isAuthenticated ? [
      { key: 'yourFavoritesRatedFilter', title: 'Your Favorites Filtered', subtitle: 'Sorted by Your Score', movies: yourFavoritesRatedFilter }] : []),
    // { key: 'hiddenGems', title: 'Hidden Gems', movies: hiddenGems },
  ];

  // This structure can now be used to render shelves in your UI, each shelf with its movies sorted by Rank within their category.
  // Assuming group1Shelves and group2Shelves are your shelf groups
  const allShelves = [...group1Shelves, ...group2Shelves];

  // Filter shelves to only include those with 3 or more movies
  const shelvesToShow = allShelves.filter(({ movies }) => movies.length >= 3);
// Assuming seenMovies, userScores, and movies are already defined

// Helper function to sort and slice top 3
const getTop3 = (counts) => {
  return Object.entries(counts)
    .sort(([, a], [, b]) => b - a)
    .slice(0, 3)
    .map(([key, value]) => ({ name: key, count: value }));
};

const calculateUserStatsFromRatedMovies = (movies, userScores) => {
  // Filter only user rated movies
  const ratedMovies = movies.filter(movie => userScores[movie.Const]);

  // Genre counts and top 3 genres
  const genreCounts = {};
  ratedMovies.forEach(movie => movie.Genre.split(', ').forEach(genre => genreCounts[genre] = (genreCounts[genre] || 0) + 1));
  const topGenres = getTop3(genreCounts);

  // Director counts and top 3 directors
  const directorCounts = {};
  ratedMovies.forEach(movie => movie.Director.forEach(director => directorCounts[director] = (directorCounts[director] || 0) + 1));
  const topDirectors = getTop3(directorCounts);

  // Actor counts and top 3 actors
  const actorCounts = {};
  ratedMovies.forEach(movie => movie.Stars.forEach(actor => actorCounts[actor] = (actorCounts[actor] || 0) + 1));
  const topActors = getTop3(actorCounts);

  // Assuming `ratedMovies` is an array of movies where each movie has a `Runtime` property in seconds.

  // Total runtime in minutes
  const totalRuntimeMinutes = ratedMovies.reduce((acc, movie) => acc + movie.Runtime / 60, 0);

  // Calculate total runtime in hours and days
  const totalRuntimeHours = Math.floor(totalRuntimeMinutes / 60);
  const totalRuntimeDays = Math.floor(totalRuntimeHours / 24);

  // Calculate the remainder for hours and minutes for display
  const hoursRemainder = totalRuntimeHours % 24;
  const minutesRemainder = totalRuntimeMinutes % 60;

  // Format for display
  const formattedTotalRuntime = `${totalRuntimeDays > 0 ? totalRuntimeDays + " days, " : ""}${hoursRemainder > 0 ? hoursRemainder + " hours, " : ""}${minutesRemainder} minutes`;

  // console.log(formattedTotalRuntime);

  // Calculate other stats as needed...
  const topRatedMovies = ratedMovies
  .sort((a, b) => userScores[b.Const] - userScores[a.Const])
  .slice(0, 3)
  .map(movie => ({
    title: movie.Title,
    userScore: userScores[movie.Const]
  }));

  

  const topMoviesForTopGenres = topGenres.map(genre => {
    const moviesInGenre = ratedMovies.filter(movie => movie.Genre.includes(genre.name));
    const topMovies = moviesInGenre
      .sort((a, b) => userScores[b.Const] - userScores[a.Const])
      .slice(0, 3)
      .map(movie => ({
        title: movie.Title,
        userScore: userScores[movie.Const]
      }));
    return {
      genre: genre.name,
      movies: topMovies
    };
  });
  

  const totalMoviesRated = Object.keys(userScores).length;
  const averageUserRating = Object.values(userScores).reduce((acc, score) => acc + score, 0) / totalMoviesRated;

  const ratedCardInfo = `
    ${totalMoviesRated}
    / ${movies.length}`;
    
  return {
    ratedCardInfo,
    topGenres,
    topDirectors,
    topActors,
    formattedTotalRuntime,
    // totalRuntimeDays,
    // More stats as needed...
  };
};

// useEffect(() => {
//   // Function to determine if the current URL is the base explore page without any ID
//   const isBaseExplorePage = () => {
//       // Split the pathname by '/' and remove any empty strings that may result from trailing slashes
//       const pathSegments = location.pathname.split('/').filter(Boolean);
      
//       // Check if the path is exactly '/explore' with no additional segments
//       return pathSegments.length === 1 && pathSegments[0] === 'profile/';
//   };

//   // Close the popup if we're on the base explore page
//   if (isBaseExplorePage()) {
//     setSelectedMovie(null);
//   }
// }, [location]);

useEffect(() => {
  // Function to determine if the URL matches the base profile page of a user without additional paths
  const isUserProfilePage = () => {
      // Split the pathname by '/' and remove any empty strings that may result from trailing slashes
      const pathSegments = location.pathname.split('/').filter(Boolean);

      // Check if the path matches the pattern of a user profile page ('profile' followed by a userId and no more segments)
      return pathSegments.length === 2 && pathSegments[0] === 'profile';
  };

  // Close the popup if we're on a user profile page without additional segments
  if (isUserProfilePage()) {
    setSelectedMovie(null);
  }
}, [location]);

const calculateUserStatsFromSeenMovies = (movies, seenMovieIds) => {
  // Extract seen movies' full details from the full movies list
  const seenMoviesFullDetails = movies.filter(movie => seenMovieIds.has(movie.Const));
  const seenCount = seenMoviesFullDetails.length;

  // Initialize counters and accumulators
  const genreCounts = {};
  const directorCounts = {};
  const actorCounts = {};
  let totalRuntime = 0;

  // Process each seen movie
  seenMoviesFullDetails.forEach(movie => {
    // Genre processing
    movie.Genre.split(', ').forEach(genre => {
      genreCounts[genre] = (genreCounts[genre] || 0) + 1;
    });

    // Assuming Director and Stars data are available and structured correctly
    // If Director and Stars are arrays
    movie.Director.forEach(director => {
      directorCounts[director] = (directorCounts[director] || 0) + 1;
    });
    movie.Stars.forEach(actor => {
      actorCounts[actor] = (actorCounts[actor] || 0) + 1;
    });

    // Total runtime
    totalRuntime += movie.Runtime || 0;
  });

  // Assuming `totalRuntime` is in minutes

  // Assuming `totalRuntime` is in seconds

  // Convert total runtime to minutes first
  const totalRuntimeMinutes = totalRuntime / 60;

  // Then, convert total runtime to hours and days
  const totalRuntimeHours = Math.floor(totalRuntimeMinutes / 60);
  const totalRuntimeDays = Math.floor(totalRuntimeHours / 24);

  // Hours remainder for display (without converting to days)
  const hoursRemainder = totalRuntimeHours % 24;

  // Format for display
  const formattedTotalRuntime = `${totalRuntimeDays > 0 ? `${totalRuntimeDays} days, ` : ''}${hoursRemainder} hours`;

  // console.log(formattedTotalRuntime);


  // Compile top genres, directors, and actors
  const topGenres = getTop3(genreCounts);
  const topDirectors = getTop3(directorCounts);
  const topActors = getTop3(actorCounts);

  return {
    ratedCardInfo: `${seenCount} / ${movies.length}`,
    topGenres,
    topDirectors,
    topActors,
    formattedTotalRuntime,
  };
};

const userStatsFromRatedMovies = calculateUserStatsFromRatedMovies(movies, userScores);
const userStatsFromSeenMovies = calculateUserStatsFromSeenMovies(movies, seenMovies);

const StatsCard = ({ title, value }) => (
  <div className="review-card tertiary-bg-color rating-card">
    <div className="review-card__title typ-headline-emph">
      <span>{title}</span>
    </div>
    <div className="line-clamp-wrapper">
      <div className="product-footer__description typ-subhead clr-secondary-text">
        {typeof value === 'string' || typeof value === 'number'
          ? value
          : (value || []).map(({ name, count }, index) => ( // Safely handle potential undefined values
              <React.Fragment key={index}>
                <span className="line-clamp" style={{ WebkitLineClamp: "1"}}>{`${name} (${count})`}</span>
              </React.Fragment>
          ))}
      </div>
    </div>
  </div>
);

const UserStatsDisplay = ({ titlePrefix, userStats }) => (
  <div className="product-footer__container" style={{ padding: "0 20px" }}>
    <div className="shelf-header--with-see-all">
      <h2 className="typ-headline-emph"  style={{ padding: "20px 0px 20px 5px" }}>My {titlePrefix} Movies Stats</h2>
      <span className="typ-subhead shelf-subheader clr-secondary-text"></span>
    </div>
    <div className="product-footer__info even-split">
      {/* Total Movies Rated/Seen */}
      <StatsCard title={`${titlePrefix} Movies`} value={userStats.ratedCardInfo} />
      {/* Top Genres */}
      <StatsCard title={`Top3 Genres`} value={userStats.topGenres} />
      {/* Top Directors */}
      <StatsCard title={`Top3 Directors`} value={userStats.topDirectors} />
      {/* Top Actors */}
      <StatsCard title={`Top3 Actors`} value={userStats.topActors} />
      {/* Total Runtime */}
      <StatsCard title={`Watchtime`} value={userStats.formattedTotalRuntime} />
    </div>
  </div>
);

  const UserStats = () => {
    // Change userStatsFromRatedMovies to userStatsFromSeenMovies to display stats from all seen movies
    return (
      <div className="product-footer secondary-bg-color" style={{marginTop: "20px", borderRadius: "0px", paddingBottom: "20px", paddingTop: "0px"}}>
        <UserStatsDisplay titlePrefix="Seen" userStats={userStatsFromSeenMovies} />
        {/* <UserStatsDisplay titlePrefix="Rated" userStats={userStatsFromRatedMovies} /> */}
      </div>
    );
  };

  const closePopup = () => {
    navigate(`/profile/${user.nickname}`); // Navigate back to the explore base route
    setSelectedMovie(null); // Close the popup
  };

  return (
    <main className="top3-container-display" ref={topRef} style={{scrollSnapType: "none"}}>
      <div className="loading-inner">
      {selectedMovie && (
                  <MovieDetailPopup 
                      movie={selectedMovie} 
                      closePopup={closePopup}
                      onSimilarMovieSelect={handleSimilarMovieSelect}
                      seenMovies={seenMovies}
                      userScores={userScores} 
                      movies={movies}
                      updateUserScore={handleAddAndRateMovie}
                      watchlistMovies={watchlistMovies}
                      userId={user?.sub}
                      seenMoviesProp={seenMovies}
                      watchlistProp={watchlistMovies}
                  />
              )}
        <div className="landing-page-with-nav-header">
          <div className="canvas landing__main">

          {user && user.given_name ? ( 
            <div className="global-footer typ-footnote" style={{padding: "unset", display: "contents"}}>
            <div className="global-footer__left">
              {isAuthenticated && (
                <div className="" style={{display: "flex", justifyContent: "space-between", paddingRight: "30px"}}>
                <h2 className="explore-landing__title typography-large-title-emphasized">{user.given_name}'s&nbsp;Profile</h2>
                <button 
                  onClick={() => logoutWithRedirect()}
                  className={`logout-footer-button`}>
                  <span className="button-text">Logout</span>
                </button>
                </div>
              )}
            </div>
          </div>
          ) : ( 
            <h2 className="explore-landing__title typography-large-title-emphasized">Your&nbsp;Profile</h2>
          )}
          
          {isMoviesLoading && (
            <div className={`shelf-grid shelf-grid--onhover snap-shelf`}>
              <div className="shelf-grid__header">
                {/* If similar movies exist display title div: */}
                <div className="shelf-header--with-see-all">
                  <h2 className="typ-headline-emph">Loading</h2>
                  <span className="typ-subhead shelf-subheader clr-secondary-text">Loading movies for you...</span>
                </div>
              </div>
            </div>
          )}

          {group1Shelves.map(({ key, title, subtitle, movies }, index) => (
            <React.Fragment key={key}>
              <MovieShelf
                title={title}
                subtitle={subtitle}
                movies={movies}
                onMovieSelect={handleMovieSelect}
                isFirstShelf={index === 0}
                displayUserScore={true}
                userScores={userScores}
                userId={user?.sub}
                seenMoviesProp={seenMovies}
                watchlistProp={watchlistMovies}
              />
            </React.Fragment>
          ))}

          <UserStats/>

          <div className="product-footer secondary-bg-color" style={{marginTop: "20px", borderRadius: "0px"}}>
            <div className="product-footer__container" style={{ padding: "0 20px" }}>
              <>
              <div className="search-landing__header" style={{paddingBottom: "20px"}}>
                <h2 className="review-card__title typ-headline-emph">Import Letterboxd Ratings</h2>
                <div className="search-header__search search-header__search-page"> 
                  <label htmlFor="search-header-form-input-box" id="search-header-form-input-label" className="search-header__form-input-label">
                      Import Letterboxd Ratings
                  </label>
                  {/* <form > */}
                    <div className="search-header__search-input-wrapper">
                        <svg height="24" viewBox="0 0 64 64" width="24" xmlns="http://www.w3.org/2000/svg" className="search-header__search-icon" aria-hidden="true">
                          <path d="m26.72 50.414c5.205 0 10.005-1.683 13.932-4.488l14.773 14.773c.686.686 1.59 1.028 2.556 1.028 2.026 0 3.46-1.558 3.46-3.553 0-.935-.312-1.807-.998-2.493l-14.68-14.71c3.086-4.052 4.925-9.07 4.925-14.524 0-13.184-10.784-23.968-23.967-23.968-13.153 0-23.968 10.753-23.968 23.968 0 13.183 10.784 23.967 23.968 23.967zm0-5.174c-10.285 0-18.793-8.508-18.793-18.793 0-10.286 8.508-18.794 18.794-18.794 10.285 0 18.793 8.508 18.793 18.794 0 10.285-8.508 18.793-18.793 18.793z">
                          </path>
                        </svg>
                        <input onChange={(e) => setLetterboxdUsername(e.target.value)} name="query" placeholder="Letterboxd Username" autoComplete="off" className="search-header__search-input " id="search-header-form-input-box" type="search"/>
                    </div>
                    <button aria-label="Clear text" className="search-header__button search-header__button--close" type="reset">
                        <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg" className="search-header__close-icon"><path d="M7 14c3.83 0 7-3.177 7-7 0-3.83-3.177-7-7.007-7C3.171 0 0 3.17 0 7c0 3.823 3.177 7 7 7ZM4.694 9.882a.562.562 0 0 1-.563-.57c0-.15.055-.294.165-.397l1.901-1.908-1.9-1.901a.55.55 0 0 1-.166-.398c0-.323.247-.563.563-.563.158 0 .281.055.391.158L7 6.21l1.928-1.915a.52.52 0 0 1 .392-.165c.315 0 .57.247.57.563a.53.53 0 0 1-.172.405L7.81 7.007l1.9 1.9a.524.524 0 0 1 .172.406.57.57 0 0 1-.576.57.543.543 0 0 1-.405-.165L7 7.81 5.106 9.718a.57.57 0 0 1-.412.164Z"></path></svg>
                    </button>
                    <button onClick={handleImportMovies} className='logout-footer-button' style={{marginBottom: "10px", marginLeft: "auto", marginRight: "auto"}}>Import</button>
                    
                    <button onClick={handleResetSeenMovies} className='logout-footer-button' style={{marginBottom: "10px", marginLeft: "auto", marginRight: "auto"}}>Reset Seen Movies List</button>

                    {isLoading2 && <p style={{padding: "10px", textAlign: "center"}}>Importing movies...Just a minute please!</p>}
                    {summary && (
                    <>
                      <div style={{padding: "10px"}}>
                        <h2>Import Summary</h2>
                        <p>Total Movies Imported From Letterboxd: {summary.totalMoviesFromLetterboxd}</p>
                        <p>Imported Movies Matching Curator Movies: {summary.moviesFoundInCurator}</p>
                        <br></br><h4>Movies Added to Seen List With Rating</h4>
                        <p>Count: {summary.moviesAddedToSeenListWithRating.length} Movies</p>
                        <br></br><h4>Movies Just Added to Curator Seen List</h4>
                        <p>Count: {summary.moviesAddedToSeenListNoRating.length} Movies</p>
                        <br></br><h4>Movies Already in Curator Database</h4>
                        <p>Count: {summary.moviesAlreadyExist.length} Movies</p>
                        <br></br><h4>Imported Movies Not Matching Curator Movies</h4>
                        <p>Count: {summary.moviesNotFoundInCurator.length} Movies</p>
                        
                        <br></br><br></br><h2>Import Extended Details</h2>
                        <h4>Movies Added to Seen List With Rating</h4>
                        <p>Count: {summary.moviesAddedToSeenListWithRating.length} Movies</p>
                        <ul>
                          {summary.moviesAddedToSeenListWithRating.map((movie, index) => (
                            <li key={`withRating-${index}`}>{movie.title} - Rating: {movie.rating}</li>
                          ))}
                        </ul>

                        <br></br><h4>Movies Just Added to Seen List</h4>
                        <p>Count: {summary.moviesAddedToSeenListNoRating.length} Movies</p>
                        <ul>
                          {summary.moviesAddedToSeenListNoRating.map((title, index) => (
                            <li key={`noRating-${index}`}>{title}</li>
                          ))}
                        </ul>

                        <br></br><h4>Movies Already in Curator Database</h4>
                        <p>Count: {summary.moviesAlreadyExist.length} Movies</p>
                        <ul>
                          {summary.moviesAlreadyExist.map((title, index) => (
                            <li key={`alreadyExist-${index}`}>{title}</li>
                          ))}
                        </ul>

                        <br></br><h4>Movies Not Found in Curator Database</h4>
                        <p>Count: {summary.moviesNotFoundInCurator.length} Movies</p>
                        <ul>
                          {summary.moviesNotFoundInCurator.map((title, index) => (
                            <li key={`notFound-${index}`}>{title}</li>
                          ))}
                        </ul>
                      </div>
                    </>
                    )}
 
                </div>
              </div>
              </>
            </div>
          </div>

          {/* <h2 className="explore-landing__title typography-large-title-emphasized">Your Filters</h2>
            {showYearDropdown && (
                <div ref={yearDropdownRef} className={`dropdown-menu explore-dropdown-menu ${showYearDropdown ? 'visible' : ''}`}>
                  <button onClick={() => toggleYearFilter('All')} className="dropdown-item">
                    Select All Years
                  </button>
                  <button onClick={() => toggleYearFilter('Clear')} className="dropdown-item">
                    Clear
                  </button>
                  <hr className="hr-line" />
                  {decades.map(decade => (
                    <button onClick={() => toggleYearFilter(decade)} className="dropdown-item" key={decade}>
                      {selectedYears.has(decade) && <CheckmarkSmall className="checkmark-icon" />}
                      <span className="dropdown-text">{`${decade} (${decadeCounts[decade]})`}</span>
                    </button>
                  ))}
                </div>
              )}
            {showLangDropdown && (
                <div ref={langDropdownRef} className={`dropdown-menu explore-dropdown-menu ${showLangDropdown ? 'visible' : ''}`}>
                  <button onClick={() => toggleLangFilter('All')} className="dropdown-item">
                    Select All Languages
                  </button>
                  <button onClick={() => toggleLangFilter('Clear')} className="dropdown-item">
                    Clear
                  </button>
                  <hr className="hr-line" />
                  {langList.map(lang => (
                    <button onClick={() => toggleLangFilter(lang)} className="dropdown-item" key={lang}>
                      {selectedLangs.has(lang) && <CheckmarkSmall className="checkmark-icon" />}
                      <span className="dropdown-text">{`${lang} (${langCount[lang]})`}</span>
                    </button>
                  ))}
                </div>
              )}
              {showStreamingDropdown && (
                <div ref={streamingDropdownRef} className={`dropdown-menu explore-dropdown-menu ${showStreamingDropdown ? 'visible' : ''}`}>
                  <button onClick={() => toggleStreamingServiceFilter('All')} className="dropdown-item">
                    Select All Watch Options
                  </button>
                  <button onClick={() => toggleStreamingServiceFilter('Clear')} className="dropdown-item">
                    Clear
                  </button>
                  <hr className="hr-line" />
                  {streamingServices.map(service => (
                    <button onClick={() => toggleStreamingServiceFilter(service)} className="dropdown-item" key={service}>
                      {selectedStreamingServices.has(service.toLowerCase()) && <CheckmarkSmall className="checkmark-icon" />}
                      <span className="dropdown-text">{`${capitalizeFirstLetter(service)} (${serviceCounts[service]})`}</span>
                    </button>
                  ))}
                </div>
              )}
              {showGenreDropdown && (
                <div ref={genreDropdownRef} className={`dropdown-menu explore-dropdown-menu ${showGenreDropdown ? 'visible' : ''}`}>
                  <button onClick={() => toggleGenreFilter('All')} className="dropdown-item">
                  Select All Genres
                  </button>
                  <button onClick={() => toggleGenreFilter('Clear')} className="dropdown-item">
                    Clear
                  </button>
                  <hr className="hr-line" />
                  {genreList.map(genre => (
                    <button onClick={() => toggleGenreFilter(genre)} className="dropdown-item" key={genre}>
                      {selectedGenres.has(genre.toLowerCase()) && <CheckmarkSmall className="checkmark-icon" />}
                      <span className="dropdown-text">{`${genre} (${genreCount[genre]})`}</span>
                    </button>
                  ))}
                </div>
              )}
              {showRatedUnratedDropdown && (
                <div ref={seenDropdownRef} className={`dropdown-menu explore-dropdown-menu ${showRatedUnratedDropdown ? 'visible' : ''}`}>
                  <button onClick={() => toggleRatedUnrated('all')} className="dropdown-item">
                    {showRated === 'all' && <CheckmarkSmall className="checkmark-icon" />}
                    <span className="dropdown-text">Select All Movies</span>
                  </button>
                  <hr className="hr-line" />
                  <button onClick={() => toggleRatedUnrated('rated')} className="dropdown-item">
                    {showRated === 'rated' && <CheckmarkSmall className="checkmark-icon" />}
                    <span className="dropdown-text">Rated Movies ({ratedMoviesCount})</span>
                  </button>
                  <button onClick={() => toggleRatedUnrated('unrated')} className="dropdown-item">
                    {showRated === 'unrated' && <CheckmarkSmall className="checkmark-icon" />}
                    <span className="dropdown-text">Unrated Movies ({unratedMoviesCount})</span>
                  </button>
                </div>
              )} */}

            {/* <div className={`filter-container filter-container-explore ${showYearDropdown || showStreamingDropdown || showLangDropdown || showGenreDropdown || showRatedUnratedDropdown ? 'filter-container-expanded' : ''}`}>
                <div className="dropdown">
                  <button 
                    onClick={clearAllFilters} 
                    className={`dropdown-button clear-all`}>
                    <span className="button-text">
                      {filtersApplied ? 'Filters On' : 'Filters Off'}
                      </span>
                  </button>
                </div>
                <div className="dropdown">
                <button 
                  onClick={toggleYearDropdown} 
                  className={`dropdown-button ${isYearFilterActive() ? 'active' : ''}`}>
                  <span className="button-text">Year</span>
                  <DropDown className="dropdown-icon" />
                </button>
                </div>
                <div className="dropdown">
                <button 
                  onClick={toggleStreamingDropdown} 
                  className={`dropdown-button ${isStreamingFilterActive() ? 'active' : ''}`}>
                  <span className="button-text">Streaming</span>
                  <DropDown className="dropdown-icon" />
                </button>
                </div>
                <div className="dropdown">
                <button 
                  onClick={toggleGenreDropdown} 
                  className={`dropdown-button ${isGenreFilterActive() ? 'active' : ''}`}>
                  <span className="button-text">Genre</span>
                  <DropDown className="dropdown-icon" />
                </button>
                </div>
                <div className="dropdown">
                <button 
                  onClick={() => toggleRatedUnratedDropdown()} 
                  className={`dropdown-button ${isRatedUnratedFilterActive() ? 'active' : ''}`}>
                  <span className="button-text">{showRated.charAt(0).toUpperCase() + showRated.slice(1)}</span>
                  <DropDown className="dropdown-icon" />
                </button>
                </div>
                <div className="dropdown">
                <button 
                  onClick={toggleLangDropdown} 
                  className={`dropdown-button ${isLangFilterActive() ? 'active' : ''}`}>
                  <span className="button-text">Language</span>
                  <DropDown className="dropdown-icon" />
                </button>
                </div>


              </div> */}

            {/* {group2Shelves.map(({ key, title, subtitle, movies }, index) => (
              <React.Fragment key={key}>
                <MovieShelf
                  title="Your Favorites Filtered"
                  subtitle="Sorted by Your Score"
                  movies={yourFavoritesRatedFilter}
                  onMovieSelect={handleMovieSelect}
                  isFirstShelf={index === 0}
                  displayUserScore={true}
                  userScores={userScores}
                />
              </React.Fragment>
            ))} */}

            {/* <div className="global-footer typ-footnote" style={{display: "flex"}}>
              <div className="global-footer__left" style={{marginLeft: "auto", marginRight: "auto"}}>
                {isAuthenticated && (
                  <div className="">
                  <button 
                    onClick={() => logoutWithRedirect()}
                    className={`logout-footer-button`}>
                    <span className="button-text">Logout</span>
                  </button>
                  </div>
                )}
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </main>
  );
};

export default Profile2;