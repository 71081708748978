import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation, Link } from 'react-router-dom';
import "./style.css";
import "./style2.css";
import { ReactComponent as DropDown } from '../assets/chevron.down.svg';

const NavBar = () => {
  const { user, isAuthenticated, loginWithRedirect, logout } = useAuth0();
  const location = useLocation();

  const [currentPage, setCurrentPage] = useState('');

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  const logoutWithRedirect = () =>
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      }
  });

  useEffect(() => {
    switch (location.pathname) {
      case '/':
        setCurrentPage('Home');
        break;
      case '/search':
        setCurrentPage('Search');
        break;
      case '/explore':
        setCurrentPage('Explore');
        break;
      default:
        setCurrentPage('Home');
        break;
    }
  }, [location]);

  return (

    <div className="nav-header menu-closing">

    {/* //  <div className="iphone-pro-max nav-bar-display">
    //    <div className="overlap-wrapper">
    //      <div className="overlap">
    //        <div className="group-13">
    //         <div className="overlap-2">
    //           <div className="group-14">
    //             <div className="text-wrapper-7 typ-headline-emph">Curator | Watchlist</div>
    //           </div>
    //           <div className="group-15" />
    //         </div> */}
               <div className="nav-header__wrapper">
                <div className="nav-header__content">
                  
              {/*  

            <div className="nav-header__user-controls">
              <div className="search-header__search nav-header__desktop">
                <div className="search-header__search search-header__search-page">
                  <label for="search-header-form-input-box" id="search-header-form-input-label" className="search-header__form-input-label">
                      Search
                  </label>
                  <form role="search">
                    <div className="search-header__search-input-wrapper"> 
                      <svg height="24" viewBox="0 0 64 64" width="24" xmlns="http://www.w3.org/2000/svg" className="search-header__search-icon" aria-hidden="true">
                        <path d="m26.72 50.414c5.205 0 10.005-1.683 13.932-4.488l14.773 14.773c.686.686 1.59 1.028 2.556 1.028 2.026 0 3.46-1.558 3.46-3.553 0-.935-.312-1.807-.998-2.493l-14.68-14.71c3.086-4.052 4.925-9.07 4.925-14.524 0-13.184-10.784-23.968-23.967-23.968-13.153 0-23.968 10.753-23.968 23.968 0 13.183 10.784 23.967 23.968 23.967zm0-5.174c-10.285 0-18.793-8.508-18.793-18.793 0-10.286 8.508-18.794 18.794-18.794 10.285 0 18.793 8.508 18.793 18.794 0 10.285-8.508 18.793-18.793 18.793z">
                        </path>
                      </svg>
                      <input type="search" id="search-header-form-input-box" className="search-header__form-input-box" placeholder="Search" aria-labelledby="search-header-form-input-label" />
                    </div>
                    <button type="reset" className="search-header__button search-header__button--close" aria-label="Clear text">
                      <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg" className="search-header__close-icon"><path d="M7 14c3.83 0 7-3.177 7-7 0-3.83-3.177-7-7.007-7C3.171 0 0 3.17 0 7c0 3.823 3.177 7 7 7ZM4.694 9.882a.562.562 0 0 1-.563-.57c0-.15.055-.294.165-.397l1.901-1.908-1.9-1.901a.55.55 0 0 1-.166-.398c0-.323.247-.563.563-.563.158 0 .281.055.391.158L7 6.21l1.928-1.915a.52.52 0 0 1 .392-.165c.315 0 .57.247.57.563a.53.53 0 0 1-.172.405L7.81 7.007l1.9 1.9a.524.524 0 0 1 .172.406.57.57 0 0 1-.576.57.543.543 0 0 1-.405-.165L7 7.81 5.106 9.718a.57.57 0 0 1-.412.164Z"></path></svg>
                    </button>
                  </form>
                  <ul className="search-hints is-hidden" role="listbox" aria-label="Search results">
                  </ul>
                </div>
              </div>
              <div className="nav-header-auth">
                <div className="ember-basic-dropdown">
                  <div className="ember-view ember-basic-dropdown-trigger ember-basic-dropdown-trigger--in-place nav-header__user-image__wrapper">
                    <img>
                    </img>
                  </div>
                  <div className="ember-basic-dropdown-content-placeholder"></div>
                </div>
              </div>
            </div>

            {/* </div> */}
            {/* </div>
            </div> */}

            <div className="nav-header__dropdown-menu">
              {/* <a href="/search" dir="ltr">
                Search
              </a> */}
              <nav className="nav-header__dropdown-menu-trigger" >
                <div className="nav-header__link">
                  <a href="/search" dir="ltr" style={{ color: 'var(--keyColor)' }}>
                    <span>Search</span>
                  </a>
                </div>
              </nav>
            </div>

            <div className="nav-header__mobile-solo-tab typ-headline-emph">
              <a href="/" dir="ltr">
                <span className="cc-modal__apple-icon-nav-small"></span>
              </a>
            </div>

            <nav className="nav-header__desktop-links nav-header__desktop">
              <ul className="nav-header__desktop-links-list">
                <li>
                  <a href="/explore" className="nav-header__link--active nav-header__link">
                    <span className="cc-modal__apple-icon-nav-big"></span>
                  </a>
                </li>
                {/* <li>
                  <a href="/search" className="nav-header__link">
                   <span className="tab__title" dir="ltr">Search</span>
                  </a>
                </li> */}
              </ul>
            </nav>

            <div className="nav-header__user-controls">
              {/* <button onClick={toggleDropdown} className="dropdown-button" style={{ color: "var(--systemPrimary-onDark)"}}> */}
                {/* <span className="button-text">{currentPage}</span> */}
                {/* <a href="/explore" className="dropdown-item-nav">
                  Explore
                </a>
                <a href="/search" className="dropdown-item-nav">
                  Search
                </a> */}

                  {/* <div className="nav-header__link" onClick={() => loginWithRedirect()} style={{ color: 'var(--keyColor)' }}>
                    <a dir="ltr">
                      <span>Profile&nbsp;</span>
                    </a>
                  </div> */}

                {!isAuthenticated && (
                  <div className="nav-header__link" onClick={() => loginWithRedirect()} style={{ color: 'var(--keyColor)' }}>
                    <a dir="ltr" style={{ color: 'var(--keyColor)' }}>
                      <span>Login</span>
                    </a>
                  </div>
                )}
                {/* {isAuthenticated && (
                  <div className="nav-header__link" onClick={() => logoutWithRedirect()}>
                    <a dir="ltr">
                      <span>Logout</span>
                    </a>
                  </div>
                )} */}
                {isAuthenticated && (
                  <Link to={`/profile/${user.nickname}`} className="nav-header__link" style={{ color: 'var(--keyColor)' }}>
                    My Profile&nbsp;&nbsp;
                  </Link>
                )}
{/*                 
                {!isAuthenticated && (
                    <div className="dropdown-item-nav" onClick={() => loginWithRedirect()}>Login</div>
                  )}
                  {isAuthenticated && (
                    <a className="dropdown-item-nav" onClick={() => logoutWithRedirect()}>
                      Logout */}
                      {/* <img
                        src={user.picture}
                        alt="Profile"
                        className="rounded-circle"
                        width="22"
                      /> */}
                    {/* </a>
                  )} */}
                {/* <DropDown className="dropdown-icon" /> */}
              {/* {dropdownOpen && (
                <div className="dropdown-menu nav-dropdown-menu">
                  <a href="/search" className="dropdown-item-nav">Search</a>
                  <a href="/explore" className="dropdown-item-nav">Explore</a>
                  {!isAuthenticated && (
                    <div className="dropdown-item-nav" onClick={() => loginWithRedirect()}>Log in</div>
                  )}
                  {isAuthenticated && (
                    <div className="dropdown-item-nav" onClick={() => logoutWithRedirect()}>
                      Log out
                      <img
                        src={user.picture}
                        alt="Profile"
                        className="rounded-circle"
                        width="22"
                      />
                    </div>
                  )}
                </div>
              )} */}
            </div>


            


    {/* </div> */}
         </div>
    </div>

    </div>
  );
};

export default NavBar;
