// src/analytics.js

// <!-- Google tag (gtag.js) -->
// <script async src="https://www.googletagmanager.com/gtag/js?id=G-NHJQ7DTKVW"></script>
// <script>
//   window.dataLayer = window.dataLayer || [];
//   function gtag(){dataLayer.push(arguments);}
//   gtag('js', new Date());

//   gtag('config', 'G-NHJQ7DTKVW');
// </script>

import ReactGA from 'react-ga4';

const TRACKING_ID = "G-NHJQ7DTKVW"; // Replace with your Google Analytics tracking ID

export const initGA = () => {
  ReactGA.initialize(TRACKING_ID);
};

export const PageView = () => {
  ReactGA.send({ hitType: "pageview", page: window.location.pathname });
};

// Optional: You can also create event trackers
export const Event = ({category, action, label}) => {
  ReactGA.event({
    category: category,
    action: action,
    label: label,
  });
};
