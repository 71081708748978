import { Link } from 'react-router-dom';
import React, { useEffect, useState } from "react";

const ListMenu = () => {
  let apiBaseUrl = "";
  if (process.env.REACT_APP_NODE_ENV === "development") {
    apiBaseUrl = "http://localhost:3001";
  } else {
    apiBaseUrl = "";
  }
  const decades = [
    { year: 2020, movieCount: 56 },
    { year: 2010, movieCount: 336 },
    { year: 2000, movieCount: 260 },
    { year: 1990, movieCount: 135 },
    { year: 1980, movieCount: 83 },
    { year: 1970, movieCount: 45 },
    { year: 1960, movieCount: 36 },
    { year: 1950, movieCount: 27 },
    { year: 1940, movieCount: 14 },
    { year: 1930, movieCount: 7 },
  ];
  decades.sort((a, b) => b.year - a.year);
  const [isLoading, setIsLoading] = useState(false);


  return (
    <div className="overlap-wrapper">
      <div className="iphone-pro-max">
        <div></div>
        <div className="overlap">
          <div className="top3-container-display">
            <div className="div landing-page-with-nav-header">
              <div className="movie-wrapper-div">
                <h2>Movie Lists by Decade</h2>
                {isLoading ? (
                  <p>Loading movies...</p>
                ) : (
                  decades.map((decade, index) => (
                    <Link
                      to={`/lists/${decade.year}`}
                      key={decade.year}
                      style={{ textDecoration: "none" }}
                    >
                      <div className="group-wrapper" key={decade}>
                        <div className="group-2">
                          <div className="group-3">
                            <div className="text-wrapper-3">
                              <span>{decade.year}s • Top Movies</span>
                            </div>
                            <div className="text-wrapper-4">
                              <strong>{decade.movieCount} </strong>
                              Movies
                            </div>
                          </div>
                          <div className="movie-poster">
                            <img
                              src={"https://via.placeholder.com/60"}
                              
                              // src={"https://image.tmdb.org/t/p/w1280/acvE3RWjDLgvbL2RtcyzkrsAyNV.jpg"}
                              decoding="async"
                              loading="lazy"
                              className="movie-poster-img"
                              alt={`Top Movies of the ${decade.year}s`}
                              role="presentation"
                              style={{ width: "60px", height: "60px" }}
                            />
                          </div>

                          <div className="movie-rank-cont">
                            <div className="movie-rank-pos">
                              <span>{index + 1}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListMenu;
