import React from "react";
import { useAuth0 } from '@auth0/auth0-react';

const FooterUpsell = () => {
    const {loginWithRedirect} = useAuth0();

    return (
        <div id="footer-upsell-banner-hole">
            <div className="upsell-banner clr-primary-text-on-dark" aria-hidden="false">
                <div className="upsell-banner__text-wrapper">
                    <div className="upsell-banner__title">
                        It's All Good.&nbsp;
                        <span className="responsive-break">Only on Curator.</span>
                    </div>
                    <div className="upsell-banner__text clr-secondary-text-on-dark">
                        <span>Login and start rating movies now to improve your Curator Picks.&nbsp;
                            {/* <a className="clr-primary-text-on-dark">
                                See offer details.
                            </a> */}
                        </span>
                    </div>
                </div>
                <div className="upsell-banner__button-wrapper">
                    <button className="upsell-banner__button typ-subhead-emph cursor-pointer" type="button" onClick={() => loginWithRedirect()}>
                        Login For Free
                    </button>
                    <p className="upsell-banner__button-text typ-footnote">
                        Curated For You.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default FooterUpsell;
