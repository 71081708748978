import React from "react";
import loading from "../assets/loading.svg";

const Loading = () => (
  // <div className="spinner">
  //   <img src={loading} alt="Loading" />
  // </div>
  <div className="loading-screen" >
    <div className="loading-text">Loading movies for you...</div>
  </div>
);

export default Loading;
