import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, Switch } from "react-router-dom"; // Import Routes instead of Switch
import { initGA, PageView } from './analytics';
import { Container } from "reactstrap";
import Loading from "./components/Loading";
import NavBar from "./components/NavBar";
import HomeScroller from "./components/HomeScroller";
import Home from "./views/Home";
import Profile from "./views/Profile";
import Profile2 from "./views/Profile2";
import ExternalApi from "./views/ExternalApi";
import { useAuth0 } from "@auth0/auth0-react";
import history from "./utils/history";
import Explore from "./views/Explore";
import MoviePair from "./components/MoviePair";
import FooterUpsell from "./components/FooterUpsell";
import ListMenu from "./components/ListMenu";
import ListDetail from "./components/ListDetail";
import MovieDetailPage from "./components/MovieDetailPage";
import { AuthenticationGuard } from "./components/authentication-guard";

// styles
import "./App.css";
// fontawesome
import initFontAwesome from "./utils/initFontAwesome";
initFontAwesome();

const App = () => {
  const { isLoading, error, isAuthenticated, user } = useAuth0();

  const metadataOptions = {
    Genres: ["Action", "Drama", "Comedy"],
    Decades: ["1990", "2000", "2010"],
    Directors: ["Steven Spielberg", "Christopher Nolan"],
    Streaming: ["Netflix", "Hulu", "Amazon Prime"],
  };

  useEffect(() => {
    initGA();
    PageView();
  }, []);

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Router history={history}>
      <div id="app" className="d-flex flex-column h-100">
        <NavBar />
        <Container className="flex-grow-1 no-scroll-fullscreen">
          <Routes>
            <Route path="/" element={<Explore />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/gamer" element={<MoviePair />} />
            <Route path="/explore" element={<Explore />} />
            <Route path="/home" element={<HomeScroller />} />
            {/* <Route path="/explore/:movieId" element={<Explore />} /> */}
            <Route path="/explore/:tmdbIdSlug" element={<Explore />} />
            <Route path="/movie/:movieId" element={ <MovieDetailPage/>} />
            <Route path="/movie/:movieId" element={<Explore />} />
            <Route path="/search" element={<Home />} />
            <Route path="/search/:movieId" element={<Home />} />
            <Route path="/lists" element={<ListMenu />} />
            <Route path="/lists/:decade" element={<ListDetail />} />
            <Route
              path="/profile/:userId"
              element={
                isAuthenticated ? <Profile2 /> : <Navigate to="/" replace />
              }
            />
            <Route
              path="/profile/:userId/:movieId"
              element={
                isAuthenticated ? <Profile2 /> : <Navigate to="/" replace />
              }
            />
          </Routes>
        </Container>
        {!isAuthenticated ? <FooterUpsell /> : null}
      </div>
    </Router>
  );
};

export default App;