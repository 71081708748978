import React from 'react';
import Helmet from 'react-helmet';

const MovieSchema = ({ movie }) => {
  const baseUrl = "https://www.curatorwatchlist.com/explore/";
  const totalRatings = movie.Rarity + (movie.LetterCount || 0);
  const imageUrl = 
  movie.backdrop_path ? `https://image.tmdb.org/t/p/w1280/${movie.backdrop_path}` : 
  movie.poster_path ? `https://image.tmdb.org/t/p/w1280/${movie.poster_path}` : 
  `https://image.tmdb.org/t/p/w1280/9EbRukoEPkdRaXI73H3ufbga6tH.jpg`;

  const pageUrl = `${baseUrl}${movie._id}`;
  
  // Format runtime from seconds to ISO 8601 duration (e.g., PT2H34M)
  const formattedRuntime = `PT${Math.floor(movie.Runtime / 3600)}H${Math.floor((movie.Runtime % 3600) / 60)}M`;

  return (
    <Helmet>
      {/* Standard HTML Tags */}
      <title>{`${movie.Title} - Curator Watchlist`}</title>
      <meta name="description" content={movie.overview} />
      <link rel="canonical" href={pageUrl} />

      {/* Open Graph Tags */}
      <meta property="og:url" content={pageUrl} />
      <meta property="og:type" content="video.movie" />
      <meta property="og:title" content={`${movie.Title} - ${movie.CuratorScore} Score`} />
      <meta property="og:description" content={movie.overview} />
      <meta property="og:image" content="https://image.tmdb.org/t/p/w1280/9EbRukoEPkdRaXI73H3ufbga6tH.jpg" />
      <meta property="og:image:width" content="1280" />
      <meta property="og:image:height" content="720" />
      
      {/* Twitter Card Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={movie.Title} />
      <meta name="twitter:description" content={movie.overview} />
      <meta name="twitter:image" content={imageUrl} />

      {/* Schema.org JSON-LD */}
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "Movie",
          "name": movie.Title,
          "url": pageUrl,
          "image": imageUrl,
          "description": movie.overview,
          "datePublished": movie.release_date,
          "genre": movie.Genre.split(', '),
          "director": movie.Director.map(name => ({
            "@type": "Person",
            "name": name
          })),
          "actor": movie.Stars.map(name => ({
            "@type": "Person",
            "name": name
          })),
          "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": movie.CuratorScore.toString(),
            "ratingCount": totalRatings.toString()
          },
          "contentRating": movie.Certified,
          ...(movie.youtubeTrailerId && {
            "trailer": {
              "@type": "VideoObject",
              "name": `${movie.Title} Trailer`,
              "embedUrl": `https://www.youtube.com/embed/${movie.youtubeTrailerId}`
            }
          }),
          "titleEIDR": movie.EIDR,
          "workPresented": {
            "@type": "Movie",
            "name": movie.Title,
            "sameAs": `www.imdb.com/title/${movie.Const}/`
          },
          "keywords": movie.keywords.join(', '),
          ...(movie.SpokenLanguages && {
          "inLanguage": movie.SpokenLanguages.join(', ')
          }),
          ...(movie.countriesOfOrigin && {
            "inLanguage": movie.countriesOfOrigin.countries.map(country => country.text).join(', ')
            }),
          "timeRequired": formattedRuntime,
        }, null, 2)}
      </script>
    </Helmet>
  );
};

export default MovieSchema;
