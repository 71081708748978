// streamingServiceIcons.js
const StreamingURL = {
  Buy: "https://www.amazon.com", // Placeholder, consider using a specific URL or a search parameter
  Max: "https://play.max.com/",
  "Disney+": "https://www.disneyplus.com/",
  Paramount: "https://www.paramountplus.com/",
  Netflix: "https://www.netflix.com/",
  Prime: "https://www.amazon.com/Amazon-Video/b?ie=UTF8&node=2858778011",
  Hulu: "https://www.hulu.com/",
  Peacock: "https://www.peacocktv.com/",
  Freevee: "https://www.amazon.com/adlp/freevee",
  Pluto: "https://pluto.tv/",
  AMC: "https://www.amc.com/",
  STARZ: "https://www.starz.com/us/en/",
  Cinemax: "https://www.cinemax.com/",
  MGM: "https://www.mgm.com/", // MGM might not have a direct streaming platform; adjust as necessary
  FXNOW: "https://www.fxnetworks.com/fxnow",
  Apple: "https://tv.apple.com/",
  TBS: "https://www.tbs.com/",
  The: "https://www.theplatform.com/", // This might need correction
  Bravo: "https://www.bravotv.com/",
  ABC: "https://abc.com/",
  Tribeca: "https://www.tribecafilm.com/", // Adjust if there's a specific streaming URL
  Tubi: "https://tubitv.com/",
  MUBI: "https://mubi.com/",
  Magnolia: "https://www.magnoliaselects.com/", // Adjust if necessary
  Plex: "https://www.plex.tv/",
  Crackle: "https://www.crackle.com/",
  Crunchyroll: "https://www.crunchyroll.com/",
  Freeform: "https://www.freeform.com/",
  Shout: "https://www.shoutfactorytv.com/",
  Sundance: "https://www.sundance.org/", // Adjust if there's a specific streaming URL
  Dox: "https://www.dox.com/", // Placeholder, adjust if necessary
};

export default StreamingURL;
