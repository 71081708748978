import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import "./style.css";
import { Helmet } from "react-helmet";
import { ReactComponent as SearchIcon } from "../assets/search-icon.svg";
import { ReactComponent as DropDown } from "../assets/chevron.down.svg";
import { ReactComponent as CheckmarkSmall } from "../assets/check-green.svg";
import MovieDetailPopup from "./MovieDetailPopup";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Loading from "./Loading";

const MovieList2 = () => {
  let apiBaseUrl = "";

  // console.log(`ENV: ${process.env.REACT_APP_NODE_ENV}`);
  if (process.env.REACT_APP_NODE_ENV === "development") {
    // Use the development base URL
    apiBaseUrl = "http://localhost:3001";
  } else {
    // Use the production base URL (empty string for relative URL)
    apiBaseUrl = "";
  }
  const navigate = useNavigate();
  const location = useLocation();
  const [isMoviesLoading, setIsMoviesLoading] = useState(true); // State for movie loading

  const { movieId } = useParams(); // Assuming your route parameter is named `movieId`

  // const apiUrl = process.env.REACT_APP_API_URL || '';
  const {
    user,
    getAccessTokenSilently,
    isAuthenticated,
    loginWithRedirect,
    logout,
    isLoading,
  } = useAuth0();
  // const { user, getAccessTokenSilently } = useAuth0();
  const [movies, setMovies] = useState([]);
  const [seenMovies, setSeenMovies] = useState(new Set());
  const [showSearchInput, setShowSearchInput] = useState(false);
  const searchInputRef = useRef(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [showFiltered, setShowFiltered] = useState(false);
  const [debouncedQuery, setDebouncedQuery] = useState(searchQuery);
  const [userScores, setUserScores] = useState({});

  const [showYearDropdown, setShowYearDropdown] = useState(false);
  const [showStreamingDropdown, setShowStreamingDropdown] = useState(false);
  const toggleYearDropdown = () => setShowYearDropdown((prev) => !prev);
  // const toggleStreamingDropdown = () => setShowStreamingDropdown(prev => !prev);
  // Add a new state for tracking displayed movies count
  const [displayCount, setDisplayCount] = useState(20);

  const [selectedYears, setSelectedYears] = useState(new Set());
  const [selectedStreamingServices, setSelectedStreamingServices] = useState(
    new Set()
  );
  const yearDropdownRef = useRef(null);
  const streamingDropdownRef = useRef(null);
  const seenDropdownRef = useRef(null);
  const [selectedGenres, setSelectedGenres] = useState(new Set());
  const [showRated, setShowRated] = useState("all"); // 'all', 'rated', or 'unrated'
  const [showRatedUnratedDropdown, setShowRatedUnratedDropdown] =
    useState(false);
  const toggleRatedUnratedDropdown = () =>
    setShowRatedUnratedDropdown((prev) => !prev);
  const [showGenreDropdown, setShowGenreDropdown] = useState(false);
  const genreDropdownRef = useRef(null);
  const toggleGenreDropdown = () => setShowGenreDropdown((prev) => !prev);
  const [watchlistMovies, setWatchlistMovies] = useState(new Set());
  const [contentType, setContentType] = useState("movies"); // Initial state is 'movies'

  const ratedMoviesCount = Object.keys(userScores).length;
  // const unratedMoviesCount = movies.length - ratedMoviesCount;
  const divRef = useRef(null);
  const [selectedMovie, setSelectedMovie] = useState(null);

  const openMoviePopup = (movie) => {
    setSelectedMovie(movie);
  };

  // const closePopup = () => {
  //   setSelectedMovie(null);
  // };

  const closePopup = () => {
    navigate(`/search`); // Navigate back to the explore base route
    setSelectedMovie(null); // Close the popup
  };

  const handleMovieSelect = (movie) => {
    navigate(`/search/${movie._id}`);
    setSelectedMovie(movie);
  };

  const handleSimilarMovieSelect = (similarMovieId) => {
    const newSelectedMovie = movies.find(
      (movie) => movie._id === similarMovieId
    );
    if (newSelectedMovie) {
      setSelectedMovie(newSelectedMovie);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch movies
        // console.log(`${apiBaseUrl}`);
        // console.log(`${process.env.NODE_ENV2}`);
        // const moviesResponse = await axios.get(`${apiBaseUrl}/api/movies`);
        // let fetchedMovies = moviesResponse.data;
        const response = await axios.get(
          `${apiBaseUrl}/api/content?type=movies`
        );
        // const fetchedContent = response.data;
        // fetchedContent.sort((a, b) => b.CuratorScore - a.CuratorScore);
        // setMovies(fetchedContent);
        // fetchContent();
        let fetchedMovies = response.data;
        // Sort movies by curator score in descending order
        fetchedMovies.sort((a, b) => b.CuratorScore - a.CuratorScore);
        setMovies(fetchedMovies);
        setIsMoviesLoading(false);

        // Fetch seen movies and user scores
        if (isAuthenticated && user && user.sub) {
          const seenMoviesResponse = await axios.get(
            `${apiBaseUrl}/api/user/seenMovies`,
            {
              headers: {
                "user-id": user.sub,
              },
            }
          );

          const watchlistMoviesResponse = await axios.get(
            `${apiBaseUrl}/api/user/watchlistMovies`,
            {
              headers: {
                // 'Authorization': `Bearer ${accessToken}`,
                "user-id": user.sub,
              },
            }
          );
          const processedWatchlistMovies = new Set();
          watchlistMoviesResponse.data.forEach((movie) => {
            processedWatchlistMovies.add(movie.Const);
          });
          setWatchlistMovies(processedWatchlistMovies);

          const seenMovieIds = new Set(
            seenMoviesResponse.data.map((movie) => movie.Const)
          );
          setSeenMovies(seenMovieIds);

          const userScoresResponse = seenMoviesResponse.data.reduce(
            (acc, movie) => {
              if (movie.userScore) {
                acc[movie.Const] = movie.userScore;
              }
              return acc;
            },
            {}
          );
          setUserScores(userScoresResponse);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsMoviesLoading(false);
      }
    };

    fetchData();
  }, [user, isAuthenticated, getAccessTokenSilently]);

  useEffect(() => {
    // console.log('Location changed:', location.pathname);
    // Function to determine if the current URL is the base explore page without any ID
    const isBaseExplorePage = () => {
      // Split the pathname by '/' and remove any empty strings that may result from trailing slashes
      const pathSegments = location.pathname.split("/").filter(Boolean);

      // Check if the path is exactly '/explore' with no additional segments
      return pathSegments.length === 1 && pathSegments[0] === "search";
    };

    // Close the popup if we're on the base explore page
    if (isBaseExplorePage()) {
      setSelectedMovie(null);
    }
  }, [location]);

  // New login function with console log
  const handleLogin = () => {
    // Construct the redirect URI (this may vary based on your Auth0 setup)
    const redirectUri = `${window.location.origin}/callback`;

    // Log the redirect URI
    console.log("Attempting login with redirect URI:", redirectUri);

    // Call the loginWithRedirect function with the redirectUri
    loginWithRedirect({ redirect_uri: redirectUri });
  };

  const getColorForScore = (score) => {
    let red, green;
    if (score >= 80) {
      // Score is between 80 and 100, blend from yellow to green
      red = Math.round((255 * (100 - score)) / 20); // Decrease red as score approaches 100
      green = 255;
    } else if (score > 60) {
      // Score is between 60 and 80, blend from red to yellow
      red = 255;
      green = Math.round((255 * (score - 60)) / 20); // Increase green as score approaches 80
    } else {
      // Score is 60 or below, display red
      red = 255;
      green = 0;
    }
    return `rgb(${red}, ${green}, 0)`; // Return RGB color string
  };

  // Toggle Streaming Dropdown
  const toggleStreamingDropdown = () => {
    setShowStreamingDropdown((prev) => {
      if (prev) {
        // If the dropdown is currently shown (prev is true), it will now be closed
        console.log("close");
      } else {
        // If the dropdown is currently hidden (prev is false), it will now be opened
        console.log("open");
        // Close other dropdowns when opening this one
        setShowYearDropdown(false);
        setShowGenreDropdown(false);
        setShowRatedUnratedDropdown(false);
      }
      return !prev;
    });
  };

  const getMovieTitleById = (id) => {
    const movie = filteredMovies.find((movie) => movie._id === id);
    return movie ? movie.Title : "Unknown";
  };

  // Load filter state from localStorage on mount
  useEffect(() => {
    const savedYears = new Set(
      JSON.parse(localStorage.getItem("selectedYears")) || []
    );
    const savedStreamingServices = new Set(
      JSON.parse(localStorage.getItem("selectedStreamingServices")) || []
    );
    const savedGenres = new Set(
      JSON.parse(localStorage.getItem("selectedGenres")) || []
    );
    const savedShowRated = localStorage.getItem("showRated") || "all";

    setSelectedYears(savedYears);
    setSelectedStreamingServices(savedStreamingServices);
    setSelectedGenres(savedGenres);
    setShowRated(savedShowRated);
  }, []);

  // Save filter state to localStorage whenever they change
  useEffect(() => {
    localStorage.setItem("selectedYears", JSON.stringify([...selectedYears]));
    localStorage.setItem(
      "selectedStreamingServices",
      JSON.stringify([...selectedStreamingServices])
    );
    localStorage.setItem("selectedGenres", JSON.stringify([...selectedGenres]));
    localStorage.setItem("showRated", showRated);
  }, [selectedYears, selectedStreamingServices, selectedGenres, showRated]);

  // Function to clear all filters and update localStorage
  const clearAllFilters = () => {
    setSelectedYears(new Set());
    setSelectedStreamingServices(new Set());
    setSelectedGenres(new Set());
    setShowRated("all");

    // Clear localStorage
    localStorage.removeItem("selectedYears");
    localStorage.removeItem("selectedStreamingServices");
    localStorage.removeItem("selectedGenres");
    localStorage.removeItem("showRated");
  };

  // Function to handle clicking the "See 10 More" button
  const handleSeeMore = () => {
    setDisplayCount((prevCount) => prevCount + 20);
    // console.log('see more');
    // console.log(displayCount);
  };

  // When searchQuery changes, reset the displayCount to 10
  useEffect(() => {
    setDisplayCount(20);
  }, [searchQuery]);

  // const streamingServices = [
  //   'Buy', 'Max', 'Disney', 'Netflix', 'Prime', 'Paramount',
  //   'Freevee', 'Hulu', 'AMC', 'FXNOW', 'STARZ',
  //   'Showtime', 'Peacock', 'Cinemax', 'TBS', 'Pluto',
  //   'MGM', 'Roku', 'ABC', 'Tribeca', 'Tubi',
  //   'Bravo', 'MUBI', 'Crackle', 'Magnolia', 'Plex',
  //   'Crunchyroll', 'Freeform', 'BritBox', 'Apple', 'Sundance',
  //   'Dox'
  // ];
  const serviceCounts = {
    Buy: 341,
    Max: 118,
    Disney: 70,
    Netflix: 69,
    Prime: 65,
    Paramount: 48,
    AMC: 34,
    Freevee: 32,
    MGM: 27,
    Hulu: 24,
    Peacock: 21,
    Cinemax: 21,
    Pluto: 20,
    STARZ: 19,
    FXNOW: 19,
    Showtime: 19,
    Roku: 6,
    Tribeca: 5,
    Bravo: 5,
    Tubi: 5,
    Plex: 5,
    MUBI: 4,
    ABC: 4,
    Magnolia: 4,
    TBS: 3,
    Crackle: 3,
    Freeform: 3,
    Crunchyroll: 2,
    Shout: 1,
    Sundance: 1,
    Apple: 1,
    Dox: 1,
  };
  // Convert serviceCounts to an array and sort by count
  const streamingServices = Object.entries(serviceCounts)
    .sort((a, b) => b[1] - a[1])
    .map((service) => service[0]);

  const genreList = [
    "Crime",
    "Drama",
    "Action",
    "Adventure",
    "Biography",
    "History",
    "Thriller",
    "Animation",
    "Family",
    "War",
    "Fantasy",
    "Music",
    "Mystery",
    "Romance",
    "Sci-Fi",
    "Horror",
    "Western",
    "Sport",
    "Film-Noir",
    "Musical",
    "Comedy",
    "Documentary",
  ].sort();
  const genreCount = {
    Drama: 634,
    Comedy: 266,
    Action: 250,
    Adventure: 249,
    Crime: 196,
    Thriller: 157,
    Romance: 119,
    Mystery: 114,
    Biography: 109,
    Animation: 102,
    "Sci-Fi": 92,
    Fantasy: 74,
    Horror: 71,
    Family: 47,
    History: 44,
    Music: 34,
    War: 29,
    Sport: 14,
    Western: 13,
    Musical: 12,
    "Film-Noir": 8,
    Documentary: 3,
  };
  const decades = [
    "2020s",
    "2010s",
    "2000s",
    "1990s",
    "1980s",
    "1970s",
    "1960s",
    "1950s",
    "1940s",
    "1930s",
  ];
  const decadeCounts = {
    "2020s": 56,
    "2010s": 336,
    "2000s": 260,
    "1990s": 135,
    "1980s": 83,
    "1970s": 45,
    "1960s": 36,
    "1950s": 27,
    "1940s": 14,
    "1930s": 7,
  };

  const toggleYearFilter = (year) => {
    if (year === "All") {
      setSelectedYears(new Set(decades));
    } else if (year === "Clear") {
      setSelectedYears(new Set());
    } else {
      setSelectedYears((prevYears) => {
        const newYears = new Set(prevYears);
        if (newYears.has(year)) {
          newYears.delete(year);
        } else {
          newYears.add(year);
        }
        return newYears;
      });
    }
  };

  const toggleStreamingServiceFilter = (service) => {
    if (service === "All") {
      setSelectedStreamingServices(
        new Set(streamingServices.map((s) => s.toLowerCase()))
      );
    } else if (service === "Clear") {
      setSelectedStreamingServices(new Set());
    } else {
      setSelectedStreamingServices((prevServices) => {
        const newServices = new Set(prevServices);
        if (newServices.has(service.toLowerCase())) {
          newServices.delete(service.toLowerCase());
        } else {
          newServices.add(service.toLowerCase());
        }
        return newServices;
      });
    }
  };

  // Toggle Genre Filter with 'All' and 'Clear' functionality
  const toggleGenreFilter = (genre) => {
    if (genre === "All") {
      setSelectedGenres(new Set(genreList.map((g) => g.toLowerCase())));
    } else if (genre === "Clear") {
      setSelectedGenres(new Set());
    } else {
      setSelectedGenres((prevGenres) => {
        const newGenres = new Set(prevGenres);
        if (newGenres.has(genre.toLowerCase())) {
          newGenres.delete(genre.toLowerCase());
        } else {
          newGenres.add(genre.toLowerCase());
        }
        return newGenres;
      });
    }
  };

  // Toggle Filter Function
  const toggleFilter = () => {
    setShowFiltered((current) => !current);
  };

  // Function to handle score change and update the database
  const handleScoreChange = async (constId, newScore) => {
    try {
      const token = await getAccessTokenSilently();
      await axios.post(
        `${apiBaseUrl}/api/user/rateMovie`,
        {
          userId: user.sub,
          Const: constId,
          score: newScore,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Update the userScores state to include the new score
      setUserScores((prevUserScores) => ({
        ...prevUserScores,
        [constId]: newScore,
      }));

      // Check if the movie is already in seenMovies before adding it
      if (!seenMovies.has(constId)) {
        setSeenMovies(
          (prevSeenMovies) => new Set([...prevSeenMovies, constId])
        );
      }
    } catch (error) {
      console.error("Error rating movie:", error);
    }
  };

  const handleAddAndRateMovie = async (movieConst, movieId) => {
    if (!isAuthenticated) {
      alert("Please log in to rate movies."); // Replace this with a UI-based prompt when you're ready.
      return; // Exit the function early.
    }

    // Continue with your logic for adding and rating a movie.
    const newScore = prompt("Enter your score (1-99):");
    if (newScore && newScore >= 1 && newScore <= 99) {
      if (!seenMovies.has(movieConst)) {
        await addMovieToSeenList(movieId);
      }
      await handleScoreChange(movieConst, newScore);
    }
  };

  const toggleRatedUnrated = (option) => {
    setShowRated(option); // option can be 'all', 'rated', or 'unrated'
  };

  // Function to determine the display style of the score div
  const scoreDisplayStyle = (constId, userScore, curatorScore) => {
    const isRated = userScores.hasOwnProperty(constId);
    const scoreColor = isRated
      ? getColorForScore(userScore || curatorScore)
      : "transparent"; // Transparent for unrated
    const borderColor = isRated
      ? getColorForScore(userScore)
      : getColorForScore(curatorScore);
    const borderRadius = isRated ? "50px" : "50px"; // 6px for rated, 50px for unrated

    return {
      backgroundColor: scoreColor,
      border: `4px solid ${borderColor}`,
      borderRadius: borderRadius,
    };
  };

  const decodeDecade = (decade) => {
    const startYear = parseInt(decade.substring(0, 4), 10);
    const endYear = startYear + 9;
    return [startYear, endYear];
  };

  const formatRarityRounded = (rarity) => {
    let formattedRarity;
    if (rarity < 100000) {
      // Round to the nearest ten thousand if less than 100k
      const rounded = Math.round(rarity / 10000) * 10000;
      formattedRarity =
        rounded < 1000 ? rarity.toString() : (rounded / 1000).toFixed(0) + "K+";
    } else if (rarity >= 100000 && rarity < 1000000) {
      // Different rounding logic for 100k to 1 million
      const rounded = Math.round(rarity / 100000) * 100000;
      formattedRarity = (rounded / 1000).toFixed(0) + "K+";
    } else if (rarity >= 1000000) {
      // Different rounding logic for more than 1 million
      const rounded = Math.round(rarity / 1000000) * 1000000;
      formattedRarity = (rounded / 1000000).toFixed(0) + "M+";
    } else {
      // Handle case for rarity < 1000 without special rounding
      formattedRarity = rarity.toString();
    }
    return `${formattedRarity}`;
  };

  const formatRuntime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    if (hours === 0) return `${minutes} min`;
    return `${hours} hr ${minutes} min`;
  };

  const filteredMovies = movies
    .filter((movie) => {
      const matchesSearch =
        searchQuery === "" ||
        movie.Title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        movie.Genre.toLowerCase().includes(searchQuery.toLowerCase()) ||
        movie.Streaming.toLowerCase().includes(searchQuery.toLowerCase()) ||
        movie.Year.toString().includes(searchQuery);

      // const matchesYearFilter = selectedYears.size === 0 || [...selectedYears].some(decade => {
      //   const [startYear, endYear] = decodeDecade(decade);
      //   return movie.Year >= startYear && movie.Year <= endYear;
      // });

      // // Revised logic for streaming services filter
      // const matchesStreamingFilter = selectedStreamingServices.size === 0 ||
      //   selectedStreamingServices.has(movie.Streaming.toLowerCase());

      // // Revised logic for genre filter
      // const movieGenres = movie.Genre.split(', ').map(g => g.trim().toLowerCase());

      // // Get a list of genres that are not selected
      // const unselectedGenres = genreList.map(g => g.toLowerCase()).filter(g => !selectedGenres.has(g));

      // // Ensure none of the movie's genres are in the unselected genres
      // const matchesGenreFilter = selectedGenres.size === 0 || movie.Genre.split(', ').some(genre => selectedGenres.has(genre.trim().toLowerCase()));

      // const isMovieRated = userScores.hasOwnProperty(movie.Const);
      // const matchesRatedFilter = showRated === 'all' ||
      //   (showRated === 'rated' && isMovieRated) ||
      //   (showRated === 'unrated' && !isMovieRated);

      // return matchesSearch;
      return matchesSearch;
    })
    .sort((a, b) => a.CuratorScoreRank - b.CuratorScoreRank); // Sort by CuratorScoreRank in descending order

  // return matchesSearch && matchesYearFilter && matchesStreamingFilter && matchesGenreFilter && matchesRatedFilter;
  // });

  const toggleSearchInput = () => {
    setShowSearchInput((current) => !current);
    if (!showSearchInput) {
      setTimeout(() => {
        searchInputRef.current.focus();
      }, 0);
    }
  };

  const logoutWithRedirect = () =>
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });

  const addMovieToSeenList = async (movieId) => {
    try {
      const token = await getAccessTokenSilently();
      const movie = movies.find((m) => m._id === movieId);
      if (!movie) {
        console.error("Movie not found");
        return;
      }

      if (seenMovies.has(movie.Const)) {
        // Remove movie from seen list
        await removeMovieFromSeenList(movie.Const);
      } else {
        await axios.post(
          `${apiBaseUrl}/api/user/addMovie`,
          {
            userId: user.sub,
            name: user.name,
            movie: { Const: movie.Const, Title: movie.Title },
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // Update the seenMovies state to include the new movie
        setSeenMovies(new Set([...seenMovies, movie.Const]));
      }
    } catch (error) {
      console.error("Error adding movie to seen list:", error);
    }
  };

  const formatRarity = (rarity) => {
    let formattedRarity;
    if (rarity < 1000) {
      formattedRarity = rarity.toString();
    } else if (rarity < 1000000) {
      formattedRarity = (rarity / 1000).toFixed(0) + "K";
    } else {
      formattedRarity = (rarity / 1000000).toFixed(0) + "M";
    }
    return `(${formattedRarity})`;
  };

  const isFilterActive = () => {
    return (
      selectedYears.size > 0 ||
      selectedStreamingServices.size > 0 ||
      selectedGenres.size > 0 ||
      showRated !== "all"
    );
  };

  const removeMovieFromSeenList = async (movieConst) => {
    try {
      const token = await getAccessTokenSilently();
      await axios.post(
        `${apiBaseUrl}/api/user/removeMovie`,
        {
          userId: user.sub,
          movieConst: movieConst, // Send the Const value
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Update local seenMovies state
      const newSeenMovies = new Set(seenMovies);
      newSeenMovies.delete(movieConst);
      setSeenMovies(newSeenMovies);
    } catch (error) {
      console.error("Error removing movie from seen list:", error);
    }
  };

  const [setFilteredMovies] = useState([]);

  // Utility function to capitalize the first letter of a string
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    // Filtering logic
    const lowerCaseQuery = searchQuery.toLowerCase();
    const filtered = movies.filter(
      (movie) =>
        movie.Title.toLowerCase().includes(lowerCaseQuery) ||
        movie.Genre.toLowerCase().includes(lowerCaseQuery) ||
        movie.Streaming.toLowerCase().includes(lowerCaseQuery) ||
        movie.Year.toString().includes(searchQuery)
    );
    setFilteredMovies(filtered);
  };

  useEffect(() => {
    // Set up the debounce function
    const timerId = setTimeout(() => {
      setDebouncedQuery(searchQuery);
    }, 500); // 500ms delay

    return () => {
      clearTimeout(timerId);
    };
  }, [searchQuery]); // Only re-run effect if searchQuery changes

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        yearDropdownRef.current &&
        !yearDropdownRef.current.contains(event.target)
      ) {
        setShowYearDropdown(false);
      }
      if (
        streamingDropdownRef.current &&
        !streamingDropdownRef.current.contains(event.target)
      ) {
        setShowStreamingDropdown(false);
      }
      if (
        seenDropdownRef.current &&
        !seenDropdownRef.current.contains(event.target)
      ) {
        setShowRatedUnratedDropdown(false);
      }
      if (
        genreDropdownRef.current &&
        !genreDropdownRef.current.contains(event.target)
      ) {
        setShowGenreDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const buttonRef = useRef(null);
  const dropdownMenuRef = useRef(null);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const positionDropdownMenu = () => {
    const buttonRect = buttonRef.current.getBoundingClientRect();
    const top = buttonRect.bottom + window.scrollY;
    const left = buttonRect.left + window.scrollX;
    dropdownMenuRef.current.style.top = `${top}px`;
    dropdownMenuRef.current.style.left = `${left}px`;
  };

  const isYearFilterActive = () => {
    return selectedYears.size > 0;
  };

  const isStreamingFilterActive = () => {
    return selectedStreamingServices.size > 0;
  };

  const isGenreFilterActive = () => {
    return selectedGenres.size > 0;
  };

  const isRatedUnratedFilterActive = () => {
    return showRated !== "all"; // Assuming 'all' means no filter is applied
  };

  // Check if any filter is active
  const isAnyFilterActive = () => {
    return (
      selectedYears.size > 0 ||
      selectedStreamingServices.size > 0 ||
      selectedGenres.size > 0 ||
      showRated !== "all"
    );
  };

  const adjustDropdownWidth = () => {
    if (divRef.current && dropdownMenuRef.current) {
      const divWidth = divRef.current.offsetWidth;
      const dropdownMenus = document.querySelectorAll(".dropdown-menu");

      dropdownMenus.forEach((menu) => {
        menu.style.width = `${divWidth}px`;
        menu.style.left = `${divRef.current.getBoundingClientRect().left}px`;
        menu.style.backgroundColor = "red";
      });
    }
  };

  useEffect(() => {
    // Call adjustDropdownWidth when dropdown is open and divRef is available
    if (isDropdownOpen && divRef.current) {
      adjustDropdownWidth();
    }
  }, [isDropdownOpen]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch movies
        // const moviesResponse = await axios.get(`${apiBaseUrl}/api/movies`);

        const fetchContent = async () => {
          try {
            const response = await axios.get(
              `${apiBaseUrl}/api/content?type=${contentType}`
            );
            const fetchedContent = response.data;
            fetchedContent.sort((a, b) => b.CuratorScore - a.CuratorScore);
            setMovies(fetchedContent);
          } catch (error) {
            console.error("Error fetching content data:", error);
          }
        };

        let fetchedMovies = fetchContent.data;

        // Fetch seen movies and user scores
        const seenMoviesResponse = await axios.get(
          `${apiBaseUrl}/api/user/seenMovies`,
          {
            headers: {
              "user-id": user.sub,
            },
          }
        );
        const seenMovieIds = new Set(
          seenMoviesResponse.data.map((movie) => movie.Const)
        );

        // Merge user scores into movies data
        const moviesWithScores = fetchedMovies.map((movie) => {
          return {
            ...movie,
            score: userScores[movie.Const] || movie.CuratorScore,
          };
        });

        // Sort movies by score (user score if available, otherwise curator score)
        moviesWithScores.sort((a, b) => b.score - a.score);

        setMovies(moviesWithScores);
        setSeenMovies(seenMovieIds);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (user && user.sub) {
      fetchData();
    }
  }, [user, getAccessTokenSilently, userScores, contentType]);

  return (
    <div className="iphone-pro-max">
      {/* Render MovieDetailPopup outside the map loop */}

      <div>
        <Helmet>
          <title>Curator Watchlist</title>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
          />
          <meta
            name="description"
            content="Curated movies for you. Quickly find what to watch next. Curator Score and Streaming services makes it easier than ever."
          />
        </Helmet>
      </div>
      <div className="overlap-wrapper">
        <div className="overlap">
          <div className="top3-container-display">
            {selectedMovie && (
              <MovieDetailPopup
                movie={selectedMovie}
                // closePopup={closePopup}
                closePopup={closePopup}
                onSimilarMovieSelect={handleSimilarMovieSelect}
                seenMovies={seenMovies} // Ensure this is passed
                userScores={userScores} // Ensure this is passed
                movies={movies}
                updateUserScore={handleAddAndRateMovie}
                watchlistMovies={watchlistMovies}
                userId={user?.sub}
                seenMoviesProp={seenMovies}
                watchlistProp={watchlistMovies}
              />
            )}
            <div ref={divRef} className="div landing-page-with-nav-header">
              {/* {showYearDropdown && (
                <div ref={yearDropdownRef} className={`dropdown-menu ${showYearDropdown ? 'visible' : ''}`}>
                  <button onClick={() => toggleYearFilter('All')} className="dropdown-item">
                    Select All Years
                  </button>
                  <button onClick={() => toggleYearFilter('Clear')} className="dropdown-item">
                    Clear
                  </button>
                  <hr className="hr-line" />
                  {decades.map(decade => (
                    <button onClick={() => toggleYearFilter(decade)} className="dropdown-item" key={decade}>
                      {selectedYears.has(decade) && <CheckmarkSmall className="checkmark-icon" />}
                      <span className="dropdown-text">{`${decade} (${decadeCounts[decade]})`}</span>
                    </button>
                  ))}
                </div>
              )}

              {showStreamingDropdown && (
                <div ref={streamingDropdownRef} className={`dropdown-menu ${showStreamingDropdown ? 'visible' : ''}`}>
                  <button onClick={() => toggleStreamingServiceFilter('All')} className="dropdown-item">
                    Select All Watch Options
                  </button>
                  <button onClick={() => toggleStreamingServiceFilter('Clear')} className="dropdown-item">
                    Clear
                  </button>
                  <hr className="hr-line" />
                  {streamingServices.map(service => (
                    <button onClick={() => toggleStreamingServiceFilter(service)} className="dropdown-item" key={service}>
                      {selectedStreamingServices.has(service.toLowerCase()) && <CheckmarkSmall className="checkmark-icon" />}
                      <span className="dropdown-text">{`${capitalizeFirstLetter(service)} (${serviceCounts[service]})`}</span>
                    </button>
                  ))}
                </div>
              )}

              {showGenreDropdown && (
                <div ref={genreDropdownRef} className={`dropdown-menu ${showGenreDropdown ? 'visible' : ''}`}>
                  <button onClick={() => toggleGenreFilter('All')} className="dropdown-item">
                  Select All Genres
                  </button>
                  <button onClick={() => toggleGenreFilter('Clear')} className="dropdown-item">
                    Clear
                  </button>
                  <hr className="hr-line" />
                  {genreList.map(genre => (
                    <button onClick={() => toggleGenreFilter(genre)} className="dropdown-item" key={genre}>
                      {selectedGenres.has(genre.toLowerCase()) && <CheckmarkSmall className="checkmark-icon" />}
                      <span className="dropdown-text">{`${genre} (${genreCount[genre]})`}</span>
                    </button>
                  ))}
                </div>
              )}

              {showRatedUnratedDropdown && (
                <div ref={seenDropdownRef} className={`dropdown-menu ${showRatedUnratedDropdown ? 'visible' : ''}`}>
                  <button onClick={() => toggleRatedUnrated('all')} className="dropdown-item">
                    {showRated === 'all' && <CheckmarkSmall className="checkmark-icon" />}
                    <span className="dropdown-text">Select All Movies</span>
                  </button>
                  <hr className="hr-line" />
                  <button onClick={() => toggleRatedUnrated('rated')} className="dropdown-item">
                    {showRated === 'rated' && <CheckmarkSmall className="checkmark-icon" />}
                    <span className="dropdown-text">Rated Movies ({ratedMoviesCount})</span>
                  </button>
                  <button onClick={() => toggleRatedUnrated('unrated')} className="dropdown-item">
                    {showRated === 'unrated' && <CheckmarkSmall className="checkmark-icon" />}
                    <span className="dropdown-text">Unrated Movies ({unratedMoviesCount})</span>
                  </button>
                </div>
              )} */}

              <div className="search-landing__header">
                <h2 className="search-landing__title typography-large-title-emphasized">
                  Search
                </h2>
                <div className="search-header__search search-header__search-page">
                  <label
                    htmlFor="search-header-form-input-box"
                    id="search-header-form-input-label"
                    className="search-header__form-input-label"
                  >
                    Search
                  </label>
                  {/* <form > */}
                  <div className="search-header__search-input-wrapper">
                    <svg
                      height="24"
                      viewBox="0 0 64 64"
                      width="24"
                      xmlns="http://www.w3.org/2000/svg"
                      className="search-header__search-icon"
                      aria-hidden="true"
                    >
                      <path d="m26.72 50.414c5.205 0 10.005-1.683 13.932-4.488l14.773 14.773c.686.686 1.59 1.028 2.556 1.028 2.026 0 3.46-1.558 3.46-3.553 0-.935-.312-1.807-.998-2.493l-14.68-14.71c3.086-4.052 4.925-9.07 4.925-14.524 0-13.184-10.784-23.968-23.967-23.968-13.153 0-23.968 10.753-23.968 23.968 0 13.183 10.784 23.967 23.968 23.967zm0-5.174c-10.285 0-18.793-8.508-18.793-18.793 0-10.286 8.508-18.794 18.794-18.794 10.285 0 18.793 8.508 18.793 18.794 0 10.285-8.508 18.793-18.793 18.793z"></path>
                    </svg>
                    <input
                      onChange={(e) => setSearchQuery(e.target.value)}
                      name="query"
                      placeholder="Search"
                      autoComplete="off"
                      className="search-header__search-input"
                      id="search-header-form-input-box"
                      type="search"
                    />
                  </div>
                  <button
                    aria-label="Clear text"
                    className="search-header__button search-header__button--close"
                    type="reset"
                  >
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      xmlns="http://www.w3.org/2000/svg"
                      className="search-header__close-icon"
                    >
                      <path d="M7 14c3.83 0 7-3.177 7-7 0-3.83-3.177-7-7.007-7C3.171 0 0 3.17 0 7c0 3.823 3.177 7 7 7ZM4.694 9.882a.562.562 0 0 1-.563-.57c0-.15.055-.294.165-.397l1.901-1.908-1.9-1.901a.55.55 0 0 1-.166-.398c0-.323.247-.563.563-.563.158 0 .281.055.391.158L7 6.21l1.928-1.915a.52.52 0 0 1 .392-.165c.315 0 .57.247.57.563a.53.53 0 0 1-.172.405L7.81 7.007l1.9 1.9a.524.524 0 0 1 .172.406.57.57 0 0 1-.576.57.543.543 0 0 1-.405-.165L7 7.81 5.106 9.718a.57.57 0 0 1-.412.164Z"></path>
                    </svg>
                  </button>
                  {/* </form> */}
                </div>
              </div>

              {/* <div className={`filter-container ${showYearDropdown || showStreamingDropdown || showGenreDropdown || showRatedUnratedDropdown ? 'filter-container-expanded' : ''}`}>
                
                <div className="dropdown">
                  <button 
                    onClick={clearAllFilters} 
                    className={`dropdown-button clear-all ${isYearFilterActive() ? '' : ''}`}>
                    <span className="button-text">Clear Filters</span>
                  </button>
                </div>
                
                <div className="dropdown">
                <button 
                  onClick={toggleYearDropdown} 
                  className={`dropdown-button ${isYearFilterActive() ? 'active' : ''}`}>
                  <span className="button-text">Year</span>
                  <DropDown className="dropdown-icon" />
                </button>
                </div>

                <div className="dropdown">
                <button 
                  onClick={toggleStreamingDropdown} 
                  className={`dropdown-button ${isStreamingFilterActive() ? 'active' : ''}`}>
                  <span className="button-text">Streaming</span>
                  <DropDown className="dropdown-icon" />
                </button>
                </div>

                <div className="dropdown">
                <button 
                  onClick={toggleGenreDropdown} 
                  className={`dropdown-button ${isGenreFilterActive() ? 'active' : ''}`}>
                  <span className="button-text">Genre</span>
                  <DropDown className="dropdown-icon" />
                </button>
                </div>

                <div className="dropdown">
                <button 
                  onClick={() => toggleRatedUnratedDropdown()} 
                  className={`dropdown-button ${isRatedUnratedFilterActive() ? 'active' : ''}`}>
                  <span className="button-text">{showRated.charAt(0).toUpperCase() + showRated.slice(1)}</span>
                  <DropDown className="dropdown-icon" />
                </button>
                </div>
              </div> */}

              <div className="movie-wrapper-div">
                {isMoviesLoading && (
                  <div className="group-wrapper">
                    <div className="group-2">
                      {/* If similar movies exist display bbtitle div: */}
                      <div className="shelf-header--with-see-all">
                        <h2 className="typ-headline-emph">Loading</h2>
                        <span className="typ-subhead shelf-subheader clr-secondary-text">
                          Loading movies for you...
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                {filteredMovies.slice(0, displayCount).map((movie, index) => (
                  <div
                    className="group-wrapper"
                    key={movie._id}
                    onClick={() => handleMovieSelect(movie)}
                  >
                    <div
                      className="group-2"
                      onClick={() => openMoviePopup(movie)}
                    >
                      {/* <div className="div-wrapper">
                      {true ? 
                        (<svg style={{ width: "20px", fill: "white", stroke: "white", strokeWidth: "2px", marginBottom: "0px" }} focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M17 3H7c-1.1 0-1.99.9-1.99 2L5 21l7-3 7 3V5c0-1.1-.9-2-2-2z"></path></svg>) : 
                        (<svg style={{ width: "20px", fill: "none", stroke: "white", strokeWidth: "2px", marginBottom: "0px" }} focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M17 3H7c-1.1 0-1.99.9-1.99 2L5 21l7-3 7 3V5c0-1.1-.9-2-2-2zm0 21.35z"/></svg>)}
                      </div>       */}

                      {/* <div className="div-wrapper">
                        <div
                            className="overlap-group-2"
                            style={scoreDisplayStyle(movie.Const, userScores[movie.Const], movie["CuratorScore"])}
                            onClick={() => handleAddAndRateMovie(movie.Const, movie._id)}
                        >
                          <div 
                            className="text-wrapper-2"
                            style={{ color: userScores.hasOwnProperty(movie.Const) ? 'black' : '--systemPrimary-onDark: rgba(255, 255, 255, 0.92)' }} // Conditionally text color
                          >
                            {userScores[movie.Const] || movie["CuratorScore"]}
                          </div>
                        </div>
                      </div> */}

                      <div className="group-3">
                        {/* <div className="text-wrapper-3"><span>{movie.Title}</span></div>
                        <div className="text-wrapper-4">#{movie.CuratorRank} ‧ {movie.User} {formatRarity(movie.Rarity)} ‧ {movie.Critic} ‧ {movie.Certified}</div>
                        <div className="text-wrapper-41">{movie.Year} ‧ {movie.Streaming} ‧ {movie.Genre}</div> */}

                        <div className="text-wrapper-3">
                          <span>{movie.Title}</span>
                        </div>
                        <div className="text-wrapper-4">
                          <strong>{movie.CuratorScore}</strong>{" "}
                          {movie.Streaming}
                        </div>
                        {/* <div className="text-wrapper-4"><strong>{movie.Streaming}</strong> ‧ {movie.Genre} </div> */}
                        {/* <div className="text-wrapper-41">{movie.Year} ‧ {movie.number_of_seasons > 0 ? `${movie.number_of_seasons} seasons` : formatRuntime(movie.Runtime)} ‧ {movie.Certified}</div> */}

                        {/* 
                        <li style={{paddingBottom: "5px", fontSize: "14px",}}>
                        </li>
                        <li style={{paddingBottom: "5px", fontSize: "14px",}}>
                        </li>
                        <li style={{paddingBottom: "5px", fontSize: "14px",}}>
                            
                        </li> */}
                      </div>
                      {/* <button 
                        className="star" 
                        onClick={() => addMovieToSeenList(movie._id)}
                        aria-pressed={seenMovies.has(movie.Const)}
                      >
                        {seenMovies.has(movie.Const) ? '' : ''}
                      </button> */}
                      <div className="movie-poster">
                        {/* <img src={movie.Poster} alt={movie.Title} className="movie-poster-img"/> */}
                        <img
                          // src={
                          //   `${movie.Poster}@._V1_QL55_UX190_.jpg`

                          // }
                          src={
                            window.innerWidth > 10000 && movie.backdrop_path
                              ? `${movie.backdrop_path.replace(
                                  "/",
                                  "https://image.tmdb.org/t/p/w1280/"
                                )}`
                              : movie.poster_path
                              ? `${movie.backdrop_path.replace(
                                  "/",
                                  "https://image.tmdb.org/t/p/w1280/"
                                )}`
                              : `${movie.Poster.replace(
                                  "_V1_.jpg",
                                  "_V1_FMjpg_UX900_.jpg"
                                )}`
                          }
                          decoding="async"
                          loading="lazy"
                          className="movie-poster-img"
                          alt={movie.Title}
                          role="presentation"
                          style={{ width: "60px", height: "60px" }}
                          // sizes="(max-width: 480px) 100vw, (max-width: 720px) 50vw, 25vw"
                          // srcSet={`
                          //     ${movie.Poster}@._V1_QL75_UX190_CR0,0,190,281_.jpg 190w,
                          //     ${movie.Poster}@._V1_QL75_UX285_CR0,0,285,422_.jpg 285w,
                          //     ${movie.Poster}@._V1_QL75_UX380_CR0,0,380,562_.jpg 380w
                          // `}
                        />
                      </div>

                      <div className="movie-rank-cont">
                        <div className="movie-rank-pos">
                          <span>{movie.CuratorScoreRank}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

                {/* Button to see more movies */}
                {displayCount < filteredMovies.length && (
                  <div
                    className="see-more-text see-all-button clr-link"
                    onClick={handleSeeMore}
                  >
                    See More
                  </div>
                )}
              </div>

              {/* Render MovieDetailPopup outside the map loop */}
              {/* {selectedMovie && (
                  <MovieDetailPopup 
                      movie={selectedMovie} 
                      closePopup={closePopup}
                      seenMovies={seenMovies} // Ensure this is passed
                      userScores={userScores} // Ensure this is passed 
                  />
              )} */}

              {/* <div className="group-wrapper foot-space"></div> */}
              {/* <div className="text-wrapper-6">{user ? `${user.given_name}'s` : ''} Top Movies</div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MovieList2;
